import React from "react";
import { Link, useLocation } from "react-router-dom";

function SeasonSalaryDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Type",
      value: listDetails?.EmployeeTypeName,
    },
    {
      label: "From Date",
      value: listDetails?.from_date,
    },
    {
      label: "To Date",
      value: listDetails?.to_date,
    },
    {
      label: "Per Day Salary",
      value: listDetails?.per_day_salary,
    },
    {
      label: "Remarks",
      value: listDetails?.remarks,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/seasonalsalary" className="in-active">
              Seasonal Salary List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Seasonalsalary Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Seasonal Salary Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeasonSalaryDetails;
