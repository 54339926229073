import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import AgentSecurity from "../../DataTable/AgentSecurity";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function SecurityList() {
  const navigate = useNavigate();
  const [securityList, setSecurityList] = useState([]);
  const [filteredSecurity, setFilteredSecurity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [pagination, setPagination] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const showSecurityDetails = (listDetail) => {
    navigate("/master/hrms/agent/security/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/agent/security/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "company_name", label: "Company Name" },
    { id: "contact_person", label: "Contact Person" },
    { id: "contact_number", label: "Contact Number" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getSecurityList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getSecurityList(1, pageSize, searchKey);
  };

  const getSecurityList = async (page, size, search) => {
    setLoading(true);
    try {
      const securityList = await GET_API(
        `/agentList/security_agent?page=${page}&size=${size}&search=${search}`
      );
      if (securityList?.data?.status === 200) {
        if (
          securityList?.data?.data?.current_page >
          securityList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setSecurityList(securityList.data?.data?.data);
        setPagination(securityList?.data);
        setFilteredSecurity(securityList.data?.data?.data);
        setLastPage(securityList?.data?.data?.last_page);
        return;
      }
    } catch (error) {
      setLoading(false);
      setSecurityList([]);
      setFilteredSecurity([]);
      setLastPage("");
      setCurrentPage(1);
      alertify.error("Failed to Get Security List");
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Security..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteAgents/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getSecurityList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Security Agent List");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Security Agent List</h4>

          <form
            onSubmit={() => getSecurityList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
              <div className="add-new-button-block">
                <Link
                  to="/master/hrms/agent/security/add"
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </form>
          <AgentSecurity
            list={filteredSecurity}
            columns={columns}
            showDetails={showSecurityDetails}
            icon={More}
            multiplePage={true}
            multiple={true}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
            pagination={pagination}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default SecurityList;
