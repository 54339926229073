import Select from "react-select";
import { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/POValidation";
import { useNavigate } from "react-router-dom";

function CreateLogistics() {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, seterror] = useState(false);
  const navigate = useNavigate();
  const [logistics, setLogistics] = useState({
    purchase_order_id: "",
    invoice_id: "",
    mode_of_transport: "",
    transport_name: "",
    lr_number: "",
    amount: "",
    consignment_number: "",
  });

  useEffect(() => {
    getPurchaseOrderList();
    getInvoiceList();
    getSupplierList();
  }, []);

  const getPurchaseOrderList = async () => {
    setLoading(true);
    try {
      const POList = await GET_API("po/purchaseOrderDropdown");
      if (POList?.data?.status === 200) {
        const PO = POList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.po_number };
        });
        PO.unshift({ value: "", label: "Select..." });
        setPurchaseOrderList(PO);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get PO List...");
    }
  };

  const getInvoiceList = async () => {
    setLoading(true);
    try {
      const invoiceList = await GET_API("po/invoiceDropdown");
      if (invoiceList?.data?.status === 200) {
        const invoice = invoiceList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.invoice_number };
        });
        invoice.unshift({ value: "", label: "Select..." });
        setInvoiceList(invoice);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get PO List...");
    }
  };

  const getSupplierList = async () => {
    setLoading1(true);
    try {
      const supplierListData = await GET_API("po/suppliersDropdown");
      if (supplierListData?.data?.status === 200) {
        const supplier = supplierListData?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        supplier.unshift({ value: "", label: "Select..." });
        setSupplierList(supplier);
        setLoading1(false);
      }
    } catch (error) {
      setLoading1(false);
      alertify?.error("Failed to get Supplier List...");
    }
  };

  const handleChange = (name, value) => {
    setLogistics({ ...logistics, [name]: value });
  };

  const addTransport = async (e) => {
    e?.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Logistics(logistics);
    if (isValid && isValid?.error) {
      seterror({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterror({});
      setButtonDisabled(false);
    }

    try {
      const createLogisticsList = await POST_API("po/addLogistic", logistics);
      if (createLogisticsList?.data?.status === 200) {
        alertify?.success(createLogisticsList?.data?.message);
        setButtonDisabled(false);
        navigate("/po/logistics/previous");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify?.error("Failed to create logistics list...");
      Object.keys(error?.response?.data?.data).forEach(function (key) {
        alertify?.error(error?.response?.data?.data[key].toString());
      });
    }
  };

  const resetForm = () => {
    setLogistics({
      invoice_number: "",
      purchase_order_id: "",
      supplier_id: "",
      amount: "",
      paid_amount: "",
      payment_status: "",
      invoice_date: "",
      invoice_items: [],
    });
  };

  if (loading || loading1) {
    return <Spinner />;
  }

  return (
    <div className="po-container">
      <div className="po-contect-box">
        <div className="header-name">New Logistics</div>
        <form
          className="po-form"
          onSubmit={(e) => {
            addTransport(e);
          }}
        >
          <div className="form-block-2">
            <div className="grid-block">
              <div className="input-field-block">
                <span className="label-name">
                  PO Number <span className="required-symbol">*</span>
                </span>
                <Select
                  options={purchaseOrderList}
                  name="purchase_order_id"
                  isSearchable
                  value={purchaseOrderList?.find(
                    (option) => option?.value === logistics.purchase_order_id
                  )}
                  onChange={(e) => handleChange("purchase_order_id", e?.value)}
                />
                {error.purchase_order_id ? (
                  <div className="validation-error-label">
                    {error.purchase_order_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Invoice <span className="required-symbol">*</span>
                </span>
                <Select
                  options={invoiceList}
                  name="invoice_id"
                  isSearchable
                  value={purchaseOrderList?.find(
                    (option) => option?.value === logistics.invoice_id
                  )}
                  onChange={(e) => handleChange("invoice_id", e?.value)}
                />
                {error.invoice_id ? (
                  <div className="validation-error-label">
                    {error.invoice_id}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Mode of Transport <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="mode_of_transport"
                  className="input-field"
                  placeholder="Enter Mode of Transport"
                  onChange={(e) => {
                    handleChange("mode_of_transport", e?.target?.value);
                  }}
                  value={logistics?.mode_of_transport}
                />
                {error.mode_of_transport ? (
                  <div className="validation-error-label">
                    {error.mode_of_transport}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Transport Name <span className="required-symbol">*</span>
                </span>
                <input
                  type="text"
                  name="transport_name"
                  className="input-field"
                  placeholder="Enter Transport name"
                  onChange={(e) => {
                    handleChange("transport_name", e?.target?.value);
                  }}
                  value={logistics?.transport_name}
                />
                {error.transport_name ? (
                  <div className="validation-error-label">
                    {error.transport_name}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  LR Number <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  name="lr_number"
                  className="input-field"
                  placeholder="Enter LR Number"
                  onChange={(e) => {
                    handleChange("lr_number", e?.target?.value);
                  }}
                  value={logistics?.lr_number}
                />
                {error.lr_number ? (
                  <div className="validation-error-label">
                    {error.lr_number}
                  </div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Cost <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  className="input-field"
                  name="amount"
                  placeholder="Enter amount"
                  onChange={(e) => {
                    handleChange("amount", e?.target?.value);
                  }}
                  value={logistics?.amount}
                />
                {error.amount ? (
                  <div className="validation-error-label">{error.amount}</div>
                ) : null}
              </div>

              <div className="input-field-block">
                <span className="label-name">
                  Consignment Number <span className="required-symbol">*</span>
                </span>
                <input
                  type="number"
                  className="input-field"
                  name="consignment_number"
                  placeholder="Enter consignment number"
                  onChange={(e) => {
                    handleChange("consignment_number", e?.target?.value);
                  }}
                  value={logistics?.consignment_number}
                />
                {error.consignment_number ? (
                  <div className="validation-error-label">
                    {error.consignment_number}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add Transport</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateLogistics;
