import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API, POST_API, PUT_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Edit from "../../../Assets/Icons/Edit.svg";
import TermsTable from "../../DataTable/PurchaseOrder/TermsTable";
import POValidation from "../../../Validation/POValidation";

function POTerms() {
  const [editStatus, setEditStatus] = useState(false);
  const [termsList, setTermsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [termsDetails, setTermsDetails] = useState({
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getTermsList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getTermsList(1, pageSize, searchKey);
  };

  const getTermsList = async (page, size, search) => {
    setLoading(true);
    try {
      const termsList = await GET_API(
        `/po/poTerms?page=${page}&size=${size}&search=${search}`
      );
      if (termsList?.data?.status === 200) {
        if (
          termsList?.data?.data?.current_page > termsList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setPagination(termsList?.data);
        setTermsList(termsList?.data?.data?.data);
        setFilteredList(termsList?.data?.data?.data);
        setLastPage(termsList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Terms List");
      setCurrentPage(1);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Terms..?",
        async function () {
          try {
            if (row_id) {
              const deleteRow = await DELETE_API(`/po/deletePoterms/${row_id}`);
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getTermsList(currentPage, pageSize, searchKey);
                setCurrentPage(1);

                return;
              }
              alertify.error("Failed To Delete Term");
            }
          } catch (error) {
            alertify?.error("failed to delete Terms");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const handleTerms = async (e, type) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = POValidation.POTerms(termsDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const url =
        type === "add"
          ? POST_API("/po/addPoterms", termsDetails)
          : PUT_API("/po/updatePoterms", termsDetails);

      const createTerms = await url;
      if (createTerms?.data?.status === 200) {
        setButtonDisabled(false);
        setTermsDetails({ name: "" });
        setEditStatus(false);
        getTermsList(currentPage, pageSize, searchKey);
        alertify.success(createTerms?.data?.message);
        return;
      }
    } catch (error) {
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const resetForm = () => {
    setTermsDetails({ name: "" });
  };
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Terms</h4>
          <form onSubmit={() => getTermsList(currentPage, pageSize, searchKey)}>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Terms Name"
                onChange={(e) => {
                  setTermsDetails({
                    ...termsDetails,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                autoComplete="off"
                value={termsDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleTerms(e, "edit");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleTerms(e, "add");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <TermsTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setTermsDetails}
            multiple={false}
            deleteRow={handleDelete}
            pagination={pagination}
            setState={setTermsList}
            setSearch={setFilteredList}
            loader={setLoading}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default POTerms;
