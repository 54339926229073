import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../../../Services/config.json";
import { GET_API } from "../../../Services/api";
import LoanPrintVoucher from "./LoanPrintVoucher";
import { useReactToPrint } from "react-to-print";
import "alertifyjs/build/css/alertify.css";
import Spinner from "../../Spinner";

function LoanDetails() {
  const componentRef = useRef();
  const [paymentDetailsLogs, setPaymentDetailsLogs] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loanDetails, setLoanDetails] = useState([]);

  useEffect(() => {
    getLoanDetails();
  }, []);

  const Details = [
    {
      label: "Employee ID",
      value: loanDetails?.employee_id ? loanDetails?.employee_id : "-",
    },
    {
      label: "Designation",
      value: loanDetails?.designation_name,
    },
    {
      label: "Employee Name",
      value: loanDetails?.first_name + " " + loanDetails?.fathers_name,
    },
    {
      label: "Department Name",
      value: loanDetails?.department_name,
    },
    {
      label: "Mobile Number",
      value: loanDetails?.phone_no,
    },
    {
      label: "Branch Name",
      value: loanDetails?.branch_name,
    },
  ];

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  const getLoanDetails = async () => {
    setLoading(true);
    try {
      const loanDetails = await GET_API(`/FinanceDetailById/${id}`);
      if (loanDetails?.data?.status === 200) {
        setLoanDetails(loanDetails?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const advanceDetails = [
    {
      label: "Payment Type",
      value: loanDetails?.payment_type,
    },
    {
      label: "Type",
      value: loanDetails?.type,
    },

    {
      label: "Finance Date",
      value: loanDetails?.finance_date,
    },
    {
      label: "Paid Status",
      value: loanDetails?.voucher_status === 1 ? "Paid" : "Unpaid",
    },
    {
      label: "Status",
      value:
        loanDetails?.status === 0
          ? "Approved"
          : loanDetails?.status === 2
          ? "Rejected"
          : loanDetails?.status === 3
          ? "Pending"
          : "--",
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/loan" className="in-active">
              Loan List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Loan Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Loan Details</div>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            {loanDetails?.status === 0 ? (
              <button
                type="button"
                className="submit-button"
                onClick={() => printVoucher()}
              >
                <span className="submit-button-label">Print</span>
              </button>
            ) : null}
          </div>

          <div className="advance-details-image">
            <div className="advance-details-image-container">
              <div
                style={{ wordBreak: "break-word" }}
                className="text-[#7B7B7B] text-base font-karla"
              >
                <img
                  src={`${config.path}${loanDetails?.profile_image}`}
                  alt=""
                  style={{ height: "100px", width: "100px" }}
                />
              </div>
            </div>
          </div>

          <div className="list-container border-bottom">
            <div className="list-content-block">
              {Details?.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="list-container border-bottom">
            <div className="advance-section">
              {advanceDetails?.map((list, i) => {
                return (
                  <div className="flex " key={i}>
                    <label className="list-label">{list?.label}: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {list?.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* {Loandetails?.status === 0 ? ( */}
          <LoanPrintVoucher ref={componentRef} printVoucherData={loanDetails} />
          {/* ) : null} */}

          <div className="p-5">
            <span className="text-text-dark font-karla text-base">
              Loan Details Logs
            </span>
            <table className="log-table mt-[4px]">
              <tr>
                <th>Amount</th>
                <th>Paid Amount</th>
                <th>Actual Due Date</th>
                <th>Remarks</th>
                <th>Image</th>
              </tr>
              {paymentDetailsLogs?.map((list, i) => {
                return (
                  <tr key={i}>
                    <td className="log-table log-table-data content-label">
                      {list?.amount}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.paid_amount}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.actual_due_date}
                    </td>
                    <td className="log-table log-table-data content-label">
                      {list?.remarks}
                    </td>
                    <td className="log-table log-table-data content-label">
                      <img
                        src={config?.path + list?.image}
                        alt="images"
                        className="h-[30px] w-[30px]"
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanDetails;
