import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { GET_API, POST_API, BASE_URL, BASE_PATH } from "../../Services/api";
import Spinner from "../Spinner";
import AgentCommCreationReportTable from "../DataTable/AgentCommCreationReportTable";
import alertify from "alertifyjs";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Validation from "../../Validation/Validation";
import Filter from "../../Assets/Icons/Filter.svg";
import QRCode from "react-qr-code";
import { numberToWords } from "../NumbersToWords/NumbersToWords";
import { useReactToPrint } from "react-to-print";
import ButtonLoader from "../ButtonLoader";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import PDFExport from "../../Assets/Icons/pdf-export.svg";

function AgentCommCreationReport() {
  const componentRef = useRef();
  const [agentList, setAgentList] = useState([]);
  const [agentCode, setAgentCode] = useState("");
  const [overallData, setOverallData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agentCommData, setAgentCommData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [errors, setErrors] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    attendance_status: "",
    joining_from_date: "",
    joining_to_date: "",
  });
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [selectAgentID, setSelectAgentID] = useState("");
  const [isShowVoucher, setIsShowVoucher] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();

  const [agentCommisssionList, setAgentCommisssionList] = useState({
    agent_id: agentCode,
    amount: "",
    created_by: "",
  });

  const [voucherData, setvoucherData] = useState({});

  const columns = [
    { id: "s.no", label: "S.No", sortable: false },
    { id: "employeeCode", label: "Employee Code", sortable: true },
    { id: "employeeName", label: "Employee Name", sortable: true },
    { id: "employeeAmount", label: "Employee Amount", sortable: false },
    { id: "companyAmount", label: "Company Amount", sortable: false },
    { id: "paidAmount", label: "Paid Amount", sortable: true },
    { id: "date", label: "Date", sortable: true },
  ];

  const [sorting, setSorting] = useState({
    orderBy: "",
    orderByType: "asc",
  });

  const handleSort = (columnId, sortable) => {
    if (!sortable) return;
    const isAsc =
      sorting.orderBy === columnId && sorting.orderByType === "desc";
    const newOrderByType = isAsc ? "asc" : "desc";
    getData(selectAgentID, currentPage, pageSize, filterParams, {
      orderBy: columnId,
      orderByType: newOrderByType,
    });
    setSorting({
      orderBy: columnId,
      orderByType: newOrderByType,
    });
  };

  useEffect(() => {
    getAgentList();
  }, []);

  useEffect(() => {
    getData(selectAgentID, currentPage, pageSize, filterParams);
  }, [showFilterResultData, pageSize, currentPage]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBranchList();
  };

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getEmployeeList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handlePopup = () => {
    setOpenPopup(true);
    // setSelectedList({
    //   agent_id: agentCode,
    // });

    setAgentCommisssionList({
      ...agentCommisssionList,
      agent_id: agentCode,
      created_by: localStorage?.getItem("user_id"),
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } =
      name === "joining_from_date" || name === "joining_to_date"
        ? e?.target
        : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const getEmployeeList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const attendanceListArr = [];
        employeeList?.data?.data.map((item) => {
          return attendanceListArr.push(item);
        });
        const empList = employeeList?.data?.data.map((item) => {
          return {
            label: `${item?.first_name} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;

    setAgentCommisssionList({
      ...agentCommisssionList,
      [name]: value,
    });
  };

  const addCommission = async () => {
    setButtonDisabled(true);
    const isValid =
      Validation.validateAgentCommissionPayment(agentCommisssionList);
    if (isValid && isValid?.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setButtonDisabled(false);
      setErrors({});
    }

    try {
      const addData = await POST_API(
        "agentCommissionCreatePayment",
        agentCommisssionList
      );
      if (addData?.data?.status === 200) {
        setvoucherData(addData?.data?.data);
        alertify?.success(addData?.data?.message);
        setOpenPopup(false);
        setAgentCommisssionList({
          agent_id: agentCode,
          amount: "",
          created_by: localStorage?.getItem("user_id"),
        });
        setIsShowVoucher(true);
        // printVoucher();
        setTimeout(printVoucherForm, 2000);
      }
    } catch (error) {
      setAgentCommisssionList({
        agent_id: "",
        amount: "",
        created_by: localStorage?.getItem("user_id"),
      });
      alertify?.error("Failed to add commission");
      setOpenPopup(false);
    }
  };

  const printVoucherForm = () => {
    printVoucher();
    setIsShowVoucher(false);
  };

  const getEmployeeAgentCommCreationReport = async (e) => {
    const { value } = e;
    setSelectAgentID(value);
    setIsLoading(true);
    getData(value, currentPage, pageSize, filterParams, sorting);
  };

  const getData = async (value, currentPage, pageSize, filterParams, sort) => {
    const sorting =
      sort?.orderBy && sort?.orderByType
        ? `&orderBy=${sort?.orderBy}&orderByType=${sort?.orderByType}`
        : "";
    if (value === "") return;
    try {
      setIsLoading(true);
      const getDetails = await GET_API(
        `agentCommissionCreationReport/${value}?page=${currentPage}&size=${pageSize}${filterParams}${sorting}`
      );
      if (getDetails?.data?.status === 200) {
        if (
          getDetails?.data?.data?.current_page >
          getDetails?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setAgentCommData(getDetails?.data?.data?.data);
        setOverallData(getDetails?.data?.overallData);
        setLastPage(getDetails?.data?.data?.last_page);
        setLoading(false);
        setAgentCode(value);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify?.error("Failed to get consolidate details");
    }
  };

  const getAgentList = async () => {
    setLoading(true);
    GET_API(`/agentDropdown/consultancy`)
      .then(async function (data) {
        const empList = data?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.company_name}`,
          };
        });
        setAgentList(empList);
        setLoading(false);
      })

      .catch((error) => {
        setAgentList([]);
        setLoading(false);
      });
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const exportAsExcel = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-agenctCommissioncreationExport/${selectAgentID}?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Agent Commsion Creation Report.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const exportasPDF = async () => {
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `agentCommissionCreationPdf/${selectAgentID}?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Agent Commsion Creation Report.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  if (
    loading ||
    isEmpLoading ||
    isBranchLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isLoading
  ) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow w-0">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Agent Commission Creation Report</h4>
          <div className="search-block">
            <div className="field-block dropdown-select-to-third">
              <label className="label-name">Select Agent</label>
              <Select
                options={agentList}
                value={agentList?.find((option) => option?.value === agentCode)}
                onChange={(e) =>
                  getEmployeeAgentCommCreationReport(
                    e,

                    filterParams
                  )
                }
                name="agentCode"
                className=""
                isSearchable
              />
            </div>
            {agentCode !== "" && (
              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>

                <div
                  className="add-new-button-block"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {isExportLoading ? (
                    <ButtonLoader isBtnDisable={isExportLoading} />
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={() => exportAsExcel()}
                    >
                      <span>Export as Excel</span>
                      <img
                        src={ExcelExport}
                        style={{ height: "25px" }}
                        alt="export"
                      />
                    </button>
                  )}
                </div>

                <div
                  className="add-new-button-block"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {isExportPdfLoading ? (
                    <ButtonLoader isBtnDisable={isExportPdfLoading} />
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={() => exportasPDF()}
                    >
                      <span>Export as PDF</span>
                      <img
                        src={PDFExport}
                        style={{ height: "25px" }}
                        alt="export"
                      />
                    </button>
                  )}
                </div>

                <div
                  className="add-new-button-block w-max"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Link
                    className="add-new-button"
                    onClick={() => {
                      handlePopup();
                    }}
                  >
                    <span>Create Payment</span>
                  </Link>
                </div>
              </div>
            )}
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date");
                      }}
                      value={filterList?.joining_from_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date");
                      }}
                      value={filterList?.joining_to_date}
                    />
                  </div>
                </div>

                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%]">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <div className="px-5 py-5 pt-0">
            <h2 className="text-xl font-karla">
              Total Pending Amount : <b>{overallData}</b>
            </h2>
          </div>
          <div className="px-5">
            <AgentCommCreationReportTable
              list={agentCommData}
              columns={columns}
              pageSize={pageSize}
              currentPage={currentPage}
              setPageNo={setCurrentPage}
              setPageSize={setPageSize}
              lastPage={lastPage}
              sorting={sorting}
              handleSort={handleSort}
            />
          </div>

          {isShowVoucher && (
            <div className="p-5" ref={componentRef}>
              <div className="bank-voucher-border p-5">
                <h2 className="advance-voucher-title text-center">
                  Agent Commission Voucher
                </h2>
                <div className="flex flex-col gap-4">
                  <div className="flex justify-end">
                    <QRCode
                      value={voucherData?.voucher?.voucher_no}
                      style={{ height: "80px" }}
                    />
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Date</span>
                      <span className="text-text-dark">
                        {voucherData?.date}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Agent Name</span>
                      <span className="text-text-dark">
                        {voucherData?.agent_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="text-xl font-karla flex gap-3">
                      <span className="text-text-light">Amount</span>
                      <span className="text-text-dark">
                        {voucherData?.amount}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="">
                      <div
                        className="bank-voucher-border advance-voucher-bold p-5"
                        style={{ fontSize: "45px" }}
                      >
                        <span>&#8377;{voucherData?.amount}</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-xl font-karla flex gap-3 text-text-dark">
                    <div className="text-text-light">Amount in words:</div>

                    <div>
                      <span>
                        &#8377;{numberToWords(parseFloat(voucherData?.amount))}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-around text-text-dark font-karla p-3">
                    <div>
                      <div className="text-xl text-text-light">Admit By</div>
                      <div></div>
                    </div>

                    <div>
                      <div className="text-xl text-text-light">Checked By</div>
                    </div>

                    <div>
                      <div className="text-xl text-text-light">
                        Employee Signature
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Dialog open={openPopup} onClose={handleClose}>
            <DialogTitle id="customized-dialog-title">
              ADD AGENT COMMISSION
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <div className="font-karla">
              <div
                className="gap-3"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    className="text-text-dark"
                    style={{ padding: "6px 0 6px 0" }}
                  >
                    Amount
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div>
                    <input
                      type="number"
                      name="amount"
                      placeholder="Enter Amount"
                      style={{
                        padding: "6px",
                        border: "1px solid #D99719",
                        borderRadius: "4px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={agentCommisssionList?.amount}
                    />

                    {errors.amount ? (
                      <div className="validation-error-label">
                        {errors.amount}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <DialogActions>
              <Button
                disabled={buttonDisabled}
                autoFocus
                onClick={() => addCommission()}
                style={{
                  backgroundColor: "#d99719",
                  color: "#ffffff",
                  position: "relative",
                  top: "20px",
                  padding: "10px",
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default AgentCommCreationReport;
