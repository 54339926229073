import React, { useState } from "react";

import MenuItems from "../MenuItems";
import Toggle from "../../Assets/Icons/Toggle.svg";
import $ from "jquery";
import { useParams } from "react-router-dom";

function MasterMenus() {
  const [selectedMenu, setSelectedMenu] = useState("");
  const { id } = useParams();

  const superAdminMenu = [
    {
      main_title: "HRMS",
      key: "hrms_masters",
      links: [
        {
          name: "Employee",
          id: "employee_master",
          list: [
            {
              label: "Role",
              to: "/master/hrms/role",
              active: ["/master/hrms/role"],
              key: "role",
            },
            {
              label: "User",
              to: "/master/hrms/user",
              active: ["/master/hrms/user", "/master/hrms/user/create"],
              key: "user",
            },
            {
              label: "Allowance Type",
              to: "/master/hrms/allowancetype",
              active: ["/master/hrms/allowancetype"],
              key: "allowance_type",
            },
            {
              label: "Govt ID Proof",
              to: "/master/hrms/govtidproof",
              active: ["/master/hrms/govtidproof"],
              key: "government_id_proof",
            },
            {
              label: "Department",
              to: "/master/hrms/department",
              active: [
                "/master/hrms/department",
                "/master/hrms/department/create",
                "/master/hrms/department/edit",
                "/master/hrms/department/details",
              ],
              key: "department",
            },
            {
              label: "Designation",
              to: "/master/hrms/designation",
              active: [
                "/master/hrms/designation",
                "/master/hrms/designation/create",
                "/master/hrms/designation/edit",
                "/master/hrms/designation/details",
              ],
              key: "designation",
            },
            {
              label: "Shift",
              to: "/master/hrms/shift",
              active: [
                "/master/hrms/shift",
                "/master/hrms/shift/create",
                "/master/hrms/shift/edit",
                "/master/hrms/shift/details",
              ],
              key: "shift",
            },
            {
              label: "Termination",
              to: "/master/hrms/termination",
              active: ["/master/hrms/termination"],
              key: "termination",
            },
            {
              label: "Break",
              to: "/master/hrms/break",
              active: [
                "/master/hrms/break",
                "/master/hrms/break/create",
                "/master/hrms/break/edit",
                "/master/hrms/break/details",
              ],
              key: "break",
            },
            {
              label: "Break Fine",
              to: "/master/hrms/breakfine",
              active: ["/master/hrms/breakfine"],
              key: "break_fine",
            },
            {
              label: "District",
              to: "/master/hrms/district",
              active: ["/master/hrms/district"],
              key: "district",
            },
            {
              label: "Town",
              to: "/master/hrms/town",
              active: ["/master/hrms/town"],
              key: "town",
            },
            {
              label: "Caste",
              to: "/master/hrms/caste",
              active: ["/master/hrms/caste"],
              key: "caste",
            },
            {
              label: "Religion",
              to: "/master/hrms/religion",
              active: ["/master/hrms/religion"],
              key: "religion",
            },
            {
              label: "Blood Group",
              to: "/master/hrms/bloodgroup",
              active: ["/master/hrms/bloodgroup"],
              key: "blood_group",
            },
            {
              label: "Consultancy Agent",
              to: "/master/hrms/agent/consultancy",
              active: [
                "/master/hrms/agent/consultancy",
                "/master/hrms/agent/consultancy/add",
                "/master/hrms/agent/consultancy/edit",
                "/master/hrms/agent/consultancy/details",
              ],
              key: "consultancy_agent",
            },
            {
              label: "Security Agent",
              to: "/master/hrms/agent/security",
              active: [
                "/master/hrms/agent/security",
                "/master/hrms/agent/security/add",
                "/master/hrms/agent/security/edit",
                "/master/hrms/agent/security/details",
              ],
              key: "security_agent",
            },
            {
              label: "Employment Type",
              to: "/master/hrms/employementType",
              active: [
                "/master/hrms/employementType",
                "/master/hrms/employementType/add",
                "/master/hrms/employementType/edit",
                "/master/hrms/employementType/details",
              ],
              key: "employment_type",
            },
            {
              label: "Half Day variant",
              to: "/master/hrms/halfDayVariant",
              active: [
                "/master/hrms/halfDayVariant",
                "/master/hrms/halfDayVariant/add",
                "/master/hrms/halfDayVariant/edit",
                "/master/hrms/halfDayVariant/details",
              ],
              key: "halfday_variant",
            },
          ],
        },

        {
          name: "Branch",
          id: "branch_master",
          list: [
            {
              label: "Branch",
              to: "/master/hrms/branch",
              active: [
                "/master/hrms/branch",
                "/master/hrms/branch/add",
                "/master/hrms/branch/edit",
                "/master/hrms/branch/details",
              ],
              key: "branch",
            },
            {
              label: "Biometrics",
              to: "/master/hrms/biometrics",
              active: ["/master/hrms/biometrics"],
              key: "biometrics",
            },
            {
              label: "Floor",
              to: "/master/hrms/floor",
              active: ["/master/hrms/floor"],
              key: "floor",
            },
          ],
        },

        {
          name: "Finance",
          id: "finance_master",
          list: [
            {
              label: "Loan",
              to: "/master/hrms/loan",
              active: ["/master/hrms/loan"],
              key: "loan",
            },
            {
              label: "Advance",
              to: "/master/hrms/advance",
              active: ["/master/hrms/advance"],
              key: "advance",
            },
            {
              label: "Bonus",
              to: "/master/hrms/bonus",
              active: ["/master/hrms/bonus"],
              key: "bonus",
            },
            {
              label: "Expenses",
              to: "/master/hrms/expenses",
              active: ["/master/hrms/expenses"],
              key: "expenses",
            },
            {
              label: "Fine",
              to: "/master/hrms/fine",
              active: ["/master/hrms/fine"],
              key: "fine_master",
            },
            {
              label: "Payment",
              to: "/master/hrms/payment",
              active: ["/master/hrms/payment"],
              key: "payment_master",
            },
            {
              label: "Bank",
              to: "/master/hrms/bank",
              active: [
                "/master/hrms/bank",
                "/master/hrms/bank/add",
                "/master/hrms/bank/edit",
                "/master/hrms/bank/details",
              ],
              key: "bank_master",
            },
            {
              label: "Seasonal salary",
              to: "/master/hrms/seasonalsalary",
              active: [
                "/master/hrms/seasonalsalary",
                "/master/hrms/seasonalsalary/add",
                "/master/hrms/seasonalsalary/edit",
                "/master/hrms/seasonalsalary/details",
              ],
              key: "seasonal_salary",
            },
            {
              label: "Reimbursement Scheme",
              to: "/master/hrms/reimbursementScheme",
              active: [
                "/master/hrms/reimbursementScheme",
                "/master/hrms/reimbursementScheme/add",
                "/master/hrms/reimbursementScheme/edit",
                `/master/hrms/reimbursementScheme/${id}`,
              ],
              key: "reimbursement_scheme",
            },
          ],
        },
        {
          name: "Holiday",
          id: "holiday_master",
          list: [
            {
              key: "holiday_scheme",
              label: "Holiday scheme",
              to: "/master/hrms/holidayscheme",
              active: [
                "/master/hrms/holidayscheme",
                "/master/hrms/holidayscheme/add",
                "/master/hrms/holidayscheme/edit",
                "/master/hrms/holidayscheme/details",
              ],
            },
            {
              key: "holiday",
              label: "Holiday",
              to: "/master/hrms/holiday",
              active: [
                "/master/hrms/holiday",
                "/master/hrms/holiday/add",
                "/master/hrms/holiday/edit",
                "/master/hrms/holiday/details",
              ],
            },
            {
              key: "leave",
              label: "Leave",
              to: "/master/hrms/leave",
              active: ["/master/hrms/leave"],
            },
          ],
        },
        {
          name: "Allowance",
          id: "allowance_master",
          list: [
            {
              key: "food_allowance",
              label: "Food Allowance",
              to: "/master/hrms/foodallowance",
              active: [
                "/master/hrms/foodallowance",
                "/master/hrms/foodallowance/create",
                `/master/hrms/foodallowance/edit/${id}`,
                `/master/hrms/foodallowance/details/${id}`,
              ],
            },
          ],
        },
      ],
    },

    {
      main_title: "PO",
      key: "po_masters",
      links: [
        {
          list: [
            {
              label: "Categories",
              to: "/master/po/categories",
              active: ["/master/po/categories"],
              key: "categories",
            },
            {
              label: "Warehouse",
              to: "/master/po/warehouse",
              active: [
                "/master/po/warehouse",
                "/master/po/warehouse/create",
                "/master/po/warehouse/edit",
                "/master/po/warehouse/details",
              ],
              key: "warehouse",
            },
            // {
            //   label: "Warehouse Section",
            //   to: "/master/po/warehouseSection",
            //   active: [
            //     "/master/po/warehouseSection",
            //     "/master/po/warehouseSection/create",
            //     "/master/po/warehouseSection/edit",
            //     "/master/po/warehouseSection/details",
            //   ],
            //   key: "warehouse_section",
            // },

            {
              label: "Department",
              to: "/master/po/department",
              active: [
                "/master/po/department",
                `/master/po/department/create`,
                `/master/po/department/edit/${id}`,
                `/master/po/department/details/${id}`,
              ],
              key: "department",
            },
            {
              label: "Department Section",
              to: "/master/po/departmentSection",
              active: [
                "/master/po/departmentSection",
                "/master/po/departmentSection/create",
                "/master/po/departmentSection/edit",
                "/master/po/departmentSection/details",
              ],
              key: "department_section",
            },
            {
              label: "Suppliers",
              to: "/master/po/suppliers",
              active: [
                "/master/po/suppliers",
                "/master/po/supplier/create",
                "/master/po/supplier/edit",
                "/master/po/supplier/details",
              ],
              key: "suppliers",
            },
            // {
            //   label: "Product Price",
            //   to: "/master/po/productPrice",
            //   active: ["/master/po/productPrice"],
            //   key: "product_price",
            // },
            {
              label: "Attributes",
              to: "/master/po/attributes",
              active: ["/master/po/attributes"],
              key: "attributes",
            },
            {
              label: "Attribute Values",
              to: "/master/po/attributevalue",
              active: ["/master/po/attributevalue"],
              key: "attributes_values",
            },
            {
              label: "Tax",
              to: "/master/po/tax",
              active: ["/master/po/tax"],
              key: "tax",
            },
            {
              label: "Discount",
              to: "/master/po/discount",
              active: ["/master/po/discount"],
              key: "discount",
            },
            {
              label: "PO Terms",
              to: "/master/po/terms",
              active: ["/master/po/terms"],
              key: "po_terms",
            },
            {
              label: "Agent",
              to: "/master/po/agent",
              active: [
                "/master/po/agent",
                "/master/po/agent/create",
                "/master/po/agent/edit",
                "/master/po/agent/details",
              ],
              key: "agent",
            },
            {
              label: "Transport",
              to: "/master/po/transport",
              active: ["/master/po/transport"],
              key: "transport",
            },

            {
              label: "Less",
              to: "/master/po/less",
              active: ["/master/po/less"],
              key: "less",
            },
          ],
        },
      ],
    },
  ];

  const handleToggle = () => {
    $("#toggle-menu").animate({
      width: "toggle",
    });
  };

  return (
    <div className="w-1/5 overflow-x-hidden shadow-md" id="toggle-menu">
      <div className=" pl-[8px] pr-[8px] pt-[12px]">
        <div className="flex justify-end">
          <img
            src={Toggle}
            alt=""
            className="cursor-pointer"
            onClick={handleToggle}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {superAdminMenu?.map((nav, i) => {
          return (
            <React.Fragment key={i}>
              <MenuItems
                selected={selectedMenu === nav.main_title}
                menuName={nav?.key}
                title={nav?.main_title}
                to={nav?.to}
                links={nav?.links}
                handleMenuClick={setSelectedMenu}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default MasterMenus;
