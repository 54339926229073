import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API, POST_API, PUT_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Edit from "../../../Assets/Icons/Edit.svg";
import CategoriesTabel from "../../DataTable/PurchaseOrder/CategoriesTable";
import POValidation from "../../../Validation/POValidation";

function POCategories() {
  const [editStatus, setEditStatus] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [categorydetails, setCategorydetails] = useState({
    name: "",
    description: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getPOCategoryList(currentPage, pageSize, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const getPOCategoryList = async (page, size, search) => {
    setLoading(true);
    try {
      const categoryList = await GET_API(
        `/po/categories?page=${page}&size=${size}&search=${search}`
      );
      if (categoryList?.data?.status === 200) {
        setLastPage(categoryList?.data?.data?.last_page);
        // setCategoryList(categoryList?.data?.data?.data);
        setFilteredList(categoryList?.data?.data?.data);
        setLoading(false);
      }
      if (
        categoryList?.data?.data?.current_page >
        categoryList?.data?.data?.last_page
      ) {
        isPageNotExit();
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Categories List");
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getPOCategoryList(1, pageSize, searchKey);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Category..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/po/deleteCategory/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPOCategoryList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Category");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const addCategory = async (e, type) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = POValidation.Categories(categorydetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const url =
        type === "add"
          ? POST_API("/po/addCategory", categorydetails)
          : PUT_API("/po/updateCategory", categorydetails);
      const createCategory = await url;
      if (createCategory?.data?.status === 200) {
        setButtonDisabled(false);
        setEditStatus(false);
        setCategorydetails({ name: "", description: "" });
        getPOCategoryList(currentPage, pageSize, searchKey);
        alertify.success(createCategory?.data?.message);
        return;
      }
    } catch (error) {
      alertify.error("Failed to create category name...");
      alertify.error(error?.response?.data?.data?.name.toString());
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const resetForm = () => {
    setCategorydetails({ name: "", description: "" });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Categories</h4>
          <form
            onSubmit={() => getPOCategoryList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Category Name"
                onChange={(e) => {
                  setCategorydetails({
                    ...categorydetails,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                autoComplete="off"
                value={categorydetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  setCategorydetails({
                    ...categorydetails,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                value={categorydetails.description}
                autoComplete="off"
                rows={5}
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addCategory(e, "edit");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addCategory(e, "add");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <CategoriesTabel
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setCategorydetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default POCategories;
