import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { POST_API, GET_API } from "../../Services/api";
import EmployeeBasicInfoComp from "./Tabs/BasicInfo";
import EmployeeBankInfoComp from "./Tabs/Bankinfo";
import EmployeeSalaryInfoComp from "./Tabs/SalaryInfo";
import EmployeePositionInfoComp from "./Tabs/Positioninfo";
import EmployeePersonalInfoComp from "./Tabs/PersonaInfo";
import EmployeeEducationInfoComp from "./Tabs/EducationInfo";
import EmployeeFoodAllowanceComp from "./Tabs/FoodAllowance";
import EmployeeAllowanceComp from "./Tabs/Allowance";
import Save from "../../Assets/Icons/Save.svg";
import Validation from "../../Validation/Validation";
import Spinner from "../Spinner";
import ButtonLoader from "../ButtonLoader";
import "alertifyjs/build/css/alertify.css";

function CreateEmployees() {
  const navigate = useNavigate();
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Food Allowance
  const [foodAllowanceValue, setFoodAllowanceValue] = useState([]);
  const [foodAllowanceOption, setFoodAllowanceOption] = useState([]);

  // Allowance
  const [allowanceValue, setallowanceValue] = useState([
    {
      allowance_type_id: "",
      in_compliance: false,
      calculation_method: "",
      amount: "",
    },
  ]);
  const [allowanceTypeList, setallowanceTypeList] = useState([]);
  const [isAllowanceTypeLoading, setIsAllowanceTypeLoading] = useState(false);

  const [empDetails, setEmpDetails] = useState({
    profile_image: "",
    image: "",
    first_name: "",
    employee_type_id: "",
    department_id: "",
    designation_id: "",
    emp_code: "",
    branch_id: "",
    shift_id: "",
    floor: "",
    break_time_id: "",
    reporting_to: "",
    holiday_scheme_id: "",
    halfday_variant_id: "",
    date_of_joining: "",
    biometric_device: [],
    agent_id: "",
    date_of_birth: "",
    blood_group_id: "",
    present_address: "",
    permanent_address: "",
    gender: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    // is_food_allowance_applicable: 0,
    bank_name: "",
    bank_branch: "",
    pf_number: "",
    esi_number: "",
    loan_deduction_amount: "",
    is_deducting_pf: 0,
    pmrpy_scheme: 0,
    pf_complaint: 0,
    dummy_basic: "",
    pf_amount: "",
    // gross_salary: "",
    account_salary: "",
    in_cash: "",
    approved_by: "",
    email: "",
    phone_no: "",
    alternate_phone_no: "",
    marital_status: "",
    fathers_name: "",
    religion_id: "",
    caste_id: "",
    government_proof_id: "",
    government_proof_value: "",
    proof_image: "",
    education: "",
    district: "",
    town: "",
    passed_out: "",
    additional_qualification: "",
    remarks: "",
    allowance_details: [],
    food_allowance: [],
  });

  useEffect(() => {
    getFoodList();
    getAllowanceTypeList();
  }, []);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setFoodAllowanceOption(list);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getAllowanceTypeList = async () => {
    try {
      setIsAllowanceTypeLoading(true);
      const allowance_type_list = await GET_API("/allowanceTypeDropdown");
      if (allowance_type_list?.status === 200) {
        const allowType = allowance_type_list.data?.data?.map((option) => ({
          label: option?.name,
          value: option?.id,
        }));
        setallowanceTypeList(allowType);
        setIsAllowanceTypeLoading(false);
        return;
      }
    } catch (error) {
      setIsAllowanceTypeLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBtnDisable(true);
    // const isValid = Validation.validateEmployee(empDetails);
    // if (isValid && isValid?.error) {
    //   setErrors({
    //     [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
    //   });
    //   setIsBtnDisable(false);
    //   const errorInput = document.getElementsByName(
    //     isValid?.error?.details[0].path[0]
    //   )[0];
    //   errorInput.scrollIntoView({ behavior: "smooth" });
    //   return;
    // } else {
    //   setErrors({});
      try {
        const submitData = {
          ...empDetails,
          food_allowance: foodAllowanceValue?.map((opt) => opt?.value),
          allowance_details: allowanceValue,
        };

        const createEmployee = await POST_API("/addEmployee", submitData);
        if (createEmployee.status === 200) {
          alertify.success(createEmployee?.data?.message);
          navigate("/employee/list");
          if (createEmployee?.data?.data?.filename) {
            const alink = document.createElement("a");
            alink.href = createEmployee.data.data.filename;
            alink.setAttribute("target", "_blank");
            alink.download = "Employee.pdf";
            alink.click();
            setIsBtnDisable(false);
          }
          return;
        }
      } catch (error) {
        alertify.error(error?.message);
        setIsBtnDisable(false);
      }
    // }
  };

  if (isLoading || isAllowanceTypeLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow" style={{ width: "80%" }}>
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/employee/list" className="in-active">
              Employee List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Employee</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Employee</div>

          <div>
            <EmployeePositionInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
              setErrors={setErrors}
              page="create"
            />
          </div>
          <div>
            <EmployeeBasicInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeePersonalInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
          <div>
            <EmployeeEducationInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeBankInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeSalaryInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeFoodAllowanceComp
              foodAllowanceValue={foodAllowanceValue}
              setFoodAllowanceValue={setFoodAllowanceValue}
              foodAllowanceOption={foodAllowanceOption}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeAllowanceComp
              allowanceTypeList={allowanceTypeList} // allowanceTypeList
              allowanceValue={allowanceValue}
              setallowanceValue={setallowanceValue}
              errors={errors}
            />
          </div>
          <div>
            <div className="submit-reset-button">
              <button type="button" className="reset-button">
                Reset
              </button>
              {isBtnDisable ? (
                <ButtonLoader isBtnDisable={isBtnDisable} />
              ) : (
                <button
                  className="submit-button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <span className="submit-button-label">Submit</span>
                  <img src={Save} alt="" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEmployees;
