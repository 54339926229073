import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import BonusPrintVoucher from "./BonusPrintVoucher";
import { useReactToPrint } from "react-to-print";

function BonusView() {
  const { state } = useLocation();
  const componentRef = useRef();
  const listDetails = state?.list;
  
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + listDetails?.fathers_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.EmployeeCode,
    },
    {
      label: "Department Name",
      value: listDetails?.department_name,
    },
    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
    {
      label: "Finance Category",
      value: listDetails?.finance_category_name,
    },

    {
      label: "Voucher No",
      value: listDetails?.voucher_no,
    },
    {
      label: "Amount",
      value: listDetails?.amount,
    },
    {
      label: "Paid Status",
      value: listDetails?.voucher_status === 1 ? "Paid" : "Unpaid",
    },
    {
      label: "Approved By",
      value: listDetails?.approvedby,
    },

    {
      label: "Remarks",
      value: listDetails?.remarks,
    },
    {
      label: "Status",
      value: listDetails?.status === 0 ? "Approved" :
        listDetails?.status === 2 ? "Rejected" :
          listDetails?.status === 1 ? "Pending" : "--",
    },
  ];

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <Link to="/expense/bonus" className="in-active">
            Bonus Details List
          </Link>
          <Link>{">"}</Link>
          <Link className="active">Bonus Details</Link>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Bonus Details</div>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            {listDetails?.status === 0 ?
              <button
                type="button"
                className="submit-button"
                onClick={() => printVoucher()}
              >
                <span className="submit-button-label">Print</span>
              </button> : null
            }
          </div>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {listDetails?.status === 0 ?
            <BonusPrintVoucher
              ref={componentRef}
              printVoucherData={listDetails}
            /> : null
          }
        </div>
      </div>
    </div>
  );
}

export default BonusView;
