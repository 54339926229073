import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Select from "react-select";

const PayRollUpdateAttendanceModal = ({
  isOpen,
  handleModal,
  handleSubmit,
  handleChange,
  handleFilter,
  filterList,
  attendanceList,
  status,
  isBtnDisable,
}) => {
  const handleClickAway = () => {};
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dialog
        className="testing"
        open={isOpen}
        onClose={handleModal}
        disableBackdropClick={false}
        PaperProps={{
          style: {
            minWidth: "500px",
            minHeight: "300px",
          },
        }}
      >
        <DialogTitle>Update Attendance Status</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => handleSubmit(e)}>
            

            <div className="field-block rejoin-employee-width">
              <label className="label-name">Date</label>
              <input
                type="date"
                className="input-field"
                name="date"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div className="field-block rejoin-employee-width">
              <label className="label-name">Attendance Type</label>
              <Select
                    options={attendanceList}
                    value={attendanceList?.find(
                      (option) => option?.value === filterList.status
                    )}
                    onChange={(e) => handleFilter(e, "status", "select")}
                    name="status"
                    className=""
                  />
            </div>

            <div className="text-right flex mt-6 justify-end">
              <button disabled={isBtnDisable} type="submit" className="submit-button">
                <span className="reset-button-label">
                  <span className="submit-button-label">Update</span>
                </span>
              </button>
              <button
                type="button"
                className="reset-button ml-3"
                onClick={handleModal}
              >
                <span className="reset-button-label">Cancel</span>
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </ClickAwayListener>
  );
};

export default PayRollUpdateAttendanceModal;
