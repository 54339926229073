import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import dayjs from "dayjs";
import Pagination from "./Pagination";

export default function StickyHeadTable({
  list,
  columns,
  bonus,
  bulkBonus,
  setBulkBonus,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  const handleBonusType = (e, empID, i) => {
    var isEmployeeExist = bulkBonus?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const bonusList = {
        employee_id: empID,
        bonus_type_id: e?.value,
        amount: "",
      };
      const totalList = { ...bulkBonus };
      totalList.data[i] = bonusList;
      setBulkBonus(totalList);
    } else {
      const changedRow = bulkBonus?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, bonus_type_id: e?.value };
        } else {
          return list;
        }
      });
      setBulkBonus({ ...bulkBonus, data: [] });
      setBulkBonus({ ...bulkBonus, data: changedRow });
    }
  };

  const handleAmount = (e, empID, index) => {
    const { value } = e?.target;
    var isEmployeeExist = bulkBonus?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const bonusList = {
        index: index,
        employee_id: empID,
        amount: value,
      };
      const totalList = { ...bulkBonus };
      totalList?.data?.push(bonusList);
      setBulkBonus(totalList);
    } else {
      const changedRow = bulkBonus?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, amount: value };
        } else {
          return list;
        }
      });
      setBulkBonus((prevState) => ({ ...prevState, data: [] }));
      setBulkBonus((prevState) => ({ ...prevState, data: changedRow }));

      console.log("bulkkkk", bulkBonus);
    }
  };
  const resetRow = (e, empID) => {
    const filterRow = bulkBonus?.data?.map((row) => {
      if (row?.employee_id === empID) {
        return { ...row, amount: "", bonus_type_id: "" };
      } else {
        return row;
      }
    });
    setBulkBonus({ ...bulkBonus, data: filterRow });
  };

  const getEmpDuration = (JoiningDate) => {
    const startDate = dayjs(JoiningDate);
    const endDate = dayjs();

    const diffInDays = endDate.diff(startDate, "day");
    const years = Math.floor(diffInDays / 365);
    const months = Math.floor((diffInDays % 365) / 30);
    const days = diffInDays % 30;

    return `${years} years ${months} months ${days} days`;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employeeName}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.emp_code}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {dayjs(row?.date_of_joining).format("DD-MM-YYYY")}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {getEmpDuration(row?.date_of_joining)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.holidayschemename}
                      </TableCell>
                    }
                    {
                      // <TableCell className="table-body">
                      //   <Select
                      //     options={bonus}
                      //     onChange={(e) => handleBonusType(e, row?.Id, index)}
                      //     placeholder="Please Select"
                      //     name="bonus_type_id"
                      //     className=""
                      //     isSearchable
                      //     value={
                      //       bulkBonus?.data[index]?.bonus_type_id
                      //         ? bulkBonus?.data?.find(
                      //             (option) =>
                      //               option?.employee_type_id ===
                      //               bulkBonus?.data[index]?.bonus_type_id
                      //           )
                      //         : ""
                      //     }
                      //   />
                      // </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <input
                          type="number"
                          name="amount"
                          className="border-[1px] border-[#D9D9D9] p-1 rounded"
                          placeholder="Enter Loan Amount"
                          onChange={(e) => handleAmount(e, row?.Id)}
                          value={
                            // bulkBonus.data[row?.Id]?.amount || row.bonus_amount
                            bulkBonus?.data?.find((o) => o.employee_id === row?.Id)?.amount ?? row?.bonus_amount
                          }
                        />
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body submit-reset-button">
                        <button
                          type="button"
                          className="reset-button"
                          onClick={(e) => resetRow(e, row?.Id)}
                        >
                          <span className="reset-button-label">Reset</span>
                        </button>
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}

function ListRow({
  row,
  index,
  page,
  rowsPerPage,
  currentPage,
  bonus,
  bulkBonus,
  setBulkBonus,
  employeeList,
}) {
  const handleBonusType = (e, empID, i) => {
    var isEmployeeExist = bulkBonus?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const bonusList = {
        employee_id: empID,
        bonus_type_id: e?.value,
        amount: "",
      };
      const totalList = { ...bulkBonus };
      totalList.data[i] = bonusList;
      setBulkBonus(totalList);
    } else {
      const changedRow = bulkBonus?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, bonus_type_id: e?.value };
        } else {
          return list;
        }
      });
      setBulkBonus({ ...bulkBonus, data: [] });
      setBulkBonus({ ...bulkBonus, data: changedRow });
    }
  };

  const handleAmount = (e, empID, index) => {
    const { value } = e?.target;
    console.log("valllueee", value);
    console.log("vallluindex", index);
    console.log("vallluempID", empID);
    var isEmployeeExist = bulkBonus?.data?.some((item) => {
      return item?.employee_id === empID;
    });

    if (!isEmployeeExist) {
      const bonusList = {
        index: index,
        employee_id: empID,
        amount: value,
      };
      const totalList = { ...bulkBonus };
      totalList?.data?.push(bonusList);
      setBulkBonus(totalList);
    } else {
      const changedRow = bulkBonus?.data?.map((list) => {
        if (list?.employee_id === empID) {
          return { ...list, amount: value };
        } else {
          return list;
        }
      });
      // setBulkBonus({ ...bulkBonus, data: [] });
      // setBulkBonus({ ...bulkBonus, data: changedRow });
      setBulkBonus((prevState) => ({ ...prevState, data: [] }));
      setBulkBonus((prevState) => ({ ...prevState, data: changedRow }));

      console.log("bulkkkk", bulkBonus);
    }
  };
  const resetRow = (e, empID) => {
    const filterRow = bulkBonus?.data?.map((row) => {
      if (row?.employee_id === empID) {
        return { ...row, amount: "", bonus_type_id: "" };
      } else {
        return row;
      }
    });
    setBulkBonus({ ...bulkBonus, data: filterRow });
  };

  const getEmpDuration = (JoiningDate) => {
    const startDate = dayjs(JoiningDate);
    const endDate = dayjs();

    const diffInDays = endDate.diff(startDate, "day");
    const years = Math.floor(diffInDays / 365);
    const months = Math.floor((diffInDays % 365) / 30);
    const days = diffInDays % 30;

    return `${years} years ${months} months ${days} days`;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      className="table-column"
    >
      {
        <TableCell className="table-body !w-[7%]">
          {(currentPage - 1) * rowsPerPage + (index + 1)}
        </TableCell>
      }
      {<TableCell className="table-body">{row?.employeeName}</TableCell>}
      {<TableCell className="table-body">{row?.emp_code}</TableCell>}
      {<TableCell className="table-body">{row?.amount}</TableCell>}
      {
        <TableCell className="table-body">
          {dayjs(row?.date_of_joining).format("DD-MM-YYYY")}
        </TableCell>
      }
      {
        <TableCell className="table-body">
          {getEmpDuration(row?.date_of_joining)}
        </TableCell>
      }
      {<TableCell className="table-body">{row?.holidayschemename}</TableCell>}
      {
        // <TableCell className="table-body">
        //   <Select
        //     options={bonus}
        //     onChange={(e) => handleBonusType(e, row?.Id, index)}
        //     placeholder="Please Select"
        //     name="bonus_type_id"
        //     className=""
        //     isSearchable
        //     value={
        //       bulkBonus?.data[index]?.bonus_type_id
        //         ? bulkBonus?.data?.find(
        //             (option) =>
        //               option?.employee_type_id ===
        //               bulkBonus?.data[index]?.bonus_type_id
        //           )
        //         : ""
        //     }
        //   />
        // </TableCell>
      }
      {
        <TableCell className="table-body">
          <input
            type="number"
            name="amount"
            className="border-[1px] border-[#D9D9D9] p-1 rounded"
            placeholder="Enter Loan Amount"
            onChange={(e) => handleAmount(e, row?.Id)}
            value={bulkBonus?.data?.find((o, i) => {
              return o.employee_id === row?.Id ? o.amount : row?.bonus_amount;
            })}
          />
        </TableCell>
      }

      {
        <TableCell className="table-body submit-reset-button">
          <button
            type="button"
            className="reset-button"
            onClick={(e) => resetRow(e, row?.Id)}
          >
            <span className="reset-button-label">Reset</span>
          </button>
        </TableCell>
      }
    </TableRow>
  );
}
