import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Select from "react-select";
import Spinner from "../Spinner";

function CreateUser() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, seterrors] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [user, setUser] = useState({
    employee_id: "",
    role_id: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(() => ({ ...user, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setUser({ ...user, [name]: e?.value });
  };

  useEffect(() => {
    getEmployeeList();
    getRoleList();
  }, []);

  const getEmployeeList = async () => {
    setLoader1(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setLoader1(false);
      }
    } catch (error) {
      setLoader1(false);
      alertify?.error("Failed to Get Employee List...");
    }
  };

  const getRoleList = async () => {
    setLoader2(true);
    try {
      const roleList = await GET_API("RolesListDropdown");
      if (roleList?.data?.status === 200) {
        const list = roleList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.name}`,
          };
        });
        list.unshift({ value: "", label: "Select..." });
        setRoleList(list);
        setLoader2(false);
      }
    } catch (error) {
      setLoader2(false);
      alertify?.error("Failed to get Role list...");
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.ValidateUser(user);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const addUserList = await POST_API("/addUser", user);
      if (addUserList?.data?.status === 200) {
        alertify.success(addUserList?.data?.message);
        setButtonDisabled(false);
        navigate("/master/hrms/user");
      }
    } catch (error) {
      Object.keys(error?.response?.data?.data).forEach(function (key) {
        alertify?.error(error?.response?.data?.data[key]?.toString());
      });
      setButtonDisabled(false);
    }
  };

  const resetForm = () => {
    setUser({
      name: "",
      description: "",
      slug: "",
    });
  };

  if (loader1 || loader2) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/user" className="in-active">
              User List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add User</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add User</h4>
          <form onSubmit={(e) => createUser(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>

                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === user.employee_id
                  )}
                  onChange={(e) => handleSelectChange(e, "employee_id")}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Role
                  <span className="required-symbol">*</span>
                </label>

                <Select
                  options={roleList}
                  value={roleList?.find(
                    (option) => option?.value === user.role_id
                  )}
                  onChange={(e) => handleSelectChange(e, "role_id")}
                  name="role_id"
                  className=""
                  isSearchable
                />

                {errors.role_id ? (
                  <div className="validation-error-label">{errors.role_id}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Email
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field "
                  type="email"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="email"
                  placeholder="Enter Slug Name"
                  value={user?.email}
                />

                {errors.email ? (
                  <div className="validation-error-label">{errors.email}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Password
                  <span className="required-symbol">*</span>
                </label>
                <div className="flex gap-2">
                  <span className="text-sm text-text-light">Show Password</span>
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    value={isShowPassword}
                    onChange={() => {
                      setIsShowPassword((prev) => !prev);
                    }}
                  />
                </div>

                <input
                  className="input-field "
                  type={`${isShowPassword === true ? "text" : "password"}`}
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="password"
                  placeholder="Enter Slug Name"
                  value={user?.password}
                />

                {errors.password ? (
                  <div className="validation-error-label">
                    {errors.password}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>

              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
