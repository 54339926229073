import React, { useEffect, useState } from "react";
import PolarChart from "../Chart/DashboardChart";
import TotalEmployeeIcon from "../../Assets/Icons/AttendanceReport/TotalEmployee.svg";
import PresentEmployeeIcon from "../../Assets/Icons/AttendanceReport/PresentEmployee.svg";
import LongLeaveAlert from "../../Assets/Icons/AttendanceReport/LongLeaveAlert.svg";
import AbsentEmployeeIcon from "../../Assets/Icons/AttendanceReport/AbsentEmployee.svg";
import LeaveEmployeeIcon from "../../Assets/Icons/AttendanceReport/LeaveEmployee.svg";
import { GET_API } from "../../Services/api";
import Spinner from "../Spinner";
import { Link } from "react-router-dom";

function Dashboard() {
  const [TotalEmployee, setTotalEmployee] = useState(0);
  const [PresentEmployee, setPresentEmployee] = useState(0);
  const [longLeaveEmp, setLongLeaveEmp] = useState(0);
  const [AbsentEmployee, setAbsentEmployee] = useState(0);
  const [LeaveEmployee, setLeaveEmployee] = useState(0);
  const [lateEntry, setLateEntry] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    reportData();
  }, []);

  const reportData = async () => {
    try {
      setLoading(true);
      const report = await GET_API("webDashboard");
      if (report?.status === 200) {
        setTotalEmployee(report?.data?.data?.totalEmployee);
        setPresentEmployee(report?.data?.data?.presentEmployee);
        setAbsentEmployee(report?.data?.data?.absentEmployee);
        setLeaveEmployee(report?.data?.data?.leaveEmployee);
        setLateEntry(report?.data?.data?.lateEntry);
        setLongLeaveEmp(report?.data?.data?.longLeaveEmployee);
        setChartData(report?.data?.data?.empDetails);
        setLoading(false);
      }
    } catch (error) {}
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]" style={{ width: "80%" }}>
      <div className="sm-container">
        <h1 className="heading-text">Dashboard</h1>

        <div className="report-card-block">
          <Link to="/report/employee" className="card">
            <div className="content-left">
              <span className="card-value">{TotalEmployee}</span>
              <span className="card-label">Total Employees</span>
            </div>
            <div className="content-right">
              <img src={TotalEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/attendance" className="card">
            <div className="content-left">
              <span className="card-value">{PresentEmployee}</span>
              <span className="card-label">Employee Present</span>
            </div>
            <div className="content-right">
              <img src={PresentEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/absent" className="card">
            <div className="content-left">
              <span className="card-value">{AbsentEmployee}</span>
              <span className="card-label">Employee Absent</span>
            </div>
            <div className="content-right">
              <img src={AbsentEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/leave" className="card">
            <div className="content-left">
              <span className="card-value">{LeaveEmployee}</span>
              <span className="card-label">Employee Leave</span>
            </div>
            <div className="content-right">
              <img src={LeaveEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/latefine" className="card">
            <div className="content-left">
              <span className="card-value">{lateEntry}</span>
              <span className="card-label">Late Entry Employee</span>
            </div>
            <div className="content-right">
              <img src={LeaveEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/long/leave" className="card">
            <div className="content-left">
              <span className="card-value">{longLeaveEmp}</span>
              <span className="card-label">Long Leave Count</span>
            </div>
            <div className="content-right">
              <img src={LongLeaveAlert} alt="" />
            </div>
          </Link>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Overall Report</h4>
          <PolarChart data={chartData} />
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
