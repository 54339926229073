import HomePageMenu from "./Menus/HomePageMenu";
import Layout from "./Layouts/Layout";
import { Outlet } from "react-router-dom";
import UserAuthenticator from "./UserAuthenticator";
import PurchaseOrderMenus from "./Menus/PurchaseOrderMenus";
import MasterMenus from "./Menus/MasterMenus";
function AllRoutes() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export function HomePageLayout() {
  return (
    <div className="w-full flex grow h-[calc(100vh-67px)]">
      <UserAuthenticator>
        <Outlet />
      </UserAuthenticator>
    </div>
  );
}

export function HRMSSideBar() {
  return (
    <div className="w-full flex grow h-[calc(100vh-67px)]">
      <HomePageMenu />
      <UserAuthenticator>
        <Outlet />
      </UserAuthenticator>
    </div>
  );
}

export function PurchaseOrderSideBar() {
  return (
    <div className="w-full flex grow h-[calc(100vh-67px)]">
      <PurchaseOrderMenus />
      <UserAuthenticator>
        <Outlet />
      </UserAuthenticator>
    </div>
  );
}

export function MasterRoutesSideBar() {
  return (
    <div className="w-full flex grow h-[calc(100vh-67px)]">
      <MasterMenus />
      <UserAuthenticator>
        <Outlet />
      </UserAuthenticator>
    </div>
  );
}

export default AllRoutes;
