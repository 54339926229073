import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";

function EmployeeBasicInfo({ stateChanger, empDetails, errors }) {
  const [bloodgroupList, setbloodgroupList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    stateChanger(() => ({ ...empDetails, [name]: e?.value }));
  };

  const handleChanges = (event) => {
    event.target.checked
      ? stateChanger(() => ({
          ...empDetails,
          permanent_address: empDetails["present_address"],
        }))
      : stateChanger(() => ({ ...empDetails, permanent_address: "" }));
  };

  useEffect(() => {
    getBloodgroup();
    getHRAgentList();
    // getDistrictList();
    // getTownList();
  }, []);

  const getBloodgroup = async () => {
    setLoading1(true);
    try {
      const bloodGroup = await GET_API("/bloodGroupDropdown");
      if (bloodGroup?.data?.status === 200) {
        const blood = bloodGroup?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        blood.unshift({ value: "", label: "Select..." });
        setbloodgroupList(blood);
        setLoading1(false);
      }
    } catch (error) {
      alertify?.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Failed to get Blood group List..."
      );
      setLoading1(false);
    }
  };

  const getHRAgentList = async () => {
    setLoading2(true);
    try {
      const HRAgent = await GET_API("/agentListDropdown/consultancy");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.contact_person };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setLoading2(false);
      }
    } catch (error) {
      alertify?.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Failed to get HR Agent List..."
      );
      setLoading2(false);
    }
  };

  // const getDistrictList = async () => {
  //   setLoading3(true);
  //   try {
  //     const districtList = await GET_API("/DistrictDropdown");
  //     if (districtList.status === 200) {
  //       const district = districtList?.data?.data.map((dep, i) => {
  //         return { value: dep?.id, label: dep?.name };
  //       });
  //       district.unshift({ value: "", label: "Select..." });
  //       setDistrict(district);
  //       setLoading3(false);
  //     }
  //   } catch (e) {
  //     setLoading3(false);
  //     alertify.error("Failed to Get District List");
  //   }
  // };

  // const getTownList = async () => {
  //   setLoading4(true);
  //   try {
  //     const townList = await GET_API("/TownDropdown");
  //     if (townList.status === 200) {
  //       const town = townList?.data?.data.map((dep, i) => {
  //         return { value: dep?.id, label: dep?.name };
  //       });
  //       town.unshift({ value: "", label: "Select..." });
  //       setTown(town);
  //       setLoading4(false);
  //     }
  //   } catch (e) {
  //     setLoading4(false);
  //     alertify.error("Failed to Get Town List");
  //   }
  // };

  if (loading1 || loading2 || loading3 || loading4) return <Spinner />;

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="w-3/12 p-3 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Basic Info
        </span>
      </div>
      <form>
        <div className="form">
          <div className="field-block" name="agent_id">
            <label className="label-name">
              HR Agent
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <Select
              options={hragentList}
              value={hragentList?.find(
                (option) => option?.value === empDetails?.agent_id
              )}
              onChange={(e) => handleSelectChange(e, "agent_id")}
              name="agent_id"
              className=""
              isSearchable
            />
            {errors?.agent_id ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.agent_id}
              >
                {errors?.agent_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="date_of_birth">
            <label className="label-name">
              Date of Birth
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <input
              type="date"
              name="date_of_birth"
              className="input-field"
              placeholder="Select Date"
              autoComplete="off"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                handleChange(e);
              }}
              value={empDetails["date_of_birth"]}
            />
            {errors?.date_of_birth ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.date_of_birth}
              >
                {errors?.date_of_birth}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="blood_group_id">
            <label className="label-name">
              Blood Group
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <Select
              options={bloodgroupList}
              value={bloodgroupList?.find(
                (option) => option?.value === empDetails.blood_group_id
              )}
              onChange={(e) => handleSelectChange(e, "blood_group_id")}
              name="blood_group_id"
              className=""
              isSearchable
            />
            {errors?.blood_group_id ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.blood_group_id}
              >
                {errors?.blood_group_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="district">
            <label className="label-name">
              District
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <input
              className="input-field"
              type="text"
              name="district"
              placeholder="Enter District name"
              onChange={(e) => handleChange(e)}
              value={empDetails?.district}
            />

            {errors?.district ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.district}
              >
                {errors?.district}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="town">
            <label className="label-name">
              Town
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <input
              className="input-field"
              type="text"
              name="town"
              placeholder="Enter Town name"
              onChange={(e) => handleChange(e)}
              value={empDetails?.town}
            />

            {errors?.town ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.town}
              >
                {errors?.town}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="present_address">
            <label className="label-name">
              Present Address
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <div className="flex flex-col gap-1">
              <textarea
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                value={empDetails?.present_address}
                autoComplete="off"
                placeholder="Enter Address"
                name="present_address"
                rows={5}
              />
              {errors?.present_address ? (
                <div
                  className="text-[#FF5C42] text-sm font-medium font-karla"
                  key={errors?.present_address}
                >
                  {errors?.present_address}
                </div>
              ) : null}
            </div>
          </div>

          <div className="field-block" name="permanent_address">
            <label className="label-name">
              Permanent Address
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>

            <div className="flex flex-col gap-1">
              <textarea
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                value={empDetails?.permanent_address}
                autoComplete="off"
                placeholder="Enter Address"
                name="permanent_address"
                rows={5}
              />
              {errors?.permanent_address ? (
                <div
                  className="text-[#FF5C42] text-sm font-medium font-karla"
                  key={errors?.permanent_address}
                >
                  {errors?.permanent_address}
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label className="label-name cursor-pointer">
              <Checkbox
                style={{ color: "rgb(208 163 76 / var(--tw-bg-opacity))" }}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
                checked={
                  empDetails &&
                  empDetails?.present_address !== "" &&
                  empDetails?.permanent_address !== "" &&
                  empDetails?.present_address === empDetails?.permanent_address
                    ? true
                    : false
                }
              />
              Same as Present address
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeeBasicInfo;
