import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Spinner from "../../../Spinner";
import alertify from "alertifyjs";
import { GET_API } from "../../../../Services/api";

function PODepartmentDetails() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState([]);
  const id = state?.list;
  const Details = [
    {
      label: "Deparetment Name",
      value: departmentDetails?.name,
    },

    {
      label: "Section Name",
      value: departmentDetails?.section,
    },
    {
      label: "HSN Code",
      value: departmentDetails?.hsn_code,
    },
    {
      label: "Category",
      value: departmentDetails?.category,
    },
  ];

  useEffect(() => {
    getDepartmentDetails();
  }, []);

  const getDepartmentDetails = async () => {
    setLoading(true);
    try {
      const departmentData = await GET_API(`po/getDepartment/${id}`);
      if (departmentData?.data?.status === 200) {
        const listData = departmentData?.data?.data;
        setDepartmentDetails({
          id: listData?.id,
          name: listData?.name,
          section: listData?.section,
          hsn_code: listData?.hsn_code,
          category: listData?.categoryName,
          description: listData?.description,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get department details");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/po/department" className="in-active">
                Department List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Department Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Department Details</h4>
            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PODepartmentDetails;
