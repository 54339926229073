import React, { useState } from "react";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import { Checkbox } from "@mui/material";

function EmployeeBankInfo({ stateChanger, empDetails, errors }) {
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, value, checked } = e?.target;
    if (
      (name === "pf_complaint" || name === "pmrpy_scheme") &
      (checked !== "")
    ) {
      const values = checked === true ? 1 : 0;
      stateChanger(() => ({ ...empDetails, [name]: values }));
      return;
    }
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e?.target;

    stateChanger(() => ({
      ...empDetails,
      [name]: checked ? 1 : 0,
    }));
  };

  const handleIFSC = async (e) => {
    const { name, value } = e?.target;
    if (name === "ifsc_code" && value !== "" && value.length) {
      const branchDetails = await GET_API(`/getIfscCode/${value}`);
      if (branchDetails?.data?.status === 200 && branchDetails?.data?.data) {
        setLoading(false);
        stateChanger(() => ({
          ...empDetails,
          ifsc_code: value,
          bank_name: branchDetails?.data?.data?.bank_name,
          bank_branch: branchDetails?.data?.data?.branch_name,
        }));
        return;
      } else if (branchDetails?.data?.status === 400) {
        console.log(5);
        setLoading(false);
        alertify.error(branchDetails?.data?.message);
        stateChanger(() => ({
          ...empDetails,
          ifsc_code: value,
          bank_name: '',
          bank_branch: '',
        }));
        return;
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="w-3/12 p-3 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Bank Info
        </span>
      </div>
      <form>
        <div className="form">
          <div className="field-block" name="ifsc_code">
            <label className="label-name">IFSC Code</label>
            <input
              name="ifsc_code"
              className="input-field"
              type="text"
              onBlur={handleIFSC}
              onChange={(e) => handleChange(e)}
              placeholder="Enter IFSC Code"
              autoComplete="off"
              value={empDetails?.ifsc_code}
            />
            {errors?.ifsc_code ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.ifsc_code}
              >
                {errors?.ifsc_code}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="bank_name">
            <label className="label-name">Bank name</label>
            <input
              name="bank_name"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Bank name"
              autoComplete="off"
              value={empDetails?.bank_name}
            />
            {errors?.bank_name ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.bank_name}
              >
                {errors?.bank_name}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="bank_branch">
            <label className="label-name">Branch name</label>
            <input
              name="bank_branch"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Branch name"
              autoComplete="off"
              value={empDetails?.bank_branch}
            />
            {errors?.bank_branch ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.bank_branch}
              >
                {errors?.bank_branch}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="account_holder_name">
            <label className="label-name">Account Holder name</label>
            <input
              name="account_holder_name"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account Holder name"
              autoComplete="off"
              value={empDetails?.account_holder_name}
            />
            {errors?.account_holder_name ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.account_holder_name}
              >
                {errors?.account_holder_name}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="account_number">
            <label className="label-name">Account No</label>
            <input
              name="account_number"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account No"
              autoComplete="off"
              value={empDetails?.account_number}
            />
            {errors?.account_number ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.account_number}
              >
                {errors?.account_number}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="pf_number">
            <label className="label-name">PF Number</label>
            <input
              name="pf_number"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter PF number"
              autoComplete="off"
              value={empDetails?.pf_number}
            />
            {errors?.pf_number ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.pf_number}
              >
                {errors?.pf_number}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="esi_number">
            <label className="label-name">ESI Number</label>
            <input
              name="esi_number"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter ESI number"
              autoComplete="off"
              value={empDetails?.esi_number}
            />
            {errors?.esi_number ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.esi_number}
              >
                {errors?.esi_number}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="bonus">
            <label className="label-name">Bonus</label>
            <input
              name="bonus"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Bonus"
              autoComplete="off"
              value={empDetails?.bonus}
            />
            {errors?.bonus ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.bonus}
              >
                {errors?.bonus}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="loan_deduction_amount">
            <label className="label-name">Loan Deduction Amount</label>
            <input
              name="loan_deduction_amount"
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Loan Deduction Amount"
              autoComplete="off"
              value={empDetails?.loan_deduction_amount}
            />
            {errors?.loan_deduction_amount ? (
              <div
                className="text-[#FF5C42] text-sm font-medium font-karla"
                key={errors?.loan_deduction_amount}
              >
                {errors?.loan_deduction_amount}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="pf_complaint">
            <div className="flex gap-1 justify-start items-center">
              <Checkbox
                name="pf_complaint"
                style={{ color: "rgb(208 163 76 / var(--tw-bg-opacity))" }}
                onChange={handleCheckBoxChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={empDetails?.pf_complaint === 1 ? true : false}
              />
              <label className="label-name">PF Complaint</label>
            </div>
          </div>

          <div className="field-block" name="is_deducting_pf">
            <div className="flex gap-1 justify-start items-center">
              <Checkbox
                name="is_deducting_pf"
                style={{ color: "rgb(208 163 76 / var(--tw-bg-opacity))" }}
                onChange={handleCheckBoxChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={empDetails?.is_deducting_pf === 1 ? true : false}
              />
              <label className="label-name">Is deducting PF</label>
            </div>
          </div>

          <div className="field-block" name="pmrpy_scheme">
            <div className="flex gap-1 justify-start items-center">
              <Checkbox
                name="pmrpy_scheme"
                style={{ color: "rgb(208 163 76 / var(--tw-bg-opacity))" }}
                onChange={handleCheckBoxChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={empDetails?.pmrpy_scheme === 1 ? true : false}
              />
              <label className="label-name">PRPY Scheme</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeeBankInfo;
