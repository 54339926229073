import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";

function CreateDepartment() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, seterrors] = useState({});
  const [departmentDetails, setdepartmentDetails] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdepartmentDetails(() => ({ ...departmentDetails, [name]: value }));
  };

  const create_Department = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateDepartment(departmentDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    POST_API("/addDepartmentList", departmentDetails)
      .then(async function (data) {
        if (data.data.status === 403) {
          alertify.error(data?.data?.message);
          return;
        }
        alertify.success(data?.data?.message);
        setButtonDisabled(false);
        navigate("/master/hrms/department");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setdepartmentDetails({
      name: "",
      description: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/department" className="in-active">
              Department List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Department</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Department</h4>
          <form onSubmit={(e) => create_Department(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Department name"
                  value={departmentDetails.name}
                />

                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              {/* <div className="field-block">
                <label className="label-name">
                  Slug
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="slug"
                  placeholder="Enter Slug Name"
                  value={departmentDetails?.slug}
                />

                {errors.slug ? (
                  <div className="validation-error-label">{errors.slug}</div>
                ) : null}
              </div> */}

              <div className="field-block">
                <label className="label-name">Description</label>

                <textarea
                  className="input-field "
                  type="text"
                  rows={5}
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  placeholder="Enter the Description"
                  value={departmentDetails?.description}
                />

                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>

              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDepartment;
