import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Spinner from "../../../Spinner";
import alertify from "alertifyjs";
import { GET_API } from "../../../../Services/api";

function WarehouseDetails() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [wareHouseDetails, setWarehouseDetails] = useState([]);
  const id = state?.list;
  const Details = [
    {
      label: "Warehouse Name",
      value: wareHouseDetails?.name,
    },

    {
      label: "Department",
      value: wareHouseDetails?.departmentName,
    },
    {
      label: "Contact Person",
      value: wareHouseDetails?.contact_person_name,
    },
    {
      label: "Landline Number",
      value: wareHouseDetails?.landline_no,
    },

    {
      label: "Contact Number",
      value: wareHouseDetails?.contact_person_mobile,
    },
    {
      label: "Email",
      value: wareHouseDetails?.contact_person_email,
    },
    {
      label: "Address",
      value: wareHouseDetails?.address,
    },
    {
      label: "Description",
      value: wareHouseDetails?.description,
    },
  ];

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getWarehouseDetails = async () => {
    setLoading(true);
    try {
      const warehouseData = await GET_API(`po/getWarehouse/${id}`);
      if (warehouseData?.data?.status === 200) {
        const listData = warehouseData?.data?.data;
        setWarehouseDetails({
          id: listData?.id,
          name: listData?.name,
          departmentName: listData?.departmentName,
          description: listData?.description,
          landline_no: listData?.landline_no,
          address: listData?.address,
          contact_person_name: listData?.contact_person_name,
          contact_person_mobile: listData?.contact_person_mobile,
          contact_person_email: listData?.contact_person_email,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get warehouse details");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/master/po/warehouse" className="in-active">
                Warehouse List
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Add Warehouse</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Warehouse Details</h4>
            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarehouseDetails;
