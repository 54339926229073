import React, { useState, useEffect, useRef } from "react";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import alertify from "alertifyjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Select from "react-select";
import Spinner from "../../Spinner";
import Joi from "joi";
import { useParams } from "react-router-dom";

function EmployeePositionInfo({
  stateChanger,
  empDetails,
  baseurl,
  errors,
  setErrors,
  page,
}) {
  const { id } = useParams();
  const [departmentList, setdepartmentList] = useState([]);
  const [designationList, setdesignationList] = useState([]);
  const [employeeTypeList, setemployeeTypeList] = useState([]);
  const [emoployeeList, setemployeeList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [shiftList, setshiftList] = useState([]);
  const [floorList, setfloorList] = useState([]);
  const [biometricOption, setBiometricOption] = useState([]);
  const [biometricsSelectedDevice, setBiometricsSelectedDevice] = useState([]);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading8, setLoading8] = useState(false); // Biometric loading state

  const datePickerRef = useRef(null);
  // const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };
  const handleSelectChange = (e, name) => {
    if (name === "biometric_device") {
      setBiometricsSelectedDevice(e);
      let deviceID = [...e]?.map((emp, i) => {
        return emp.value;
      });
      stateChanger(() => ({ ...empDetails, biometric_device: deviceID }));
      return;
    } else {
      stateChanger(() => ({ ...empDetails, [name]: e?.value }));
    }
  };
  const handleDatePicker = (e) => {
    const created_date = dayjs(e).format("YYYY-MM-DD");
    stateChanger(() => ({ ...empDetails, date_of_joining: created_date }));
  };

  const handleDesignation = async (e, name) => {
    if (page === "edit") {
      stateChanger(() => ({ ...empDetails, [name]: e?.value }));
      return;
    }
    try {
      stateChanger(() => ({ ...empDetails, [name]: e?.value, emp_code: "" }));
      if (e?.value === "") return;
      const response = await GET_API(`/getDesignation/${e?.value}`);
      if (response?.status === 200) {
        if (response?.data?.message) {
          stateChanger(() => ({
            ...empDetails,
            emp_code: response?.data?.message,
            designation_id: e?.value,
          }));
        }
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getEmployeeTypeList();
    getDepartmentList();
    getDesignationList();
    getBranchList();
    getFloorList();
    getShiftList();
    getEmployeeList();
    getBiometricList();
  }, []);

  useEffect(() => {
    if (id && empDetails?.biometric_device?.length) {
      const res =
        biometricOption?.filter((item) => {
          return empDetails?.biometric_device.includes(item?.value);
        }) || [];
      setBiometricsSelectedDevice(res);
    }
  }, [id, empDetails?.biometric_device, biometricOption]);

  const getBranchList = async () => {
    setLoading4(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList?.data?.status === 200) {
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setbranchList(branch);
        setLoading4(false);
      }
    } catch (error) {
      alertify?.error("Failed to get branch list...");
      setLoading4(false);
    }
  };

  const getShiftList = async () => {
    try {
      setLoading6(true);
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList?.status === 200) {
        const shift = shiftList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        shift.unshift({ value: "", label: "Select..." });
        setshiftList(shift);
        setLoading6(false);
      }
    } catch (error) {
      setLoading6(false);
      alertify?.error("Failed to get Shift List...");
    }
  };

  const getFloorList = async () => {
    try {
      setLoading5(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList?.status === 200) {
        setLoading5(false);
        const floor = floorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setfloorList(floor);
      }
    } catch (error) {
      setLoading5(false);
      alertify.error("Failed to get Floor List...");
    }
  };

  const getEmployeeList = async () => {
    try {
      setLoading7(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        setLoading7(false);
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setemployeeList(empList);
      }
    } catch (error) {
      setLoading7(false);
      alertify.error("Failed to get Employee List...");
    }
  };

  const getEmployeeTypeList = async () => {
    setLoading1(true);
    try {
      const employeeTypeList = await GET_API("/employeeTypeDropdown");
      if (employeeTypeList?.data?.status === 200) {
        const empType = employeeTypeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        empType.unshift({ value: "", label: "Select..." });
        setemployeeTypeList(empType);
        setLoading1(false);
      }
    } catch (error) {
      alertify?.error("Failed to get employee type list...");
      setLoading1(false);
    }
  };

  const getDepartmentList = async () => {
    setLoading2(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList?.data?.status === 200) {
        const depList = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        depList.unshift({ value: "", label: "Select..." });
        setdepartmentList(depList);
        setLoading2(false);
      }
    } catch (error) {
      alertify?.error("Failed to Department list...");
      setLoading2(false);
    }
  };

  const getDesignationList = async () => {
    setLoading3(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList?.data?.status === 200) {
        const list = designationList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        list.unshift({ value: "", label: "Select..." });
        setdesignationList(list);
        setLoading3(false);
      }
    } catch (error) {
      alertify?.error("Failed to Designation list...");
      setLoading3(false);
    }
  };

  const getBiometricList = async () => {
    setLoading8(true);
    try {
      const bioList = await GET_API("/biometricsDropdown");
      if (bioList?.data?.status === 200) {
        const list = bioList?.data?.data?.map((bio, i) => {
          return { value: bio?.id, label: bio?.name };
        });
        list.unshift({ value: "", label: "Select..." });
        setBiometricOption(list);
        setLoading8(false);
      }
    } catch (error) {
      alertify?.error("Failed to Biometric list...");
      setBiometricOption([]);
      setLoading8(false);
    }
  };

  const handleImageChange = (e) => {
    const fileReader = new FileReader();
    const { error } = schema.validate({
      size: e?.target?.files[0].size,
      type: e?.target?.files[0].type,
    });
    error?.message
      ? setErrors({ profile_image: error?.message })
      : setErrors({});
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        stateChanger(() => ({
          ...empDetails,
          profile_image: fileReader.result,
          profile_image_format: {
            size: e?.target?.files[0].size,
            type: e?.target?.files[0].type,
          },
        }));
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  const schema = Joi.object({
    size: Joi.number()
      .required()
      .max(5 * 1024 * 1024)
      .message("Image size must be less than 5MB."),

    type: Joi.string().valid("image/jpeg", "image/png").required().messages({
      "any.only": "Invalid image format. Only JPEG and PNG are allowed.",
    }),
  });

  if (
    loading1 ||
    loading2 ||
    loading3 ||
    loading4 ||
    loading5 ||
    loading6 ||
    loading7 ||
    loading8
  ) {
    return <Spinner />;
  }

  return (
    <>
      <div
        className="p-3 w-3/12 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Position Info
        </span>
      </div>
      <form>
        <div className="form">
          <div className="field-block">
            <label className="label-name">
              Profile Picture <span className="required-symbol">*</span>
              <div className="">
                <div className="flex gap-2 items-center">
                  {!baseurl && !empDetails.profile_image ? (
                    <PersonOutlineIcon
                      className="employee-profile-picture"
                      name="profile_image"
                    />
                  ) : (
                    <img
                      name="profile_image"
                      className="h-[35px] w-[35px]"
                      src={empDetails.profile_image}
                      alt=""
                    />
                  )}
                  <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                    <label for="files" className="label-name cursor-pointer">
                      {empDetails.profile_image === "" ? "Choose" : "Replace"}
                    </label>
                    <input
                      className="w-0 cursor-pointer"
                      id="files"
                      style={{ visibility: "hidden" }}
                      type="file"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => handleImageChange(e)}
                    />
                    <img src={FileUpload} alt="" />
                  </div>
                </div>
                {errors?.profile_image ? (
                  <div
                    className="validation-error-label"
                    key={errors?.profile_image}
                  >
                    {errors?.profile_image}
                  </div>
                ) : null}
              </div>
            </label>
          </div>
          <div className="field-block">
            <label className="label-name">
              Employee Name
              <span className="required-symbol">*</span>
            </label>
            <input
              className="input-field "
              type="text"
              onChange={(e) => handleChange(e)}
              name="first_name"
              placeholder="Enter Employee name"
              autoComplete="off"
              value={empDetails?.first_name}
            />
            {errors?.first_name ? (
              <div className="validation-error-label">{errors?.first_name}</div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Employee Type
              <span className="required-symbol">*</span>
            </label>
            <div name="employee_type_id">
              <Select
                options={employeeTypeList}
                value={
                  empDetails?.employee_type_id
                    ? employeeTypeList?.find(
                        (option) =>
                          option?.value === empDetails?.employee_type_id
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "employee_type_id")}
                name="employee_type_id"
                className=""
                isSearchable
              />
            </div>
            {errors?.employee_type_id ? (
              <div
                className="validation-error-label"
                key={errors?.employee_type_id}
              >
                {errors?.employee_type_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Department
              <span className="required-symbol">*</span>
            </label>
            <div name="department_id">
              <Select
                options={departmentList}
                value={
                  empDetails.department_id
                    ? departmentList?.find(
                        (option) => option?.value === empDetails.department_id
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "department_id")}
                name="department_id"
                className=""
                isSearchable
              />
            </div>
            {errors?.department_id ? (
              <div
                className="validation-error-label"
                key={errors?.department_id}
              >
                {errors?.department_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Designation
              <span className="required-symbol">*</span>
            </label>
            <div name="designation_id">
              <Select
                options={designationList}
                value={
                  empDetails.designation_id
                    ? designationList?.find(
                        (option) => option?.value === empDetails.designation_id
                      )
                    : ""
                }
                onChange={(e) => handleDesignation(e, "designation_id")}
                name="designation_id"
                className=""
                isSearchable
              />
            </div>
            {errors?.designation_id ? (
              <div
                className="validation-error-label"
                key={errors?.designation_id}
              >
                {errors?.designation_id}
              </div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Employee Code
              <span className="required-symbol">*</span>
            </label>
            <input
              className="input-field "
              type="text"
              onChange={(e) => handleChange(e)}
              name="emp_code"
              placeholder="Enter Employee name"
              autoComplete="off"
              value={empDetails?.emp_code}
              disabled
              readonly
            />
            {errors?.emp_code ? (
              <div className="validation-error-label" key={errors?.emp_code}>
                {errors?.emp_code}
              </div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Branch
              <span className="required-symbol">*</span>
            </label>
            <div name="branch_id">
              <Select
                options={branchList}
                value={
                  empDetails.branch_id
                    ? branchList?.find(
                        (option) => option?.value === empDetails.branch_id
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "branch_id")}
                name="branch_id"
                className=""
                isSearchable
              />
            </div>
            {errors?.branch_id ? (
              <div className="validation-error-label" key={errors?.branch_id}>
                {errors?.branch_id}
              </div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Floor
              <span className="required-symbol">*</span>
            </label>
            <div name="floor">
              <Select
                options={floorList}
                value={
                  empDetails?.floor
                    ? floorList?.find(
                        (option) => option?.value === empDetails?.floor
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "floor")}
                name="floor"
                className=""
                isSearchable
              />
            </div>
            {errors?.floor ? (
              <div className="validation-error-label" key={errors?.floor}>
                {errors?.floor}
              </div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Shift
              <span className="required-symbol">*</span>
            </label>
            <div name="shift_id">
              <Select
                options={shiftList}
                value={
                  empDetails?.shift_id
                    ? shiftList?.find(
                        (option) => option?.value === empDetails?.shift_id
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "shift_id")}
                name="shift_id"
                className=""
                isSearchable
              />
            </div>
            {errors?.shift_id ? (
              <div className="validation-error-label" key={errors?.shift_id}>
                {errors?.shift_id}
              </div>
            ) : null}
          </div>

          <div className="field-block">
            <label className="label-name">
              Reporting <span className="required-symbol">*</span>
            </label>
            <div name="reporting_to">
              <Select
                options={emoployeeList}
                value={
                  empDetails?.reporting_to
                    ? emoployeeList?.find(
                        (option) => option?.value === empDetails?.reporting_to
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "reporting_to")}
                name="reporting_to"
                className=""
                isSearchable
              />
            </div>
            {errors?.reporting_to ? (
              <div
                className="validation-error-label"
                key={errors?.reporting_to}
              >
                {errors?.reporting_to}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Biometric</label>
            <div name="biometric_device">
              <Select
                options={biometricOption}
                value={biometricsSelectedDevice ? biometricsSelectedDevice : []}
                onChange={(e) => handleSelectChange(e, "biometric_device")}
                name="biometric_device"
                isSearchable
                isMulti
              />
            </div>
            {errors?.biometric_device ? (
              <div
                className="validation-error-label"
                key={errors?.biometric_device}
              >
                {errors?.biometric_device}
              </div>
            ) : null}
          </div>

          <div className="field-block create-emp">
            <label className="label-name">
              Joining Date
              <span className="required-symbol">*</span>
            </label>
            <div className="w-full" name="date_of_joining">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  name="date_of_joining"
                  className="input-field-date-picker w-full"
                  value={dayjs(empDetails?.date_of_joining)}
                  ref={datePickerRef}
                  disableCloseOnSelect={true}
                  format="DD-MM-YYYY"
                  onChange={(e) => {
                    handleDatePicker(e);
                  }}
                />
              </LocalizationProvider>
              {errors?.date_of_joining ? (
                <div
                  className="validation-error-label"
                  key={errors?.date_of_joining}
                >
                  {errors?.date_of_joining}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EmployeePositionInfo;
