import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";

function CreateHolidayScheme() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [holidaySchemeList, setHolidaySchemeList] = useState({
    name: "",
    description: "",
    // special_notes: "",
    // no_of_days: "",
    grace_days: "",
    working_days: "",
  });
  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidaySchemeList(() => ({ ...holidaySchemeList, [name]: value }));
  };

  const createHoldaySchemeList = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateHolidaysScheme(holidaySchemeList);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/addHolidayScheme", holidaySchemeList)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/master/hrms/holidayscheme");
        setButtonDisabled(false);
      })

      .catch((error) => {
        alertify.error(error?.response?.data?.message);
        setButtonDisabled(false);
      });
  };

  const resetForm = () => {
    setHolidaySchemeList({
      name: "",
      description: "",
      // special_notes: "",
      // no_of_days: "",
      grace_days: "",
      working_days: "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/holidayscheme" className="in-active">
              Holiday Scheme List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Holiday Scheme</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Holiday Scheme</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createHoldaySchemeList(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holiday Scheme Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Holiday Scheme Name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={holidaySchemeList?.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>

              {/* <div className="field-block">
                <label className="label-name">
                  Special Notes
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  name="special_notes"
                  placeholder="Enter Special Note"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={holidaySchemeList?.special_notes}
                />
                {errors.special_notes && (
                  <span className="validation-error-label">
                    {errors.special_notes}
                  </span>
                )}
              </div> 
              <div className="field-block">
                <label className="label-name">
                  No Of Days
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter No Of Holiday Scheme"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={holidaySchemeList?.no_of_days}
                  name="no_of_days"
                />
                {errors.no_of_days && (
                  <span className="validation-error-label">
                    {errors.no_of_days}
                  </span>
                )}
              </div>*/}

              <div className="field-block">
                <label className="label-name">Grace Days</label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Grace Days"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="grace_days"
                  value={holidaySchemeList?.grace_days}
                />
                {errors.grace_days && (
                  <span className="validation-error-label">
                    {errors.grace_days}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">Calculating Working Days</label>
                <input
                  className="input-field "
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Calculating working Days"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="working_days"
                  value={holidaySchemeList?.working_days}
                />
                {errors.working_days && (
                  <span className="validation-error-label">
                    {errors.working_days}
                  </span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  value={holidaySchemeList?.description}
                  rows={5}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateHolidayScheme;
