import React, { useState, useEffect } from "react";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import EarlExitReport from "../../DataTable/EarlyExitReportTable";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import Filter from "../../../Assets/Icons/Filter.svg";
import Select from "react-select";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../ButtonLoader";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function LateFineReportList() {
  const navigate = useNavigate();

  const [filteredList, setfilteredList] = useState([]);
  const [earlyExitList, setEarlyExitList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [exportList, setExportList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [showMonth, setshowMonth] = useState("");
  const [floorList, setFloorList] = useState([]);

  const [isFloorLoading, setIsFloorLoading] = useState(false);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    employee_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    shift: "",
    agent: "",
    date: "",
  });

  const columns = [
    { id: "s.no", label: "S.No", sortable: false },
    { id: "Employee_code", label: "Employee Code", sortable: true },
    { id: "first_name", label: "Employee Name", sortable: true },
    { id: "department_name", label: "Department Name", sortable: false },
    { id: "designation_name", label: "Designation", sortable: false },
    { id: "floor_name", label: "Floor", sortable: false },
    { id: "attdDate", label: "Date", sortable: false },
    { id: "clockInTime", label: "In Time", sortable: false },
    { id: "clockOutTime", label: "Out Time", sortable: false },
    { id: "icon", label: "", sortable: false },
  ];

  const [sorting, setSorting] = useState({
    orderBy: "",
    orderByType: "asc",
  });

  const handleSort = (columnId, sortable) => {
    if (!sortable) return;
    const isAsc =
      sorting.orderBy === columnId && sorting.orderByType === "desc";
    const newOrderByType = isAsc ? "asc" : "desc";
    getEarlyExitList(currentPage, pageSize, filterParams, {
      orderBy: columnId,
      orderByType: newOrderByType,
    });
    setSorting({
      orderBy: columnId,
      orderByType: newOrderByType,
    });
  };

  const getexportData = (data) => {
    const attributesToRemove = ["empId", "EmployeeshiftId"];
    const updatedList = data.map((obj) => {
      const updatedObj = { ...obj };
      attributesToRemove.forEach((attr) => delete updatedObj[attr]);
      return updatedObj;
    });
    setExportList(updatedList);
  };

  useEffect(() => {
    // getEarlyExitList();
    getBranchList();
    getEmployeeType();
    getEmployeeList();
    getDepartmentList();
    getDesignationList();
    getShiftList();
    getHRAgentList();
    getCurrentMonthAndYear();
    getFloorList();
  }, []);

  useEffect(() => {
    getEarlyExitList(currentPage, pageSize, filterParams);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getEarlyExitList(1, pageSize, filterParams);
  };

  const getFloorList = async () => {
    try {
      setIsFloorLoading(true);
      const floorList = await GET_API("/floorDropdown");
      if (floorList?.status === 200) {
        setIsFloorLoading(false);
        const floor = floorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
      }
    } catch (error) {
      setIsFloorLoading(false);
      alertify.error("Failed to get Floor List...");
    }
  };
  const getEarlyExitList = async (page, size, filters, sort) => {
    const sorting =
      sort?.orderBy && sort?.orderByType
        ? `&orderBy=${sort?.orderBy}&orderByType=${sort?.orderByType}`
        : "";
    try {
      setLoading(true);
      const list = await GET_API(
        `/employeeEarlyExitList?page=${page}&size=${size}${filters}${sorting}`
      );
      if (list.status === 200) {
        if (list?.data?.data?.current_page > list?.data?.data?.last_page) {
          isPageNotExit();
          return;
        }

        setfilteredList(list?.data?.data?.data);
        setEarlyExitList(list?.data?.data?.data);
        setLastPage(list?.data?.data?.last_page);
        setLoading(false);
        getexportData(list?.data?.data?.data);
      }
    } catch (error) {
      alertify.error("Failed to get Late Fine Report!");
      setLoading(false);
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getShiftList = async () => {
    setLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.contact_person };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setLoading(false);
    }
  };

  const getExportasPDF = async () => {
    try {
      setDownloadPDF(true);
      const downloadForm = await GET_API(
        `earlyexitReportPdf?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "EmployeeForm.pdf";
        alink.click();
        setDownloadPDF(false);
      }
    } catch (error) {
      setDownloadPDF(false);
    }
  };

  const getExportAsExcel = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-earlyexitReport?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Attendance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = earlyExitList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilteredList(searchedList);
    getexportData(searchedList);
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const handleFilter = async (e, name, type) => {
    const value =
      name === "dateMonth"
        ? new Date(e)
        : name === "date"
        ? e?.target?.value
        : e?.value;
    if (name === "dateMonth") {
      filterList["year"] = value?.getFullYear();
      filterList["month"] = value?.getMonth() + 1;
      getCurrentMonthAndYear();
    } else {
      filterList[name] = value;
    }
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/report/earlyExit/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const getCurrentMonthAndYear = () => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const currentMonth = months?.filter((month, i) => {
      return i + 1 === parseInt(filterList?.month);
    });
    setshowMonth(currentMonth?.toString());
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="grow bg-[#F7F6F4]  w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Early Exit Report</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
              <img src={Search} alt="" />
            </div>

            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportAsExcel()}
                  >
                    <span>Export</span>
                    <img
                      style={{ height: "25px" }}
                      src={ExcelExport}
                      alt="export"
                    />
                  </button>
                )}
              </div>
              <div className="add-new-button-block">
                {downloadPDF ? (
                  <ButtonLoader isBtnDisable={downloadPDF} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      style={{ height: "25px" }}
                      alt="export"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Shift</label>
                  <Select
                    options={shiftList}
                    value={shiftList?.find(
                      (option) => option?.value === filterList.shift
                    )}
                    onChange={(e) => handleFilter(e, "shift", "select")}
                    name="shift"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Floor</label>
                  <Select
                    options={floorList}
                    value={floorList.find(
                      (option) => option?.value === filterList.floor
                    )}
                    onChange={(e) => handleFilter(e, "floor")}
                    name="floor"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">HR Agent</label>
                  <Select
                    options={hragentList}
                    value={hragentList?.find(
                      (option) => option?.value === filterList?.agent
                    )}
                    onChange={(e) => handleFilter(e, "agent", "select")}
                    name="agent"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Attendance Month</label>
                  <div className="flex gap-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={'"Month" and "Year"'}
                        views={["month", "year"]}
                        name="dateMonth"
                        slotProps={{
                          field: { clearable: true, onClear: () => false },
                        }}
                        onChange={(e) => {
                          handleFilter(e, "dateMonth", "date");
                        }}
                        value={dayjs(showMonth + " " + filterList?.year)}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="field-block">
                  <label className="label-name">Attendance Date</label>
                  <div className="flex gap-1">
                    <input
                      className="input-field w-full"
                      type="date"
                      autoComplete="off"
                      name="date"
                      value={filterList?.date}
                      onChange={(e) => handleFilter(e, "date", "date")}
                    />
                  </div>
                </div>
                <div className="add-new-button-block w-[40%] h-max">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      showFilterResult(e);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>Show Result</span>
                  </button>
                </div>
              </div>
            </form>
          )}

          <EarlExitReport
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            sorting={sorting}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}

export default LateFineReportList;
