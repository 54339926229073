import React, { useState, useEffect } from "react";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import { Checkbox } from "@mui/material";

function EmployeeSalaryInfo({ stateChanger, empDetails, errors }) {
  const [employeeList, setemployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHolidayLoading, setIsHolidayLoading] = useState(false);
  const [isHolidayVariantLoading, setIsHolidayVariantLoading] = useState(false);
  const [holidayList, setholidayList] = useState([]);
  const [holidayVariantList, setHolidayVariantList] = useState([]);

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const empList = await GET_API("/employeeDropdown");
      if (empList?.data?.status === 200) {
        const list = empList?.data?.data?.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        list.unshift({ label: "Select...", value: "" });
        setemployeeList(list);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error(error?.message);
    }
  };

  const getHolidayList = async () => {
    try {
      setIsHolidayLoading(true);
      const holidayList = await GET_API("/holidaySchemeDropdown");
      if (holidayList?.data?.status === 200) {
        const holiDay = holidayList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        holiDay.unshift({ value: "", label: "Select..." });
        setholidayList(holiDay);
        setIsHolidayLoading(false);
      }
    } catch (error) {
      setIsHolidayLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const getHalfVariantList = async () => {
    try {
      setIsHolidayVariantLoading(true);
      const varaintList = await GET_API("/halfDayVariantDropdown");
      if (varaintList?.data?.status === 200) {
        const list = varaintList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        list.unshift({ value: "", label: "Select..." });
        setHolidayVariantList(list);
        setIsHolidayVariantLoading(false);
      }
    } catch (error) {
      setIsHolidayVariantLoading(false);
      alertify.error("Failed to get holiday variant list");
    }
  };

  useEffect(() => {
    getEmployeeList();
    getHolidayList();
    getHalfVariantList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
    // if (name === "is_food_allowance_applicable") {
    //   const allowance_applicable = checked ? 1 : 0;
    //   stateChanger(() => ({ ...empDetails, [name]: allowance_applicable }));
    //   return;
    // }
  };

  const handleSelectChange = (e, name) => {
    stateChanger(() => ({ ...empDetails, [name]: e?.value }));
  };

  if (loading || isHolidayLoading || isHolidayVariantLoading)
    return <Spinner />;

  return (
    <div className="border-t-[1px] border-t-[#D9D9D9]">
      <div
        className="w-3/12 p-3 bg-primary-color text-center"
        style={{ borderRadius: "0px 0px 100px 0px" }}
      >
        <span className="text-white-color font-karla font-base ">
          Scheme Info
        </span>
      </div>
      <form>
        <div className="form">
          {/* <div className="field-block" name="dummy_basic">
            <label className="label-name">Basic Salary</label>
            <input
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              name="dummy_basic"
              placeholder={"Enter Basic Salary"}
              value={empDetails?.dummy_basic}
              autoComplete="off"
            />
            {errors?.dummy_basic ? (
              <div className="validation-error-label">
                {errors?.dummy_basic}
              </div>
            ) : null}
          </div> */}

          <div className="field-block" name="pf_amount">
            <label className="label-name">PF</label>
            <input
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              name="pf_amount"
              placeholder={"Enter PF Amount"}
              value={empDetails?.pf_amount}
              autoComplete="off"
            />
            {errors?.pf_amount ? (
              <div className="validation-error-label">{errors?.pf_amount}</div>
            ) : null}
          </div>

          <div className="field-block" name="account_salary">
            <label className="label-name">
              Through Bank Account(In account)
            </label>
            <input
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              name="account_salary"
              placeholder={"Enter Amount"}
              value={empDetails?.account_salary}
              autoComplete="off"
            />
            {errors?.account_salary ? (
              <div className="validation-error-label">
                {errors?.account_salary}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="in_cash">
            <label className="label-name">In cash</label>
            <input
              className="input-field"
              type="number"
              onChange={(e) => handleChange(e)}
              name="in_cash"
              placeholder="Enter amount  by hand"
              value={empDetails?.in_cash}
              autoComplete="off"
            />
            {errors?.in_cash ? (
              <div className="validation-error-label">{errors?.in_cash}</div>
            ) : null}
          </div>

          <div className="field-block dropdown-select-to-first" name="holiday_scheme_id">
            <label className="label-name">Holiday Scheme</label>
            <Select
              options={holidayList}
              value={holidayList?.find(
                (option) => option?.value === empDetails?.holiday_scheme_id
              )}
              onChange={(e) => handleSelectChange(e, "holiday_scheme_id")}
              name="holiday_scheme_id"
              className=""
              isSearchable
            />
            {errors?.holiday_scheme_id ? (
              <div
                className="validation-error-label"
                key={errors?.holiday_scheme_id}
              >
                {errors?.holiday_scheme_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="halfday_variant_id">
            <label className="label-name">Half Day Variant</label>
            <Select
              options={holidayVariantList}
              value={holidayVariantList?.find(
                (option) => option?.value === empDetails?.halfday_variant_id
              )}
              onChange={(e) => handleSelectChange(e, "halfday_variant_id")}
              name="halfday_variant_id"
              className=""
              isSearchable
            />
            {errors?.halfday_variant_id ? (
              <div
                className="validation-error-label"
                key={errors?.halfday_variant_id}
              >
                {errors?.halfday_variant_id}
              </div>
            ) : null}
          </div>

          <div className="field-block" name="approved_by">
            <label className="label-name">Approval</label>
            <Select
              options={employeeList}
              value={employeeList?.find(
                (option) => option?.value === empDetails.approved_by
              )}
              onChange={(e) => handleSelectChange(e, "approved_by")}
              name="approved_by"
              className=""
              isSearchable
            />
            {errors?.approved_by ? (
              <div className="validation-error-label">
                {errors?.approved_by}
              </div>
            ) : null}
          </div>

          {/* <div className="field-block" name={"is_food_allowance_applicable"}>
            <label className="label-name">Allowance applicable</label>
            <Checkbox
              name={"is_food_allowance_applicable"}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              checked={
                empDetails.is_food_allowance_applicable === 1 ? true : false
              }
            />
          </div> */}
        </div>
      </form>
    </div>
  );
}

export default EmployeeSalaryInfo;
