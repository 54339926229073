import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import { POST_API, GET_API, DELETE_API } from "../../Services/api";

import EmployeeBasicInfoComp from "./Tabs/BasicInfo";
import EmployeeBankInfoComp from "./Tabs/Bankinfo";
import EmployeeSalaryInfoComp from "./Tabs/SalaryInfo";
import EmployeePositionInfoComp from "./Tabs/Positioninfo";
import EmployeePersonalInfoComp from "./Tabs/PersonaInfo";
import EmployeeEducationInfoComp from "./Tabs/EducationInfo";
import EmployeeFoodAllowanceComp from "./Tabs/FoodAllowance";
import EmployeeAllowanceComp from "./Tabs/Allowance";
import Spinner from "../Spinner";
import config from "../../Services/config.json";
import Save from "../../Assets/Icons/Save.svg";
import Validation from "../../Validation/Validation";
import ButtonLoader from "../ButtonLoader";
import "alertifyjs/build/css/alertify.css";

function EmployeeEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const [empDetails, setEmpDetails] = useState({});

  // Food Allowance
  const [foodAllowanceValue, setFoodAllowanceValue] = useState([]);
  const [foodAllowanceEditValue, setFoodAllowanceEditValue] = useState([]);
  const [isFoodAllowanceLoading, setIsFoodAllowanceLoading] = useState(false);
  const [foodAllowanceOption, setFoodAllowanceOption] = useState([]);

  // Allowance
  const [allowanceValue, setallowanceValue] = useState([
    {
      allowance_type_id: "",
      in_compliance: false,
      calculation_method: "",
      amount: "",
    },
  ]);
  const [empAllowanceValue, setEmpllowanceValue] = useState([]);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const [allowanceTypeList, setallowanceTypeList] = useState([]);
  const [isAllowanceTypeLoading, setIsAllowanceTypeLoading] = useState(false);

  useEffect(() => {
    getFoodList();
    getAllowanceTypeList();
  }, []);

  useEffect(() => {
    if (id) {
      getAddedFoodAllowance();
      getEmployeeAllowanceList();
    }
  }, [id]);

  const getFoodList = async () => {
    setIsLoading(true);
    try {
      const foodList = await GET_API("/FoodAllowanceAll");
      if (foodList.status === 200) {
        const list = foodList?.data?.data?.map((opt) => {
          return { label: opt?.name, value: opt?.id };
        });
        setFoodAllowanceOption(list);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to Get Allowance List");
    }
  };

  const getAllowanceTypeList = async () => {
    try {
      setIsAllowanceTypeLoading(true);
      const allowance_type_list = await GET_API("/allowanceTypeDropdown");
      if (allowance_type_list?.status === 200) {
        const allowType = allowance_type_list.data?.data?.map((option) => ({
          label: option?.name,
          value: option?.id,
        }));
        setallowanceTypeList(allowType);
        setIsAllowanceTypeLoading(false);
        return;
      }
    } catch (error) {
      setIsAllowanceTypeLoading(false);
    }
  };

  useEffect(() => {
    const getEmployeeData = async () => {
      try {
        setIsLoading(true);
        const employee = await GET_API(`/employeeGet/${id}`);
        setEmpDetails({
          ...employee?.data?.data,
          biometric_device: employee?.data?.data?.biometric_device
            ? employee?.data?.data?.biometric_device.split(",").map(Number)
            : [],
          id_proof_number: employee?.data?.data?.government_proof_value,
          profile_image: config.path + employee?.data?.data?.profile_image,
          profile_image_format: {
            image: config.path + employee?.data?.data?.profile_image,
          },
          proof_image: config.path + employee?.data?.data?.proof_image,
        });
        setIsLoading(false);
      } catch (error) {
        setEmpDetails([]);
        setIsLoading(false);
      }
    };
    getEmployeeData();
  }, [id]);

  const getAddedFoodAllowance = async () => {
    setIsFoodAllowanceLoading(true);
    try {
      const foodList = await GET_API(`/mapFoodAllowanceEmp/${id}`);
      if (foodList.status === 200) {
        setFoodAllowanceEditValue(foodList?.data?.data);
        setIsFoodAllowanceLoading(false);
      }
    } catch (error) {
      setIsFoodAllowanceLoading(false);
      setFoodAllowanceEditValue([]);
      alertify.error("Failed to Get Allowance List");
    }
  };

  // employee added allowance
  const getEmployeeAllowanceList = async () => {
    try {
      setIsAllowanceLoading(true);
      const empAllowanceList = await GET_API(`/empAllowanceDetailsList/${id}`);
      if (empAllowanceList?.data?.status === 200) {
        setEmpllowanceValue(empAllowanceList?.data?.data);
        setIsAllowanceLoading(false);
      }
    } catch (error) {
      setEmpllowanceValue([]);
      setIsAllowanceLoading(false);
      alertify.error("Failed to get Allowance Type List...");
    }
  };

  useEffect(() => {
    if (foodAllowanceOption?.length || foodAllowanceEditValue?.length) {
      const val = foodAllowanceOption?.filter((item) => {
        return foodAllowanceEditValue?.includes(item?.value);
      });
      setFoodAllowanceValue(val);
    }
  }, [foodAllowanceOption.length, foodAllowanceEditValue?.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBtnDisable(true);
    const isValid = Validation.validateEmployee(empDetails);
    if (isValid && isValid?.error) {
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setIsBtnDisable(false);
      const errorInput = document.getElementsByName(
        isValid?.error?.details[0].path[0]
      )[0];
      errorInput.scrollIntoView({ behavior: "smooth" });
      return;
    } else {
      setErrors({});
      const submitData = {
        ...empDetails,
        food_allowance: foodAllowanceValue.map((opt) => opt?.value),
        allowance_details:
          allowanceValue[0]?.allowance_type_id !== "" ? allowanceValue : [],
      };
      POST_API("/updateEmployee", submitData)
        .then(async function (data) {
          setIsBtnDisable(false);
          alertify.success(data?.data?.message);
          navigate("/employee/list");
        })

        .catch((error) => {
          setIsBtnDisable(false);
          alertify.error(error?.response?.data?.message);
        });
    }
  };

  const deleteAllowance = async (rowID) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Allowance..?",
        async function () {
          if (rowID) {
            setIsAllowanceLoading(true);
            const deleteList = await DELETE_API(
              `/empAllowanceDetailsDelete/${rowID}`
            );
            if (deleteList?.data?.status === 200) {
              alertify?.success(deleteList?.data?.message);
              getEmployeeAllowanceList();
              setIsAllowanceLoading(false);
              return;
            }
            setIsAllowanceLoading(false);
            alertify.error("Failed To Delete Allowance...");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const resetForm = () => {};

  if (isLoading || isFoodAllowanceLoading || isAllowanceTypeLoading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/employee/list" className="in-active">
              Employee List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Employee</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Edit Employee</div>
          <div>
            <EmployeePositionInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              baseurl={config.path}
              errors={errors}
              setErrors={setErrors}
              page="edit"
            />
          </div>

          <div>
            <EmployeeBasicInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeePersonalInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              baseurl={config.path}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
          <div>
            <EmployeeEducationInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              submitEmployeeDetails={(e) => handleSubmit(e)}
              isBtnDisable={isBtnDisable}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeBankInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeSalaryInfoComp
              empDetails={empDetails}
              stateChanger={setEmpDetails}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeFoodAllowanceComp
              foodAllowanceValue={foodAllowanceValue}
              setFoodAllowanceValue={setFoodAllowanceValue}
              foodAllowanceOption={foodAllowanceOption}
              errors={errors}
            />
          </div>
          <div>
            <EmployeeAllowanceComp
              allowanceTypeList={allowanceTypeList} // allowanceTypeList
              allowanceValue={allowanceValue}
              empAllowanceValue={empAllowanceValue} // employee Added allowance
              setallowanceValue={setallowanceValue}
              deleteAllowance={deleteAllowance}
              errors={errors}
            />
          </div>
          <div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={() => resetForm()}
              >
                Reset
              </button>
              {isBtnDisable ? (
                <ButtonLoader isBtnDisable={isBtnDisable} />
              ) : (
                <button
                  className="submit-button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <span className="submit-button-label">Submit</span>
                  <img src={Save} alt="" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeEdit;
