import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import LateEntryApprovalTable from "../../DataTable/LateEntryApprovalTable";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";
import Filter from "../../../Assets/Icons/Filter.svg";
import Select from "react-select";

function LateEntryApproval() {
  const [loading, setLoading] = useState(false);
  const [lateEntryList, setlateEntryList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);

  const [filter, setFilter] = useState(false);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    date: "",
    attendance_status: "",
    valid_emp: "",
  });
  const [employeeList, setEmployeeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterParams, setFilterParams] = useState("");
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);

  useEffect(() => {
    getLateEntryList(currentPage, pageSize, filterParams);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  useEffect(() => {
    getEmployeeList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const getEmployeeList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const attendanceListArr = [];
        employeeList?.data?.data.map((item) => {
          return attendanceListArr.push(item);
        });
        const empList = employeeList?.data?.data.map((item) => {
          return {
            label: `${item?.first_name} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const attendanceStatus = [
    { label: "Please Select", value: "" },
    { label: "Present", value: "present" },
    { label: "Absent", value: "absent" },
  ];

  const validEmployee = [
    { label: "Please Select", value: "" },
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_master") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } = name === "date" ? e?.target : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const columns = [
    { field: "EmployeeCode", headerName: "Employee Code", minWidth: 150 },
    { field: "employee_name", headerName: "Employee Name", minWidth: 200 },
    { field: "attdate", headerName: "Date", minWidth: 200 },
    { field: "shiftTime", headerName: "Shift Time", minWidth: 200 },
    { field: "clockInTime", headerName: "clock In Time", minWidth: 200 },
    { field: "clockOutTime", headerName: "clock Out Time", minWidth: 200 },
    { field: "working_hrs", headerName: "Working Hours", minWidth: 150 },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLateEntryList(1, pageSize, filter);
  };

  const getLateEntryList = async (page, size, filter) => {
    setLoading(true);
    try {
      const lateEntry = await GET_API(
        `/employeeLateEntryList?page=${page}&size=${size}&${filter}`
      );
      if (lateEntry?.status === 200) {
        if (
          lateEntry?.data?.data?.current_page > lateEntry?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setlateEntryList(lateEntry?.data?.data?.data);
        setLastPage(lateEntry?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleApprove = async (type) => {
    const userId = localStorage.getItem("user_id");
    const data = {
      selectedRows,
      appType: type,
      approved_by: userId,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveLeaves = await POST_API("/lateEntryApproval", data);
      if (approveLeaves?.status === 200) {
        alertify.success(`Leave ${data?.appType}d successfully...!`);
        getLateEntryList(currentPage, pageSize, filter);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.appType}d Leave`);
    }
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Late Entry Logs</h4>
          <div className="search-block" style={{ justifyContent: "flex-end" }}>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              {addAccess && (
                <>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("reject");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      // disabled={selectedRows?.length >=1 ? false:true}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                  <div
                    className="add-new-button-block"
                    onClick={() => {
                      handleApprove("approve");
                    }}
                  >
                    <button
                      to=""
                      className="add-new-button"
                      // disabled={selectedRows?.length >=1 ? false:true}
                    >
                      <span>Approve</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Date</label>
                  <input
                    type="date"
                    name="date"
                    value={filterList?.date}
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e, "date");
                    }}
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Attendance Status</label>
                  <Select
                    options={attendanceStatus}
                    value={attendanceStatus?.find(
                      (option) => option?.value === filterList.attendance_status
                    )}
                    onChange={(e) => handleFilter(e, "attendance_status")}
                    name="attendance_status"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Valid Employee</label>
                  <Select
                    options={validEmployee}
                    value={validEmployee?.find(
                      (option) => option?.value === filterList.valid_emp
                    )}
                    onChange={(e) => handleFilter(e, "valid_emp")}
                    name="valid_emp"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%]">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[400px]">
            <LateEntryApprovalTable
              columns={columns}
              list={lateEntryList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LateEntryApproval;
