import React, { useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";

function EditDepartment() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editDepartmentDetails, setEditDepartmentDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    // slug: listData?.slug,
    description: listData?.description !== null ? listData?.description : "",
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditDepartmentDetails(() => ({
      ...editDepartmentDetails,
      [name]: value,
    }));
  };

  const saveDepartment = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateDepartment(editDepartmentDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateDepartment", editDepartmentDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        setButtonDisabled(false);
        navigate("/master/hrms/department");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setEditDepartmentDetails({
      id: listData?.id,
      name: listData?.name,
      // slug: listData?.slug,
      description: listData?.description !== null ? listData?.description : "",
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/department" className="in-active">
              Department List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Edit Department</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Department</h4>
          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={editDepartmentDetails?.name}
                  placeholder="Enter Department name"
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              {/* <div className="field-block">
                <label className="label-name">
                  Slug
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="slug"
                  value={editDepartmentDetails?.slug}
                  placeholder="Enter Slug Name"
                />
                {errors.slug ? (
                  <div className="validation-error-label">{errors.slug}</div>
                ) : null}
              </div> */}

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  rows={5}
                  name="description"
                  value={editDepartmentDetails?.description}
                  placeholder="Enter the Description"
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={() => resetForm()}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={() => saveDepartment()}
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditDepartment;
