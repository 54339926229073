import React, { useEffect, useRef, useState } from "react";
import alertify from "alertifyjs";
import { GET_API, POST_API } from "../Services/api";
import { numberToWords } from "./NumbersToWords/NumbersToWords";
import Logo from "../Assets/Logo/Logo.png";
import Save from "../Assets/Icons/Save.svg";
import "alertifyjs/build/css/alertify.css";

function BarcodeDetection() {
  const inputElement = useRef();
  const [tableName, setTableName] = useState("");
  const [payslipDetails, setPayslipDetails] = useState({});
  const [agentDetails, setAgentDetails] = useState({
    date: "",
    agent_name: "",
    amount: "",
    created_by_name: "",
    checked_by: localStorage?.getItem("user_name"),
  });
  const [qrCodeValue, setQRCodeValue] = useState("");
  const [nonPayslipObj, setnonPayslipObj] = useState({
    tableName: "",
    voucherId: "",
    empCode: "",
    empName: "",
    designation: "",
    department: "",
    amount: "",
    paymentMode: "",
    date: "",
    created_by_name: "",
    paidStatus: 0,
    checked_by: localStorage?.getItem("user_name"),
  });

  const handleVoucherPayment = async (voucherId) => {
    try {
      const response = await POST_API(`/updatevoucherpayment/${voucherId}`, {
        paid_by: localStorage?.getItem("user_id"),
      });
      if (response?.data?.status === 200) {
        setTableName("");
        setQRCodeValue("");
        setPayslipDetails({});
        setAgentDetails({});
        setnonPayslipObj({
          tableName: "",
          voucherId: "",
          empCode: "",
          empName: "",
          designation: "",
          department: "",
          amount: "",
          paymentMode: "",
          date: "",
          created_by_name: "",
          paidStatus: 0,
          checked_by: localStorage?.getItem("user_name"),
        });
        alertify.success(response?.data?.message);
        return;
      } else {
        alertify.error(response?.data?.message);
        setTableName("");
        setQRCodeValue("");
        setPayslipDetails({});
        setAgentDetails({});
        setnonPayslipObj({
          tableName: "",
          voucherId: "",
          empCode: "",
          empName: "",
          designation: "",
          department: "",
          amount: "",
          paymentMode: "",
          date: "",
          paidStatus: 0,
          created_by_name: "",
          checked_by: localStorage?.getItem("user_name"),
        });
      }
    } catch (error) {
      alertify.error(error?.data?.message);
    }
  };

  const fathers_name =
    payslipDetails?.employee?.fathers_name !== null
      ? payslipDetails?.employee?.fathers_name
      : "";

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (qrCodeValue) {
        getQrCodeValue(qrCodeValue);
      }
    }, 1000);

    return () => clearTimeout(delay);
  }, [qrCodeValue]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQRCodeValue(value);
  };

  const getQrCodeValue = async (code) => {
    try {
      const voucherDetails = await GET_API(`voucherdetails/${code}`);
      const voucherData = voucherDetails?.data?.data;
      if (voucherDetails?.status === 200 && voucherData?.voucherId === code) {
        // voucherDetails?.data?.data.map((item) => {
        setTableName(voucherData?.tableName);
        if (voucherData?.tableName === "payslip") {
          //for payslip details
          // item?.voucherDetails?.map((detail) => {
          //   const parsedData = JSON.parse(detail?.data);
          //   setPayslipDetails(parsedData);
          // });
        } else if (voucherData?.tableName === "finance_details") {
          setnonPayslipObj({
            ...nonPayslipObj,
            tableName: voucherData?.tableName,
            voucherId: voucherData?.voucherId,
            empCode: voucherData?.voucherDetails?.emp_code,
            empName: voucherData?.voucherDetails?.employee_name,
            amount: voucherData?.voucherDetails?.amount,
            date: voucherData?.voucherDetails?.created_at,
            department: voucherData?.voucherDetails?.department_name,
            designation: voucherData?.voucherDetails?.designation_name,
            paymentMode: voucherData?.voucherDetails?.payment_type,
            created_by_name: voucherData?.voucherDetails?.created_by_name,
            paidStatus: voucherData?.paidStatus,
            checked_by: localStorage?.getItem("user_name"),
          });
        } else if (voucherData?.tableName === "agent_commission") {
          // setAgentDetails(voucherData?.voucherDetails[0]);
          setAgentDetails({
            date: voucherData?.voucherDetails?.date,
            agent_name: voucherData?.voucherDetails?.agent_name,
            amount: voucherData?.voucherDetails?.amount,
          });
        }
        return;
        // });
      } else {
        alertify?.error(voucherDetails?.data?.message);
      }
    } catch (error) {}
  };

  // const disableMouseEvents = () => {
  //   document.body.style.pointerEvents = 'none';
  // };

  // const enableMouseEvents = () => {
  //   document.body.style.pointerEvents = 'auto';
  // };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Detection Details</h4>
          <div className="p-5 pb-0 text-right">
            {nonPayslipObj?.paidStatus == 1 && (
              <span
                className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 bg-[red]"
                style={{ color: "#FFF" }}
              >
                Already Paid for the Voucher
              </span>
            )}
          </div>
          <div className="p-5">
            {(tableName !== "") & (tableName === "finance_details") ? (
              <>
                <div className="bank-voucher-border p-5">
                  <h2 className="advance-voucher-title text-center">
                    Loan Voucher
                  </h2>
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-between w-full">
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Voucher No</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.voucherId}
                        </span>
                      </div>
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Date</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.date}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Emp Code</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.empCode}
                        </span>
                      </div>
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Designation</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.designation}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Emp Name</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.empName}
                        </span>
                      </div>
                      <div className="text-xl font-karla flex gap-3">
                        <span className="text-text-light">Department</span>
                        <span className="text-text-dark">
                          {nonPayslipObj?.department}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="">
                        <div
                          className="bank-voucher-border advance-voucher-bold p-5"
                          style={{ fontSize: "45px" }}
                        >
                          <span>&#8377;{nonPayslipObj?.amount}</span>
                        </div>
                      </div>
                      <div className="font-karla flex gap-3 text-text-dark text-xl">
                        <span className="text-text-light">Payment Mode</span>
                        <span>{nonPayslipObj?.paymentMode}</span>
                      </div>
                    </div>

                    <div className="text-xl font-karla flex gap-3 text-text-dark">
                      <div className="text-text-light">Amount in words:</div>
                      <div>
                        <span>
                          &#8377;
                          {numberToWords(parseFloat(nonPayslipObj?.amount)) +
                            "only"}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-around text-text-dark font-karla p-3">
                      <div>
                        <div className="text-xl text-text-light">Admit By</div>
                        <div className="text-sm">
                          {nonPayslipObj?.created_by_name}
                        </div>
                      </div>

                      <div>
                        <div className="text-xl text-text-light">
                          Checked By
                        </div>
                        <div className="text-sm">
                          {nonPayslipObj?.created_by_name}
                        </div>
                      </div>

                      <div>
                        <div className="text-xl text-text-light">
                          Employee Signature
                        </div>
                        <div className="text-sm"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  {nonPayslipObj?.paidStatus == 0 && (
                    <button
                      onClick={() => handleVoucherPayment(qrCodeValue)}
                      type="button"
                      className="submit-button"
                      disabled={false}
                    >
                      <span className="submit-button-label">Save</span>
                      <img src={Save} alt="" />
                    </button>
                  )}
                </div>
              </>
            ) : (tableName !== "") & (tableName === "payslip") ? (
              <>
                <div className="card-wrapper">
                  <div>
                    <h4 className="payslip-header">
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-text-dark text-base font-karla">
                            SUMANGALI SILKS
                          </span>
                          <br></br>
                          <span className="text-text-dark text-base font-karla">
                            PaySlip for
                          </span>{" "}
                          <b className="text-text-dark text-base font-karla">
                            {payslipDetails?.displayMonth}
                          </b>
                        </div>
                        <div className="flex gap-3 w-[21%]">
                          <img
                            src={Logo}
                            className="h-[60px] w-[60px]"
                            alt=""
                          />
                        </div>
                      </div>
                    </h4>
                    <div className="p-1 pl-3 pr-3">
                      <div className="flex justify-between">
                        <div>
                          <div className="flex">
                            <label className="list-label">
                              Employee Code:{" "}
                            </label>
                            <div
                              style={{ wordBreak: "break-word" }}
                              className="advance-section-list-value"
                            >
                              {payslipDetails?.employee?.emp_code}
                            </div>
                          </div>
                          <div className="flex">
                            <label className="list-label">
                              Employee Name:{" "}
                            </label>
                            <div
                              style={{ wordBreak: "break-word" }}
                              className="advance-section-list-value"
                            >
                              {payslipDetails?.employee?.first_name}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex">
                            <label className="list-label">Designation: </label>
                            <div
                              style={{ wordBreak: "break-word" }}
                              className="advance-section-list-value"
                            >
                              {payslipDetails?.employee?.designation_name}
                            </div>
                          </div>
                          <div className="flex">
                            <label className="list-label">Date: </label>
                            <div
                              style={{ wordBreak: "break-word" }}
                              className="advance-section-list-value"
                            >
                              {payslipDetails?.date}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 pt-1">
                      <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                        <tbody>
                          <tr>
                            <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <td className="text-text-dark text-base font-karla">
                                      Holiday Scheme
                                    </td>
                                    <td className="text-right">
                                      <b>
                                        {
                                          payslipDetails?.employee
                                            ?.holiday_scheme_name
                                        }
                                      </b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-text-dark text-base font-karla">
                                      Present Days
                                    </td>
                                    <td className="text-right">
                                      <b>
                                        {
                                          payslipDetails?.attendance
                                            ?.presentDays
                                        }{" "}
                                        days
                                      </b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-text-dark text-base font-karla">
                                      Absent Days
                                    </td>
                                    <td className="text-right">
                                      <b>
                                        {payslipDetails?.attendance?.absentDays}{" "}
                                        days
                                      </b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-text-dark text-base font-karla">
                                      Total Absent Days
                                    </td>
                                    <td className="text-right">
                                      <b>
                                        {
                                          payslipDetails?.attendance
                                            ?.totalAbsentDays
                                        }{" "}
                                        days
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <th colSpan="4" className="text-left">
                                      <b> Earnings</b>
                                    </th>
                                  </tr>
                                  {payslipDetails?.earnings &&
                                    payslipDetails?.earnings.map((list, i) => {
                                      return (
                                        <tr key={i}>
                                          <td className="text-text-dark text-base font-karla">
                                            {list?.name}
                                          </td>
                                          <td className="text-text-dark text-base font-karla">
                                            {list?.type}
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            {list?.totalAmount}
                                          </td>
                                          <td className="text-right">
                                            <b>{list?.actualAmount}</b>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </td>
                            <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <th colSpan="2" className="text-left">
                                      <b>Deductions</b>
                                    </th>
                                  </tr>
                                  {payslipDetails?.deductions &&
                                    payslipDetails?.deductions.map(
                                      (list, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-text-dark text-base font-karla">
                                              {list?.name}
                                            </td>
                                            <td className="text-right text-text-dark text-base font-karla">
                                              <b>{list?.amount}</b>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  {payslipDetails?.otherDeductions &&
                                    payslipDetails?.otherDeductions.map(
                                      (list, i) => {
                                        return (
                                          <tr key={{ i }}>
                                            <td className="text-text-dark text-base font-karla">
                                              {list?.name}
                                            </td>
                                            <td className="text-right text-text-dark text-base font-karla">
                                              <b>{list?.amount}</b>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <td>
                                      <b className="text-text-dark text-base font-karla">
                                        Total Days
                                      </b>
                                    </td>
                                    <td className="text-right">
                                      {payslipDetails?.attendance?.totalDays}{" "}
                                      days
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <td className="w-[65%]">
                                      <b className="text-text-dark text-base font-karla">
                                        Total
                                      </b>
                                    </td>
                                    <td className="text-right text-text-dark text-base font-karla">
                                      (-
                                      {payslipDetails?.totalEarnings?.deduction}
                                      )
                                    </td>
                                    <td className="text-right text-text-dark text-base font-karla">
                                      {payslipDetails?.totalEarnings?.total}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                              <table className="w-[100%]">
                                <tbody>
                                  <tr>
                                    <td>
                                      <b className="text-text-dark text-base font-karla">
                                        Total
                                      </b>
                                    </td>
                                    <td className="text-right text-text-dark text-base font-karla">
                                      {payslipDetails?.totalDeductions?.total}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="p-3 flex">
                      <p className="text-text-dark text-base font-karla">
                        Salary to be paid in cash :
                      </p>
                      <p className="text-text-dark text-base font-karla">
                        {/* {`${payslipDetails?.salaryToBePaid} (${numberToWords(
                      payslipDetails?.salaryToBePaid
                    )})`} */}
                      </p>
                    </div>
                    <br />
                    <div className="p-3 pt-4">
                      <div className="flex justify-between items-center">
                        <div>
                          <b className="text-text-dark text-base font-karla">
                            Admit by
                          </b>
                        </div>
                        <div>
                          <b className="text-text-dark text-base font-karla">
                            Checked by
                          </b>
                        </div>
                        <div>
                          <b className="text-text-dark text-base font-karla">
                            Employee Signature
                          </b>
                        </div>
                      </div>
                    </div>
                    {/* PF Salary details */}
                    {payslipDetails?.pfEarnings &&
                      payslipDetails?.pfEarnings.length > 0 && (
                        <>
                          <div id="scissors">
                            <div></div>
                          </div>
                          <h4 className="payslip-pf-header">
                            <div className="flex justify-between items-center text-text-dark text-xl font-karla">
                              PF Salary Voucher
                            </div>
                          </h4>

                          <div className="p-1 pl-3 pr-3">
                            <div className="flex justify-between">
                              <div>
                                <div className="flex">
                                  <label className="list-label">
                                    Employee Code:
                                  </label>
                                  <div
                                    style={{ wordBreak: "break-word" }}
                                    className="advance-section-list-value"
                                  >
                                    {payslipDetails?.employee?.emp_code}
                                  </div>
                                </div>
                                <div className="flex">
                                  <label className="list-label">
                                    Employee Name:
                                  </label>
                                  <div
                                    style={{ wordBreak: "break-word" }}
                                    className="advance-section-list-value"
                                  >
                                    {payslipDetails?.employee?.first_name}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex">
                                  <label className="list-label">
                                    Designation:
                                  </label>
                                  <div
                                    style={{ wordBreak: "break-word" }}
                                    className="advance-section-list-value"
                                  >
                                    {payslipDetails?.employee?.designation_name}
                                  </div>
                                </div>
                                <div className="flex">
                                  <label className="list-label">Date: </label>
                                  <div
                                    style={{ wordBreak: "break-word" }}
                                    className="advance-section-list-value"
                                  >
                                    {payslipDetails?.date}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-3 pt-1">
                            <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                              <tbody>
                                <tr>
                                  <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <td className="text-text-dark text-base font-karla">
                                            Holiday Scheme
                                          </td>
                                          <td className="text-right">
                                            <b className="text-text-dark text-base font-karla">
                                              {
                                                payslipDetails?.employee
                                                  ?.holiday_scheme_name
                                              }
                                            </b>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-text-dark text-base font-karla">
                                            Present Days
                                          </td>
                                          <td className="text-right">
                                            <b className="text-text-dark text-base font-karla">
                                              {
                                                payslipDetails?.pfAttendance
                                                  ?.presentDays
                                              }
                                            </b>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-text-dark text-base font-karla">
                                            Paid Leave Days
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            <b>
                                              {
                                                payslipDetails?.pfAttendance
                                                  ?.paidDays
                                              }
                                            </b>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-text-dark text-base font-karla">
                                            Net Present Days
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            <b>
                                              {
                                                payslipDetails?.pfAttendance
                                                  ?.netPresentDays
                                              }
                                            </b>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <th colSpan="4" className="text-left">
                                            <b className="text-text-dark text-base font-karla">
                                              Earnings
                                            </b>
                                          </th>
                                        </tr>
                                        {payslipDetails?.pfEarnings &&
                                          payslipDetails?.pfEarnings.map(
                                            (list, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td className="text-text-dark text-base font-karla">
                                                    {list?.name}
                                                  </td>
                                                  <td className="text-text-dark text-base font-karla">
                                                    {list?.type}
                                                  </td>
                                                  <td className="text-right text-text-dark text-base font-karla">
                                                    {list?.totalAmount}
                                                  </td>
                                                  <td className="text-right text-text-dark text-base font-karla">
                                                    {list?.actualAmount}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <th colSpan="2" className="text-left">
                                            <b className="text-text-dark text-base font-karla">
                                              Deductions
                                            </b>
                                          </th>
                                        </tr>
                                        {payslipDetails?.pfDeductions &&
                                          payslipDetails?.pfDeductions.map(
                                            (list, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td className="text-text-dark text-base font-karla">
                                                    {list?.name}
                                                  </td>
                                                  <td className="text-right text-text-dark text-base font-karla">
                                                    <b>{list?.amount}</b>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b className="text-text-dark text-base font-karla">
                                              Total Days
                                            </b>
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            {
                                              payslipDetails?.attendance
                                                ?.totalDays
                                            }
                                            days
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <td className="w-[65%]">
                                            <b className="text-text-dark text-base font-karla">
                                              Total
                                            </b>
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            {payslipDetails?.pfEarnings &&
                                              payslipDetails?.pfEarnings[0] &&
                                              payslipDetails?.pfEarnings[0]
                                                .actualAmount}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                    <table className="w-[100%]">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b className="text-text-dark text-base font-karla">
                                              Total
                                            </b>
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            {
                                              payslipDetails?.pfTotalDeductions
                                                ?.total
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="p-3 flex text-text-dark text-base font-karla">
                            <p>Salary to be credited in Bank : </p>
                            <p className="text-text-dark text-base font-karla">
                              {`${
                                payslipDetails?.pfSalaryInBank
                              } (${numberToWords(
                                payslipDetails?.pfSalaryInBank
                              )})`}
                            </p>
                          </div>
                          <br />

                          <div className="p-3 pt-4">
                            <div className="flex justify-between items-center">
                              <div>
                                <b className="text-text-dark text-base font-karla">
                                  Admit by
                                </b>
                              </div>
                              <div>
                                <b className="text-text-dark text-base font-karla">
                                  Checked by
                                </b>
                              </div>
                              <div>
                                <b className="text-text-dark text-base font-karla">
                                  Employee Signature
                                </b>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </div>

                  <hr className=" border-[#d9d9d9] mt-6"></hr>
                  <div className="p-3">
                    <h3>
                      <b className="text-text-dark text-lg font-karla">
                        Attendance Details:
                      </b>
                    </h3>
                  </div>
                  <div className="grid grid-cols-2 p-6 pt-2 gap-2">
                    <div>
                      <p className="text-text-dark text-base font-karla">
                        Month :{" "}
                        <b className="text-text-dark text-base font-karla">
                          {payslipDetails?.displayMonth}
                        </b>
                      </p>
                      <p className="text-text-dark text-base font-karla">
                        Total days Present :
                        <b className="text-text-dark text-base font-karla">
                          {payslipDetails?.presentDetails &&
                            payslipDetails?.presentDetails.length}
                        </b>
                      </p>
                    </div>
                    <div className="flex justify-end items-center gap-3">
                      <p className="flex jusitify-center items-center gap-2">
                        <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                        <span className="text-text-dark text-base font-karla">
                          Present
                        </span>
                      </p>
                      <p className="flex jusitify-center items-center gap-2">
                        <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                        <span className="text-text-dark text-base font-karla">
                          Absent
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="p-1 flex flex-col items-center justify-center">
                    <table className="overflow-x-scroll  text-center w-2/4">
                      <tbody>
                        <tr>
                          {payslipDetails?.daysArr &&
                            payslipDetails?.daysArr.map((list, i) => (
                              <th
                                className="border attendance-header border-[#d9d9d9] p-3"
                                key={i}
                              >
                                {list}
                              </th>
                            ))}
                        </tr>
                        {payslipDetails?.attendance_details &&
                          Object.keys(payslipDetails?.attendance_details).map(
                            (key) => (
                              <>
                                <tr>
                                  {payslipDetails?.attendance_details[key].map(
                                    (item, index) => {
                                      return (
                                        <td
                                          className="font-karla border border-[#d9d9d9] p-3"
                                          key={index}
                                        >
                                          <span
                                            className={`rounded px-2 py-1 ${
                                              item.value === "P"
                                                ? "bg-[#11B76B] text-[#FFF]"
                                                : item.value === "A"
                                                ? "bg-[#ed452a] text-[#FFF]"
                                                : ""
                                            }`}
                                          >
                                            {item.date}
                                          </span>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              </>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => handleVoucherPayment(qrCodeValue)}
                    type="button"
                    className="submit-button"
                    disabled={false}
                  >
                    <span className="submit-button-label">Save</span>
                    <img src={Save} alt="" />
                  </button>
                </div>
              </>
            ) : (tableName !== "") & (tableName === "agent_commission") ? (
              <>
                <div className="p-5">
                  <div className="bank-voucher-border p-5">
                    <h2 className="advance-voucher-title text-center">
                      Agent Commission voucher
                    </h2>
                    <div className="flex flex-col gap-4">
                      {/* <div className="flex justify-end">
                      <QRCode
                        value={listDetails?.voucher_no}
                        style={{ height: "80px" }}
                      />
                    </div> */}
                      <div className="flex justify-between w-full">
                        <div className="text-xl font-karla flex gap-3">
                          <span className="text-text-light">Date</span>
                          <span className="text-text-dark">
                            {agentDetails?.date}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <div className="text-xl font-karla flex gap-3">
                          <span className="text-text-light">Agent Name</span>
                          <span className="text-text-dark">
                            {agentDetails?.agent_name}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <div className="text-xl font-karla flex gap-3">
                          <span className="text-text-light">Amount</span>
                          <span className="text-text-dark">
                            {agentDetails?.amount}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <div className="">
                          <div
                            className="bank-voucher-border advance-voucher-bold p-5"
                            style={{ fontSize: "45px" }}
                          >
                            <span>&#8377;{agentDetails?.amount}</span>
                          </div>
                        </div>
                      </div>
                      <div className="text-xl font-karla flex gap-3 text-text-dark">
                        <div className="text-text-light">Amount in words:</div>

                        <div>
                          <span>
                            &#8377;
                            {numberToWords(parseFloat(agentDetails?.amount))}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-around text-text-dark font-karla p-3">
                        <div>
                          <div className="text-xl text-text-light">
                            Admit By
                          </div>
                          <div> {agentDetails?.created_by_name}</div>
                        </div>
                        <div>
                          <div className="text-xl text-text-light">
                            Checked By
                          </div>
                        </div>
                        <div>
                          <div className="text-xl text-text-light">
                            Agent Signature
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => handleVoucherPayment(qrCodeValue)}
                    type="button"
                    className="submit-button"
                    disabled={false}
                  >
                    <span className="submit-button-label">Save</span>
                    <img src={Save} alt="" />
                  </button>
                </div>
              </>
            ) : (
              <div className="field-block">
                <input
                  className="input-field"
                  type="text"
                  value={qrCodeValue}
                  ref={inputElement}
                  onChange={(e) => handleChange(e)}
                  placeholder="Scan or Enter Voucer ID"
                  autoComplete="off"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarcodeDetection;
