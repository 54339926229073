import React, { useState, useEffect } from "react";
import { GET_API } from "../../Services/api";
import UserTable from "../DataTable/UserTable";
import Search from "../../Assets/Icons/Search.svg";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { Link } from "react-router-dom";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";

function DepartmentList() {
  const [loading, setLoading] = useState(false);
  const [filteredUser, setFilteredUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_name", label: "Employee name" },
    { id: "role_name", label: "Role name" },
    { id: "email", label: "Email" },
    { id: "created_at", label: "Created at" },
  ];

  useEffect(() => {
    getUserList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getUserList(1, pageSize, searchKey);
  };

  const getUserList = async (page, size, search) => {
    setLoading(true);
    try {
      const departmentList = await GET_API(
        `/usersList?page=${page}&size=${size}&search=${search}`
      );
      if (departmentList?.status === 200) {
        if (
          departmentList?.data?.data?.current_page >
          departmentList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setFilteredUser(departmentList?.data?.data?.data);
        setLastPage(departmentList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setFilteredUser([]);
      setLastPage("");
      setLoading(false);
      setCurrentPage(1);
      alertify?.error("Failed to get department list...");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">User List</h4>
          <div>
            <div className="search-block">
              <form
                className="search"
                onSubmit={() => getUserList(currentPage, pageSize, searchKey)}
              >
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </form>
              <div className="add-new-button-block">
                <Link to="/master/hrms/user/create" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          {/**************** * Table ********************/}
          <UserTable
            list={filteredUser}
            columns={columns}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default DepartmentList;
