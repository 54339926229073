import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import AdvanceReportTable from "../../DataTable/AdvanceReportTable";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import Filter from "../../../Assets/Icons/Filter.svg";
import Select from "react-select";
import ButtonLoader from "../../ButtonLoader";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function AdvanceReportList() {
  const navigate = useNavigate();
  const [advanceList, setAdvanceList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [exportList, setExportList] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [downloadPDF, setDownloadPDF] = useState(false);

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isCurrentMonth, setCurrentMonth] = useState(true);
  const [showMonth, setshowMonth] = useState("");

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
    paid_status: "",
    employee_id: "",
    shift: "",
    agent: "",
    finance_date: "",
    payment_type: "",
  });

  const showDetails = (listDetail) => {
    const id = listDetail?.employeeCode;
    navigate(`/report/advance/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const handleFieldChange = (e, type, name) => {
    const currentMonth = dayjs().format("MM-YYYY");
    const monthYear = dayjs(e).format("MM-YYYY");

    if (type) {
      const month = dayjs(e).format("MM");
      const year = dayjs(e).format("YYYY");
      filterList["year"] = year;
      filterList["month"] = month;
      const list = { ...filterList };
      handleFilter(list, "");
      setCurrentMonth(currentMonth === monthYear ? true : false);
      getCurrentMonthAndYear();
    } else {
      const { value } = e;
      filterList[name] = value;
      const list = { ...filterList };
      handleFilter(list, name);
    }
  };

  const getCurrentMonthAndYear = () => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const currentMonth = months?.filter((month, i) => {
      return i + 1 === parseInt(filterList?.month);
    });
    setshowMonth(currentMonth?.toString());
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "designation_name", label: "Designation" },
    { id: "date", label: "Date" },
    { id: "paid_status", label: "Paid Status" },
    { id: "amount", label: "Amount" },
    // { id: "type", label: "Type" },
    { id: "more_icon", label: "" },
  ];

  const getexportData = (data) => {
    const attributesToRemove = ["empId", "EmployeeshiftId"];
    const updatedList = data.map((obj) => {
      const updatedObj = { ...obj };
      attributesToRemove.forEach((attr) => delete updatedObj[attr]);
      return updatedObj;
    });
    setExportList(updatedList);
  };

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeNameList();
    getShiftList();
    getHRAgentList();
  }, []);

  useEffect(() => {
    getAdvanceReportList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAdvanceReportList(1, pageSize, filterParams, searchKey);
  };

  const getAdvanceReportList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const list = await GET_API(
        `/advanceReport?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (list.status === 200) {
        if (list?.data?.data?.current_page > list?.data?.data?.last_page) {
          isPageNotExit();
          return;
        }

        setAdvanceList(list?.data?.data?.data);
        setfilteredList(list?.data?.data?.data);
        setLoading(false);
        setLastPage(list?.data?.data?.last_page);
        getexportData(list?.data?.data?.data);
      }
    } catch (error) {
      alertify.error("Failed to get Advance Report List");
      setAdvanceList([]);
      setfilteredList([]);
      setLoading(false);
      getexportData([]);
    }
  };

  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      setBranchList([]);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      setEmployeetype([]);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getEmployeeNameList = async () => {
    setIsEmpLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setIsShiftLoading(false);
      setShiftList([]);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setIsHrAgentLoading(false);
      sethragentList([]);
    }
  };

  const getExportasPDF = async () => {
    try {
      setDownloadPDF(true);
      const downloadForm = await GET_API(
        `advanceReportPdf?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "EmployeeForm.pdf";
        alink.click();
        setDownloadPDF(false);
      }
    } catch (error) {
      setDownloadPDF(false);
    }
  };

  const getExportAsExcel = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-advance?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Advance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const paidStatus = [
    { label: "Please Select", value: "" },
    { label: "paid", value: "0" },
    { label: "Not Paid", value: "1" },
  ];

  const paymentType = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isShiftLoading ||
    isHrAgentLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4] w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Advance Report List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => setSearchKey(e?.target?.value)}
                value={searchKey}
              />
              <img
                src={Search}
                className="cursor-pointer"
                onClick={() => {
                  getAdvanceReportList(
                    currentPage,
                    pageSize,
                    filterParams,
                    searchKey
                  );
                }}
                alt=""
              />
            </div>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
              <div className="add-new-button-block">
                {isExportLoading ? (
                  <ButtonLoader isBtnDisable={isExportLoading} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportAsExcel()}
                  >
                    <span>Export</span>
                    <img
                      src={ExcelExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>

              <div className="add-new-button-block">
                {downloadPDF ? (
                  <ButtonLoader isBtnDisable={downloadPDF} />
                ) : (
                  <button
                    className="add-new-button"
                    onClick={() => getExportasPDF()}
                  >
                    <span>Export as PDF</span>
                    <img
                      src={PDFExport}
                      alt="export"
                      style={{ height: "25px" }}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList?.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList?.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList?.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList?.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList?.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList?.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Shift</label>
                  <Select
                    options={shiftList}
                    value={shiftList?.find(
                      (option) => option?.value === filterList?.shift
                    )}
                    onChange={(e) => handleFilter(e, "shift", "select")}
                    name="shift"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">HR Agent</label>
                  <Select
                    options={hragentList}
                    value={hragentList?.find(
                      (option) => option?.value === filterList?.agent
                    )}
                    onChange={(e) => handleFilter(e, "agent", "select")}
                    name="agent"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Advance paid/Not paid</label>
                  <Select
                    options={paidStatus}
                    value={paidStatus?.find(
                      (option) => option?.value === filterList?.paid_status
                    )}
                    onChange={(e) => handleFilter(e, "paid_status", "select")}
                    name="paid_status"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Payment Type</label>
                  <Select
                    options={paymentType}
                    value={paymentType?.find(
                      (option) => option?.value === filterList?.payment_type
                    )}
                    onChange={(e) => handleFilter(e, "payment_type", "select")}
                    name="payment_type"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Finance Date</label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="finance_date"
                    value={filterList?.finance_date}
                    onChange={(e) => handleFilter(e, "finance_date", "date")}
                  />
                </div>

                <div className="field-block mb-4">
                  <label className="label-name">Month</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        label={'"month" and "year"'}
                        views={["month", "year"]}
                        name="monthYear"
                        className="custom-input-field"
                        onChange={(e) => {
                          handleFieldChange(e, true);
                        }}
                        value={dayjs(showMonth + " " + filterList?.year)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <AdvanceReportTable
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AdvanceReportList;
