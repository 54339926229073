import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Select from "react-select";
import alertify from "alertifyjs";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import AttendanceReportTable from "../../DataTable/AttendanceReportTable";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import More from "../../../Assets/Icons/More.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import TotalEmployeeIcon from "../../../Assets/Icons/AttendanceReport/TotalEmployee.svg";
import PresentEmployeeIcon from "../../../Assets/Icons/AttendanceReport/PresentEmployee.svg";
import AbsentEmployeeIcon from "../../../Assets/Icons/AttendanceReport/AbsentEmployee.svg";
import LeaveEmployeeIcon from "../../../Assets/Icons/AttendanceReport/LeaveEmployee.svg";
import ReportChart from "../../Chart/ReportChart";
import ButtonLoader from "../../ButtonLoader";

function AttendanceReportList() {
  const navigate = useNavigate();
  const [attendanceList, setAttendanceList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [casteList, setcasteList] = useState([]);
  const [religionList, setreligionList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [branchWise, setBranchWise] = useState([]);
  const [overAllReport, setOverAllReport] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
    employee_id: "",
    shift: "",
    agent: "",
    religion: "",
    caste: "",
    status: "",
  });
  const [graphDate, setGraphDate] = useState([]);
  const [barcharData, setBarcharData] = useState([]);
  const [TotalEmployee, setTotalEmployee] = useState(0);
  const [PresentEmployee, setPresentEmployee] = useState(0);
  const [LeaveEmployee, setLeaveEmployee] = useState(0);
  const [AbsentEmployee, setAbsentEmployee] = useState(0);
  const [LateEntry, setLateEntry] = useState(0);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);
  const [isReligionLoading, setIsReligionLoading] = useState(false);
  const [isCasteLoading, setIsCasteLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_code", label: "Employee Code" },
    { id: "first_name", label: "Employee Name" },
    { id: "departmentname", label: "Department Name" },
    { id: "date", label: "Date" },
    { id: "clock_time", label: "In Time" },
    { id: "clock_time", label: "Exit Time" },
    { id: "clock_time", label: "Late Entry" },
    { id: "more_icon", label: "" },
  ];

  const statusData = [
    { label: "Please Select", value: "" },
    { label: "Onduty", value: "onduty" },
    { label: "Leave", value: "leave" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getEmployeeList();
    getDepartmentList();
    getDesignationList();
    getShiftList();
    getHRAgentList();
    getCasteList();
    getReligionList();
  }, []);

  useEffect(() => {
    getAttendaceReportList(currentPage, pageSize, showFilterResultData);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAttendaceReportList(1, pageSize, filterParams);
  };
  const getAttendaceReportList = async (page, size, filters) => {
    setLoading(true);
    try {
      const list = await GET_API(
        `/attendanceReportList?page=${page}&size=${size}${filters}`
      );
      const listData = list?.data?.data;
      setGraphDate(listData?.graphDate);
      setBarcharData(listData?.chartData);
      setTotalEmployee(listData?.cardData?.totalEmployee);
      setAbsentEmployee(listData?.cardData?.absentEmployee);
      setPresentEmployee(listData?.cardData?.presentEmployee);
      setLeaveEmployee(listData?.cardData?.leaveEmployee);
      setLateEntry(listData?.cardData?.lateEntry);
      if (list.status === 200) {
        if (
          list?.attendance?.data?.current_page >
          list?.attendance?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLastPage(listData?.attendance?.last_page);
        // setAttendanceList(listData?.attendance?.data);
        setfilteredList(listData?.attendance?.data);
        // setBranchWise(listData?.branchwise);
        // setOverAllReport(listData?.totalLeave);
        setLoading(false);
        // getexportData(listData?.attendance?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setBranchList([]);
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setEmployeetype([]);
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        setIsEmpLoading(false);
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
      }
    } catch (error) {
      setEmployeeList([]);
      setIsEmpLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setDepartment([]);
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setDesignation([]);
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const shift = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        shift.unshift({ value: "", label: "Select..." });
        setShiftList(shift);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setShiftList([]);
      setIsShiftLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      sethragentList([]);
      setIsHrAgentLoading(false);
    }
  };

  const getCasteList = async () => {
    setIsCasteLoading(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList?.data?.status === 200) {
        setIsCasteLoading(false);
        const cast = casteList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        cast.unshift({ value: "", label: "Select..." });
        setcasteList(cast);
      }
    } catch (error) {
      alertify?.error("Failed to get Caste List");
      setIsCasteLoading(false);
      setcasteList([]);
    }
  };

  const getReligionList = async () => {
    setIsReligionLoading(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList?.data?.status === 200) {
        const religion = religionList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setreligionList(religion);
        setIsReligionLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Religion List");
      setreligionList([]);
      setIsReligionLoading(false);
    }
  };

  const getExport = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-attendance-list?page=${currentPage}&size=${pageSize}${filterParams}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Attendance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/report/attendance/details/${id}`, {
      state: {
        list: id,
      },
    });
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isShiftLoading ||
    isHrAgentLoading ||
    isReligionLoading ||
    isCasteLoading
  ) {
    return <Spinner />;
  }
  const barChartsettings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="grow bg-[#F7F6F4] w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <h1 className="heading-text">Attendance Report</h1>
        <div className="report-card-block">
          <Link to="/report/employee" className="card">
            <div className="content-left">
              <span className="card-value">{TotalEmployee}</span>
              <span className="card-label">Total Employees</span>
            </div>
            <div className="content-right">
              <img src={TotalEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/attendance" className="card">
            <div className="content-left">
              <span className="card-value">{PresentEmployee}</span>
              <span className="card-label">Employee Present</span>
            </div>
            <div className="content-right">
              <img src={PresentEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/absent" className="card">
            <div className="content-left">
              <span className="card-value">{AbsentEmployee}</span>
              <span className="card-label">Employee Absent</span>
            </div>
            <div className="content-right">
              <img src={AbsentEmployeeIcon} alt="" />
            </div>
          </Link>
          <Link to="/report/leave" className="card">
            <div className="content-left">
              <span className="card-value">{LeaveEmployee}</span>
              <span className="card-label">Employee Leave</span>
            </div>
            <div className="content-right">
              <img src={LeaveEmployeeIcon} alt="" />
            </div>
          </Link>

          <Link to="/report/latefine" className="card">
            <div className="content-left">
              <span className="card-value">{LateEntry}</span>
              <span className="card-label">Employee Late Entry</span>
            </div>
            <div className="content-right">
              <img src={LeaveEmployeeIcon} alt="" />
            </div>
          </Link>
        </div>
        <div className="flex gap-3 pb-5 justify-end">
          <div
            className="filter-button"
            onClick={() => setFilter((prev) => !prev)}
          >
            <span className="filter-btn-label">Filter</span>
            <img src={Filter} alt="" />
          </div>
          <div className="add-new-button-block">
            {isExportLoading ? (
              <ButtonLoader isBtnDisable={isExportLoading} />
            ) : (
              <button className="add-new-button" onClick={() => getExport()}>
                <span>Export</span>
                <img
                  src={ExcelExport}
                  alt="export"
                  style={{ height: "25px" }}
                />
              </button>
            )}
          </div>
        </div>
        {filter && (
          <form className="bg-[#fff]">
            <div className="form ">
              <div className="field-block dropdown-select-to-first">
                <label className="label-name">Branch Name</label>
                <Select
                  options={branchList}
                  value={branchList?.find(
                    (option) => option?.value === filterList.branch_id
                  )}
                  onChange={(e) => handleFilter(e, "branch_id", "select")}
                  name="branch_id"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-first">
                <label className="label-name">Employee Name</label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === filterList.employee_id
                  )}
                  onChange={(e) => handleFilter(e, "employee_id", "select")}
                  name="employee_id"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-first">
                <label className="label-name">Employee Type</label>
                <Select
                  options={employeetype}
                  value={employeetype?.find(
                    (option) => option?.value === filterList.employee_type_id
                  )}
                  onChange={(e) =>
                    handleFilter(e, "employee_type_id", "select")
                  }
                  name="employee_type_id"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-second">
                <label className="label-name">Gender</label>
                <Select
                  options={genderList}
                  value={genderList?.find(
                    (option) => option?.value === filterList.gender
                  )}
                  onChange={(e) => handleFilter(e, "gender", "select")}
                  name="gender"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-second">
                <label className="label-name">Department</label>
                <Select
                  options={department}
                  value={department?.find(
                    (option) => option?.value === filterList.department_id
                  )}
                  onChange={(e) => handleFilter(e, "department_id", "select")}
                  name="department_id"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-second">
                <label className="label-name">Designation</label>
                <Select
                  options={designation}
                  value={designation?.find(
                    (option) => option?.value === filterList.designation_id
                  )}
                  onChange={(e) => handleFilter(e, "designation_id", "select")}
                  name="designation_id"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-third">
                <label className="label-name">Shift</label>
                <Select
                  options={shiftList}
                  value={shiftList?.find(
                    (option) => option?.value === filterList.shift
                  )}
                  onChange={(e) => handleFilter(e, "shift", "select")}
                  name="shift"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-third">
                <label className="label-name">HR Agent</label>
                <Select
                  options={hragentList}
                  value={hragentList?.find(
                    (option) => option?.value === filterList?.agent
                  )}
                  onChange={(e) => handleFilter(e, "agent", "select")}
                  name="agent"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-third">
                <label className="label-name">Religion</label>
                <Select
                  options={religionList}
                  value={religionList?.find(
                    (option) => option?.value === filterList.religion
                  )}
                  onChange={(e) => handleFilter(e, "religion", "select")}
                  name="religion"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-fourth">
                <label className="label-name">Caste</label>
                <Select
                  options={casteList}
                  value={casteList?.find(
                    (option) => option?.value === filterList.caste
                  )}
                  onChange={(e) => handleFilter(e, "caste", "select")}
                  name="caste"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block dropdown-select-to-fourth">
                <label className="label-name">Status</label>
                <Select
                  options={statusData}
                  value={statusData?.find(
                    (option) => option?.value === filterList.status
                  )}
                  onChange={(e) => handleFilter(e, "status", "select")}
                  name="status"
                  className=""
                  isSearchable
                />
              </div>
              <div className="field-block">
                <label className="label-name">Date</label>
                <div className="flex gap-1">
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="from_date"
                    value={filterList?.from_date}
                    onChange={(e) => handleFilter(e, "from_date", "date")}
                  />
                  <label className="label-name">to</label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="to_date"
                    value={filterList?.to_date}
                    onChange={(e) => handleFilter(e, "to_date", "date")}
                  />
                </div>
              </div>

              <div className="add-new-button-block w-[40%] h-max">
                <button
                  className="add-new-button"
                  onClick={(e) => {
                    showFilterResult(e);
                  }}
                >
                  <span style={{ cursor: "pointer" }}>Show Result</span>
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="card-wrapper">
          <h4 className="card-header">Branch Record</h4>

          <div className=" ">
            <Slider {...barChartsettings}>
              {barcharData?.map((branch, i) => {
                return (
                  <React.Fragment key={i}>
                    <ReportChart
                      branchName={branch?.branch_name}
                      branchData={branch?.data}
                      date={graphDate}
                    />
                  </React.Fragment>
                );
              })}
            </Slider>
          </div>
          <div className="search-block">
            <div className=""></div>
            {/* <div className="add-new-button-block">
              <CSVLink
                className="view-list-button"
                data={exportList}
                headers={CSVFileHeaders}
              >
                <span>Export</span>
                <img src={Export} alt="export" />
              </CSVLink>
            </div> */}
          </div>

          <div id="chart" className="py-4"></div>

          <AttendanceReportTable
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AttendanceReportList;
