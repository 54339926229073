import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";

export default function StickyHeadTable({
  list,
  columns,
  totalAmount,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employeeCode}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employeeName}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.allowance_name}
                      </TableCell>
                    }

                    {<TableCell className="table-body">{row?.date}</TableCell>}
                    {
                      <TableCell className="table-body">
                        {row?.entryTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.exitTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.amount}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <div className="flex w-full justify-end food-allowance-total-amt">
          <div className="py-2 pr-6 font-karla">
            <span className="font-semibold">Total Amount:</span>{" "}
            {totalAmount[0]?.totalAmount}
          </div>
        </div>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
