import React, { useEffect, useState } from "react";
import { POST_API } from "../Services/api";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { handleLogin } from "./HandleLogin";
import { useNavigate } from "react-router-dom";

function FindYourAccount() {
  const location = useLocation();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [resetAccount, setResetAccount] = useState({
    user_name: "",
    password: "",
    token: "",
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    setResetAccount({ ...resetAccount, user_name: email, token: token });
  }, []);

  const handleForgotPassword = async () => {
    try {
      const checkMail = await POST_API("/resetPassword", resetAccount);
      if (checkMail?.data?.status === 200) {
        alertify?.success(checkMail?.data?.message);
        setResetAccount({ user_name: "", password: "", token: "" });
        navigate("/login");
        return;
      }
    } catch (error) {
      alertify.error("Something went wrong...");
      setResetAccount({ ...resetAccount, password: "" });
    }
  };
  return (
    <div className="h-screen">
      <div className="h-[77px] py-4 px-3 bg-white-color border-b-[1px] border-b-[#D9D9D9] flex justify-between item-center">
        <div>
          <span className="text-[#481212] font-montserrat text-2xl font-bold">
            Sumangali Silks
          </span>
        </div>
        <form
          className="flex gap-2"
          onSubmit={(e) =>
            handleLogin(e, login, navigate, setButtonDisabled, setErrors)
          }
        >
          <div>
            <div className="username-field">
              <input
                type="text"
                name="email"
                className="font-karla text-base text-text-light"
                autoComplete="off"
                style={{
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
                placeholder="User Name"
                onChange={(e) => {
                  setLogin({ ...login, email: e?.target?.value });
                }}
              />
            </div>
            {errors.email ? (
              <div className="validation-error-label">{errors.email}</div>
            ) : null}
          </div>
          <div>
            <div className="password-field">
              <input
                className="font-karla text-base text-text-light"
                name="password"
                style={{
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => {
                  setLogin({ ...login, password: e?.target?.value });
                }}
              />
            </div>
            {errors?.password ? (
              <div className="validation-error-label">{errors?.password}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="flex bg-primary-color p-2 justify-center items-center rounded"
            disabled={buttonDisabled}
          >
            <span className="text-white-color text-lg font-montserrat font-normal">
              Login
            </span>
          </button>
        </form>
      </div>
      <div className="bg-[#F7F6F4] h-[calc(100vh-77px-150px)] flex justify-center items-center ">
        <div className="bg-white-color shadow-lg rounded min-w-[30%]">
          <div className="p-2 border-b-[1px] border-b-[#D9D9D9]">
            <span className="text-text-dark text-2xl font-karla">
              Reset Password
            </span>
          </div>

          <div className="p-3">
            <input
              type="text"
              name="email"
              className="font-karla text-base text-text-light border-[1px] p-2 border-[#D9D9D9] rounded"
              autoComplete="off"
              style={{
                outline: "none",

                boxShadow: "none",
                width: "100%",
              }}
              placeholder="Email Address"
              value={resetAccount?.user_name}
              disabled
              readOnly
            />
          </div>
          <div className="p-3">
            <input
              type="password"
              name="email"
              className="font-karla text-base text-text-light border-[1px] p-2 border-[#D9D9D9] rounded"
              autoComplete="off"
              style={{
                outline: "none",

                boxShadow: "none",
                width: "100%",
              }}
              placeholder="Password"
              onChange={(e) => {
                setResetAccount({
                  ...resetAccount,
                  password: e?.target?.value,
                });
              }}
              value={resetAccount?.password}
            />
          </div>
          <div className="p-3 flex justify-end gap-2">
            <div className="add-new-button-block">
              <button
                className="add-new-button"
                onClick={() => {
                  handleForgotPassword();
                }}
              >
                <span>Reset</span>
              </button>
            </div>
            <div className="">
              <button className="view-list-button">
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindYourAccount;
