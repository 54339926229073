import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RightArrow from "../Assets/Icons/RightArrow.svg";
import SubMenuItems from "./SubMenuItems";
import { GET_API } from "../Services/api";
import alertify from "alertifyjs";
import { PrivilegeContext } from "../PrivilegeContext";

function MenuItems({ title, links, to, menuName, selected, handleMenuClick }) {
  const [subtitle, setSubtitle] = useState("");
  const [submenuList, setSubmenuList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [submenuStatus, setSubmenuStatus] = useState([]);
  const { setPrivilege, setAccess } = useContext(PrivilegeContext);

  useEffect(() => {
    getUserPrivilege();
  }, []);

  const getUserPrivilege = async () => {
    const userID = localStorage?.getItem("user_id");
    if (userID === "") return;
    try {
      const userPrivilege = await GET_API(`userprivilege/${userID}`);
      if (userPrivilege?.data?.status === 200) {
        setMenus(userPrivilege?.data?.data);
        setPrivilege(userPrivilege?.data?.data);
      }
    } catch (error) {
      alertify?.error("Failed to get User Privileges");
    }
  };

  const setMenus = (privilege) => {
    const menuArr = [...menuList];
    const subMenuArr = [...submenuList];
    const submenuAccess = [...submenuStatus];
    privilege?.map((module) => {
      return (
        module?.data &&
        module?.data?.map((list) => {
          menuArr?.push(list?.menu?.name);
          setMenuList(menuArr);
          return (
            list?.submenus &&
            list?.submenus?.map((submenu) => {
              submenu?.view === true && subMenuArr.push(submenu?.submenu);
              submenuAccess?.push(submenu);
              setAccess(submenuAccess);
              return setSubmenuList(subMenuArr);
            })
          );
        })
      );
    });
  };

  return (
    <>
      {menuList?.includes(menuName) && (
        <>
          <Link
            to={to}
            onClick={() =>
              handleMenuClick((prev) => (prev === title ? "" : title))
            }
            className="px-2 pt-[16px] flex cursor-pointer text-theme-text-color font-medium items-center justify-between"
          >
            <span
              className={`font-montserrat text-base whitespace-nowrap ${
                selected ? "text-text-dark font-medium" : "text-text-light"
              }`}
            >
              {title}
            </span>
            {links && (
              <img
                src={RightArrow}
                alt=""
                style={{
                  transform: selected ? "rotate(90deg)" : "rotate(0)",
                }}
              />
            )}
          </Link>
          {selected &&
            links?.map((link, i) => {
              return (
                <React.Fragment key={i}>
                  {link?.name ? (
                    <span
                      className="ml-[30px] flex gap-2 ml-[30px] p-2 cursor-pointer"
                      onClick={() =>
                        setSubtitle((prev) =>
                          prev === link?.id ? "" : link?.id
                        )
                      }
                    >
                      <span
                        className={`font-montserrat text-base whitespace-nowrap  ${
                          link?.id === subtitle
                            ? "text-text-dark font-medium"
                            : "text-text-light"
                        }`}
                      >
                        {link?.name}
                      </span>
                      <img
                        src={RightArrow}
                        alt=""
                        style={{
                          transform:
                            link?.id === subtitle
                              ? "rotate(90deg)"
                              : "rotate(0)",
                        }}
                      />
                    </span>
                  ) : (
                    link?.list?.map((list) => {
                      return (
                        submenuList?.includes(list?.key) && (
                          <React.Fragment key={i}>
                            <SubMenuItems
                              label={list}
                              name={link?.name}
                              setSubtitle={setSubtitle}
                            />
                          </React.Fragment>
                        )
                      );
                    })
                  )}

                  {link?.list?.map((list) => {
                    return (
                      <>
                        {submenuList?.includes(list?.key) &&
                          link?.id === subtitle && (
                            <React.Fragment key={i}>
                              <SubMenuItems
                                label={list}
                                name={link?.name}
                                setSubtitle={setSubtitle}
                              />
                            </React.Fragment>
                          )}
                      </>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </>
      )}
    </>
  );
}

export default MenuItems;
