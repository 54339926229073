import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import alertify from "alertifyjs";
import { Link, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API } from "../../../Services/api";
import PayslipDetailsListTable from "../../DataTable/PayslipDetailsListTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";

function PayslipsDetailsList() {
  const navigate = useNavigate();
  const [payslipsList, setPayslipsList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeetype, setEmployeetype] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const { access } = useContext(PrivilegeContext);
  const [showAccess, setShowAccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    month: "",
    year: "",
  });

  const showPayslipsDetails = (listDetail) => {
    navigate(`/payroll/payslipdetails/details/${listDetail.id}`, {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "date", label: "Month" },
    { id: "paid_status", label: "Paid Status" },
    { id: "more_icon", label: "Action" },
  ];

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "payslip_details") {
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((exp) => {
          return { value: exp?.id, label: exp?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    GET_API("/employeeDropdown")
      .then(async function (data) {
        const empList = data?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList?.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      })
      .catch((error) => {
        setIsEmpLoading(false);
      });
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeList();
  }, []);

  useEffect(() => {
    getPayslipsList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getPayslipsList(1, pageSize, filterParams, searchKey);
  };

  const getPayslipsList = async (page, size, filters, search) => {
    setLoading(true);
    try {
      const payslip = await GET_API(
        `/payslipDetails?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (payslip?.status === 200) {
        if (
          payslip?.data?.data?.current_page > payslip?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLastPage(payslip?.data?.data?.last_page);
        setPayslipsList(payslip?.data?.data?.data);
        setfilteredList(payslip?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLastPage("");
      setPayslipsList("");
      setfilteredList("");
    }
  };

  const handlePayslipsSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = payslipsList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilteredList(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Payslip",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletepayslips/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPayslipsList(currentPage, pageSize, filterParams, searchKey);
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigatePaySlipPage = (listDetail) => {
    navigate(`payslipdetails/details/${listDetail?.id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const handleFieldChange = (e, type, name) => {
    if (type) {
      const month = dayjs(e).format("MM");
      const year = dayjs(e).format("YYYY");
      filterList["year"] = year;
      filterList["month"] = month;
      const list = { ...filterList };
      handleFilter(list);
    } else {
      const { value } = e;
      filterList[name] = value;
      const list = { ...filterList };
      handleFilter(list, name);
      setfilterList({ ...filterList, [name]: value });
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (data, name) => {
    const list = { ...data };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      // setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Payslip Details List</div>
          <form
            onSubmit={() =>
              getPayslipsList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFieldChange(e, false, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeList}
                    value={employeeList.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFieldChange(e, false, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFieldChange(e, false, "employee_type_id")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFieldChange(e, false, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) =>
                      handleFieldChange(e, false, "department_id")
                    }
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFieldChange(e, false, "designation_id")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block mb-4">
                  <label className="label-name">Payslip</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        label={'"month" and "year"'}
                        views={["month", "year"]}
                        name="dateMonth"
                        className="custom-input-field"
                        onChange={(e) => {
                          handleFieldChange(e, true);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {/**************** * Table ********************/}

          <PayslipDetailsListTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={showAccess}
            paySlip={showAccess ? navigatePaySlipPage : false}
            showDetails={showPayslipsDetails}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default PayslipsDetailsList;
