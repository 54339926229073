import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API, POST_API, PUT_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import Edit from "../../../Assets/Icons/Edit.svg";
import AttributeValueTable from "../../DataTable/PurchaseOrder/AttributeValueTable";
import POValidation from "../../../Validation/POValidation";
import "alertifyjs/build/css/alertify.css";

function Attributes() {
  const [editStatus, setEditStatus] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [attributeValueList, setAttributeValueList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [attributeValue, setAttributeValue] = useState({
    po_attribute_id: "",
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "po_attribute_id", label: "Attribute" },
    { id: "name", label: "Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getAttributeList();
  }, []);

  useEffect(() => {
    getAttributeValue(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const getAttributeValue = async (page, size, search) => {
    setLoading(true);
    try {
      const attributeList = await GET_API(
        `/po/attributeValues?page=${page}&size=${size}&search=${search}`
      );
      if (attributeList?.data?.status === 200) {
        if (
          attributeList?.data?.data?.current_page >
          attributeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setPagination(attributeList?.data);
        setAttributeValueList(attributeList?.data?.data?.data);
        setFilteredList(attributeList?.data?.data?.data);
        setLastPage(attributeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Attribute Value List");
    }
  };

  const getAttributeList = async () => {
    setLoading(true);
    try {
      const attributeList = await GET_API("/po/attributes");
      if (attributeList?.data?.status === 200) {
        const attribute = attributeList?.data?.data?.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });
        attribute.unshift({ label: "Select...", value: "" });
        setAttributeList(attribute);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Attribute Value List");
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAttributeValue(1, pageSize, searchKey);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Attribute Value..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(
              `/po/deleteAttributeValues/${row_id}`
            );
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getAttributeValue(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Attribute Value");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const handleAttribute = async (e, type) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = POValidation.AttributeValue(attributeValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const url =
        type === "add"
          ? POST_API("/po/addAttributeValues", attributeValue)
          : PUT_API("/po/updateAttributeValues", attributeValue);
      const createAttribute = await url;
      if (createAttribute?.data?.status === 200) {
        setButtonDisabled(false);
        setAttributeValue({ name: "", description: "" });
        setEditStatus(false);
        getAttributeValue(currentPage, pageSize, searchKey);
        alertify.success(createAttribute?.data?.message);
        return;
      }
    } catch (error) {
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
      setButtonDisabled(false);
    }
  };

  const handleSelectChange = async (e, selectName) => {
    const { value } = e;
    setAttributeValue({ ...attributeValue, [selectName]: value });
  };

  if (loading) {
    return <Spinner />;
  }

  const resetForm = () => {
    setAttributeValue({ name: "", attribute_id: "" });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Attribute Values</h4>

          <form
            onSubmit={() => getAttributeList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block dropdown-select-to-third">
              <label className="label-name">
                Attribute
                <span className="required-symbol">*</span>
              </label>
              <Select
                options={attributeList}
                value={
                  attributeValue.po_attribute_id
                    ? attributeList?.find(
                        (option) =>
                          option?.value === attributeValue.po_attribute_id
                      )
                    : ""
                }
                onChange={(e) => handleSelectChange(e, "po_attribute_id")}
                name="po_attribute_id"
                className=""
                isSearchable
              />
              {errors.po_attribute_id ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.po_attribute_id}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Name"
                onChange={(e) => {
                  setAttributeValue({
                    ...attributeValue,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                autoComplete="off"
                value={attributeValue.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleAttribute(e, "edit");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      handleAttribute(e, "add");
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}

          <AttributeValueTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setAttributeValue}
            multiple={false}
            deleteRow={handleDelete}
            pagination={pagination}
            setState={setAttributeList}
            setSearch={setFilteredList}
            loader={setLoading}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
export default Attributes;
