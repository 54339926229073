import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API, DELETE_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Edit from "../../../Assets/Icons/Edit.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import LoanTable from "../../DataTable/LoanMasterTable";

function Advance() {
  const [advanceList, setAdvanceList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const [advanceDetails, setAdvanceDetails] = useState({
    name: "",
    description: "",
    type: "advance",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "advance_name", label: "Advance Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getAdvanceList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAdvanceList(1, pageSize, searchKey);
  };

  const getAdvanceList = async (page, size, search) => {
    setLoading(true);
    try {
      const holiday = await GET_API(
        `/FinanceCategoriesList/advance?page=${page}&size=${size}&search=${search}`
      );
      if (holiday?.status === 200) {
        if (
          holiday?.data?.data?.current_page > holiday?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setAdvanceList(holiday?.data?.data?.data);
        setFilteredList(holiday?.data?.data?.data);
        setLastPage(holiday?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAdvanceList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
    }
  };

  const handleFloorSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = advanceList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdvanceDetails({ ...advanceDetails, [name]: value, type: "advance" });
  };

  const addAdvance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFinance(advanceDetails, "Advance");
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createFloor = await POST_API("/addFinanceCategory", advanceDetails);
      if (createFloor.status === 200) {
        setButtonDisabled(false);
        setAdvanceDetails({ name: "", description: "", type: "advance" });
        getAdvanceList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        alertify.success(createFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setAdvanceDetails({ name: "", description: "", type: "advance" });
      setCurrentPage(1);
    }
  };
  const editAdvance = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateFinance(advanceDetails, "Advance");
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const editFloor = await POST_API("/updateCategories", advanceDetails);
      if (editFloor.status === 200) {
        setButtonDisabled(false);
        setAdvanceDetails({ name: "", description: "", type: "advance" });
        getAdvanceList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        setEditStatus(false);
        alertify.success(editFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setAdvanceDetails({ name: "", description: "", type: "advance" });
      setCurrentPage(1);
      setEditStatus(false);
    }
  };

  const resetForm = () => {
    setEditStatus(false);
    setAdvanceDetails({
      ...advanceDetails,
      name: "",
      description: "",
      type: "advance",
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Advance...?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteFinanceCategories/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getAdvanceList(currentPage, pageSize, searchKey);
                setCurrentPage(1);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Advance");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Add Advance</h4>

          <form
            onSubmit={() => getAdvanceList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Advance Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Advance Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={advanceDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={5}
                value={advanceDetails.description}
                autoComplete="off"
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editAdvance(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addAdvance(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          <LoanTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setAdvanceDetails}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Advance;
