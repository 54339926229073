import React, { useEffect, useState } from "react";
import Setting from "../../Assets/Icons/Setting.svg";
import { useNavigate } from "react-router-dom";
import SettingsCard from "./Settings/SettingsCard";
import { useGeneralSettings } from "../../GeneralSettingsContext";
import config from "../../Services/config.json";
import { GET_API } from "../../Services/api";
import alertify from "alertifyjs";
import UseOutsideClick from "../OutsideClick";

function Header() {
  const navigate = useNavigate();
  const [isSetting, setIsSetting] = useState(false);
  const { generalSettings } = useGeneralSettings();
  const { setGeneralSettings } = useGeneralSettings();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSettingsDetails();
  }, []);

  const actionModel = UseOutsideClick(() => {
    setIsSetting(false);
  });

  const getSettingsDetails = async () => {
    setLoading(true);
    try {
      const settings = await GET_API("settings");
      if (settings?.data?.status === 200) {
        const list = settings?.data?.data;

        setGeneralSettings({
          title: list?.title,
          logo_to_show: config?.path + list?.logo,
          favicon_to_show: config?.path + list?.favicon,
          primary_color: list?.primary_color,
          secondary_color: list?.secondary_color,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get general setting value");
    }
  };
  const userName = localStorage?.getItem("user_name");
  return (
    <div className="pt-[12px] pb-[12px] pl-[24px] pr-[24px] flex justify-between items-center border border-primary h-[66px]">
      <div>
        <div className="flex items-center gap-4">
          <img
            src={generalSettings?.logo_to_show}
            alt=""
            className="h-[40px] w-[40px] rounded-3xl"
            onClick={() => navigate("/dashboard")}
          />
          <span className="text-special-text-color font-bold font-montserrat text-base">
            {generalSettings?.title}
          </span>
        </div>
      </div>
      <div className="flex gap-6">
        {/* <div className="flex justify-center items-center">
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="flex justify-center items-center">
          <img src={Support} alt="" />
        </div> */}
        {userName && (
          <div className="font-karla text">
            Hello,
            <span className="text-[#ff0000] font-semibold">
              {userName + "👋"}
            </span>
          </div>
        )}

        <div
          className={`flex justify-center items-center cursor-pointer relative`}
          onClick={() => {
            setIsSetting((current) => !current);
          }}
        >
          <img
            src={Setting}
            alt=""
            className={`${
              isSetting ? "duration-500 rotate-45 " : "duration-500 rotate-0"
            }`}
          />
          <div ref={actionModel}>{isSetting && <SettingsCard />}</div>
        </div>
        {/* <div className="flex justify-center items-center">
          <img src={Remainder} alt="" />
        </div> */}
      </div>
    </div>
  );
}
export default Header;
