import React, { useEffect, useState } from "react";
import Delete from "../../Assets/Icons/Delete.svg";

function AllowanceListItems({
  allowanceTypeList,
  index,
  deleteList,
  setState,
  state,
  page,
  addMoreList,
}) {
  const [disabled, setdisabled] = useState(false);
  const calculationMethod = [
    { value: "monthly", label: "Monthly" },
    { value: "daily", label: "Daily" },
    { value: "fixed", label: "Fixed" },
  ];

  useEffect(() => {
    if (page === "list") {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newArr = [...state];
    if (type === "checkbox") {
      newArr[index][name] = checked;
    } else {
      newArr[index][name] = value;
    }
    setState(newArr);
  };
  return (
    <div className="p-4 grid grid-cols-5 gap-5">
      <div className="input-block" style={{ width: "100%" }}>
        <label className="add-new-input-label">
          Allowance Type <span className="required-symbol">*</span>
        </label>
        <select
          className="input-field"
          onChange={(e) => handleChange(e)}
          name="allowance_type_id"
          disabled={disabled}
          value={state[index]["allowance_type_id"]}
        >
          <option value="">Please Select</option>
          {allowanceTypeList?.map((item) => {
            return (
              <option
                value={item?.id}
                selected={
                  typeof state !== "undefined" &&
                  state[index]["allowance_type_id"] === item?.id
                }
              >
                {item?.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="input-block" style={{ width: "100%" }}>
        <label className="add-new-input-label">In Compliance</label>
        <input
          type="checkbox"
          onClick={(e) => handleChange(e)}
          name="in_compliance"
          disabled={disabled}
          checked={
            typeof state !== "undefined" &&
            (state[index]["in_compliance"] === 1 ||
              state[index]["in_compliance"] === true)
          }
        />
      </div>
      <div className="input-block" style={{ width: "100%" }}>
        <label className="add-new-input-label">
          Calculation Method <span className="required-symbol">*</span>
        </label>
        <select
          className="input-field"
          onChange={(e) => handleChange(e)}
          name="calculation_method"
          disabled={disabled}
        >
          <option value="">Please Select</option>
          {calculationMethod?.map((method) => {
            return (
              <option
                value={method.value}
                selected={
                  typeof state !== "undefined" &&
                  state[index]["calculation_method"] === method?.value
                }
              >
                {method.label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="input-block" style={{ width: "100%" }}>
        <label className="add-new-input-label">
          Amount <span className="required-symbol">*</span>
        </label>
        <input
          type="number"
          name="amount"
          placeholder="Enter Amount"
          className="input-field"
          onChange={(e) => handleChange(e)}
          value={typeof state !== "undefined" ? state[index]["amount"] : "0"}
          disabled={disabled}
        />
      </div>
      {page === "list" ? (
        <button
          onClick={() => {
            deleteList(state[index]["id"]);
          }}
        >
          Delete
        </button>
      ) : index > 0 ? (
        <button
          onClick={() => {
            deleteList(index);
          }}
        >
          <img src={Delete} alt="" className="w-6 h-6" />
        </button>
      ) : (
        <div className="flex items-center">
          <div
            className="add-new-button-block add-new-button-container "
            onClick={() => addMoreList()}
          >
            <button className="add-new-button">
              <span>Add</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllowanceListItems;
