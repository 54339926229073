import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../../Validation/POValidation";
import { GET_API, POST_API } from "../../../../Services/api";
import ColorAdd from "../../../../Assets/Icons/ColorAdd.svg";
import Select from "react-select";
import Spinner from "../../../Spinner";

function CreatePODepartment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedDept, setSelectedDept] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedDept(e);
    let deptID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setDepartmentDetails(() => ({ ...departmentDetails, category: deptID }));
  };

  const getCategoryList = async () => {
    setLoading(true);
    const category = await GET_API("po/categoryDropdown");
    if (category?.data?.status === 200) {
      const categoryList = category?.data?.data.map((dep, i) => {
        return { value: dep?.id, label: dep?.name };
      });
      categoryList.unshift({ value: " ", label: "Select..." });
      setCategoryList(categoryList);
      setLoading(false);
    }
  };

  const [departmentDetails, setDepartmentDetails] = useState({
    name: "",
    section: "",
    hsn_code: "",
    category: [],
    description: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setDepartmentDetails(() => ({ ...departmentDetails, [name]: value }));
  };

  const createDept = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.Department(departmentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createDepartment = await POST_API(
        "/po/addPoDepartment",
        departmentDetails
      );
      if (createDepartment?.data?.status === 200) {
        setButtonDisabled(false);
        alertify.success(createDepartment?.data?.message);
        navigate("/master/po/department");
      }
    } catch (error) {
      setButtonDisabled(false);
      for (const key in error?.response?.data?.data) {
        if (error?.response?.data?.data.hasOwnProperty(key)) {
          error?.response?.data?.data[key].forEach((value) => {
            alertify.error(value);
          });
        }
      }
    }
  };
  const resetForm = () => {
    setDepartmentDetails({
      name: "",
      section: "",
      hsn_code: "",
      category: [],
      description: "",
    });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/po/department" className="in-active">
              Department List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Department</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Department</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createDept(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Department Name"
                  value={departmentDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Section Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="section"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Section Name"
                  value={departmentDetails?.section}
                />
                {errors.section ? (
                  <div className="validation-error-label">{errors.section}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  HSN Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="hsn_code"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter HSN Code"
                  value={departmentDetails?.hsn_code}
                />
                {errors.hsn_code ? (
                  <div className="validation-error-label">
                    {errors.hsn_code}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Category
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={categoryList}
                  value={selectedDept ? selectedDept : []}
                  onChange={(e) => handleSelectChange(e)}
                  isMulti
                />
                {errors.category ? (
                  <div className="validation-error-label">
                    {errors.category}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  name="description"
                  autoComplete="off"
                  rows={5}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Description"
                  value={departmentDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreatePODepartment;
