import React from "react";
import { Link, useLocation } from "react-router-dom";

function EarlyExitApprovalDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name,
    },
    {
      label: "Employee Code",
      value: listDetails?.EmployeeCode,
    },

    {
      label: "Clock In Time",
      value: listDetails?.clock_in_time,
    },
    {
      label: "Clock Out Time",
      value: listDetails?.clock_out_time,
    },
    {
      label: "Shift",
      value: listDetails?.shift_from_time + " - " + listDetails?.shift_to_time,
    },
    {
      label: "Department",
      value: listDetails?.department_name,
    },

    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <div className="bread-crumbs">
            <div>
              <Link to="/approve/earlyExit" className="in-active">
                Early Exit Report
              </Link>
              <Link>{" > "}</Link>
              <Link className="active">Early Exit Details</Link>
            </div>
          </div>
          <div className="card-wrapper">
            <h4 className="card-header">Early Exit Details</h4>
            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
                <div className="list ">
                  <label className="list-label">Logs</label>
                  {listDetails?.logs &&
                    JSON.parse(listDetails?.logs).map((item) => {
                      return (
                        <div
                          style={{ wordBreak: "break-word" }}
                          className="list-value"
                        >
                          <ul>
                            <li>{item?.time}</li>
                            <li>{item?.ip}</li>
                          </ul>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EarlyExitApprovalDetails;
