import React from "react";
import { Link, useLocation } from "react-router-dom";


function ReimbursementSchemeDetails() {
  const { state } = useLocation();
  const schemeDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: schemeDetails?.employee_name,
    },
    {
      label: "Reimbursement Date",
      value: schemeDetails?.reimbursement_date,
    },
    {
      label: "Deduction Date",
      value: schemeDetails?.deduction_date,
    },
    {
      label: "Amount",
      value: schemeDetails?.amount,
    },
    {
      label: "Reason",
      value: schemeDetails?.remarks,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/reimbursementScheme" className="in-active">
              Reimbursement Scheme List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Reimbursement Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Reimbursement Details</h4>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReimbursementSchemeDetails;
