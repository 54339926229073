import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";

function CreateSchame() {
  const navigate = useNavigate();

  useEffect(() => {
    getEmploymentList();
  }, []);

  const [schemeList, setSchemeList] = useState({
    employee_id: "",
    deduction_date: "",
    reimbursement_date: "",
    amount: "",
    reason: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmploymentList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const emp = employeeList?.data?.data.map((list, i) => {
          return {
            value: list?.id,
            label: `${list?.first_name} (${list?.emp_code})`,
          };
        });
        emp.unshift({ value: "", label: "Select..." });
        setEmployeeList(emp);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get employee details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setSchemeList(() => ({ ...schemeList, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setSchemeList(() => ({ ...schemeList, [name]: e?.value }));
  };

  const createScheme = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateReimbursementScheme(schemeList);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addReimbursementScheme", schemeList)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/master/hrms/reimbursementScheme");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => {
    setSchemeList({
      employee_id: "",
      deduction_date: "",
      reimbursement_date: "",
      amount: "",
      reason: "",
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/master/hrms/reimbursementScheme" className="in-active">
              Reimbursement List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Reimbursement</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Add Reimbursement</h4>

          {/***************Form************* **/}

          <form onSubmit={(e) => createScheme(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === schemeList.employee_id
                  )}
                  onChange={(e) => handleSelectChange(e, "employee_id")}
                  name="employee_id"
                  placeholder="Please select"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Deduction Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="date"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="deduction_date"
                  placeholder="Enter From Date"
                  value={schemeList.deduction_date}
                />
                {errors.deduction_date ? (
                  <div className="validation-error-label">
                    {errors.deduction_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Reimbursement Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="reimbursement_date"
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter To Date"
                  value={schemeList?.reimbursement_date}
                />
                {errors.reimbursement_date ? (
                  <div className="validation-error-label">
                    {errors.reimbursement_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  name="amount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                  value={schemeList?.amount}
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Reason
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  rows={5}
                  className="input-field"
                  name="reason"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.reason ? (
                  <div className="validation-error-label">{errors.reason}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSchame;
