import React, { useState } from "react";
import { POST_API } from "../Services/api";
import alertify from "alertifyjs";
import { handleLogin } from "./HandleLogin";
import { useNavigate } from "react-router-dom";
import Validation from "../Validation/Validation";

function FindYourAccount() {
  const [email, setEmail] = useState({});
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const isEmailValidOrNot = async () => {
    const isValid = Validation.findYourAccount(email);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const checkMail = await POST_API("/identifyUser", { email: email });
      if (checkMail?.data?.status === 200) {
        alertify?.success(checkMail?.data?.message);
        setEmail({ findEmail: "" });
      }
      if (checkMail?.data?.status === 403) {
        alertify?.error(checkMail?.data?.message);
        setEmail({ findEmail: "" });
      }
    } catch (error) {
      alertify.error("Something went wrong...");
    }
  };
  return (
    <div className="h-screen">
      <div className="h-[77px] py-4 px-3 bg-white-color border-b-[1px] border-b-[#D9D9D9] flex justify-between item-center">
        <div>
          <span className="text-[#481212] font-montserrat text-2xl font-bold">
            Sumangali Silks
          </span>
        </div>
        <form
          onSubmit={(e) => {
            handleLogin(e, login, navigate, setButtonDisabled, setErrors);
          }}
          className="flex gap-2"
        >
          <div>
            <div className="username-field">
              <input
                type="text"
                name="email"
                className="font-karla text-base text-text-light"
                autoComplete="off"
                style={{
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
                placeholder="User Name"
                onChange={(e) => {
                  setLogin({ ...login, email: e?.target?.value });
                }}
              />
            </div>
            {errors.email ? (
              <div className="validation-error-label">{errors.email}</div>
            ) : null}
          </div>
          <div>
            <div className="password-field">
              <input
                className="font-karla text-base text-text-light"
                name="password"
                style={{
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => {
                  setLogin({ ...login, password: e?.target?.value });
                }}
              />
            </div>
            {errors?.password ? (
              <div className="validation-error-label">{errors?.password}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="flex bg-primary-color p-2 justify-center items-center rounded"
            disabled={buttonDisabled}
            // onClick={handleLogin()}
          >
            <span className="text-white-color text-lg font-montserrat font-normal">
              Login
            </span>
          </button>
        </form>
      </div>
      <div className="bg-[#F7F6F4] h-[calc(100vh-77px-150px)] flex justify-center items-center">
        <div className="bg-white-color shadow-lg rounded">
          <div className="p-2 border-b-[1px] border-b-[#D9D9D9]">
            <span className="text-text-dark text-2xl font-karla">
              Find Your Account
            </span>
          </div>
          <div className="p-3 text-text-light text-base font-karla">
            Please enter your email address to search for your account
          </div>
          <div className="p-3 min-h-[90px]">
            <input
              type="text"
              name="findEmail"
              className="font-karla text-base text-text-light border-[1px] p-2 border-[#D9D9D9] rounded"
              autoComplete="off"
              style={{
                outline: "none",

                boxShadow: "none",
                width: "100%",
              }}
              placeholder="Email Address"
              onChange={(e) => {
                setEmail({ findEmail: e?.target?.value });
              }}
              value={email.findEmail}
            />
            {errors.findEmail ? (
              <div className="validation-error-label mt-[6px]">
                {errors.findEmail}
              </div>
            ) : null}
          </div>
          <div className="p-3 flex justify-end gap-2">
            <div className="add-new-button-block">
              <button
                type="button"
                className="add-new-button"
                onClick={() => {
                  isEmailValidOrNot();
                }}
                disabled={buttonDisabled}
              >
                <span>Search</span>
              </button>
            </div>
            <div className="">
              <button
                className="view-list-button"
                onClick={() => {
                  setEmail({ findEmail: "" });
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindYourAccount;
