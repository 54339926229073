import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import BreakTimeTable from "../../DataTable/BreakTimeTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function BreakTimeList() {
  const navigate = useNavigate();
  const [breakTimeList, setBreakTimeList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");

  const showBreakTimeDetails = (listDetail) => {
    navigate("/master/hrms/break/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/master/hrms/break/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Break Name" },
    { id: "time", label: "Time" },
    { id: "shift", label: "For Shift" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBreakTimeList(1, pageSize, searchKey);
  };

  const getBreakTimeList = async (page, size, search) => {
    setLoading(true);
    try {
      const breakTimeList = await GET_API(
        `/breakTimeList?page=${page}&size=${size}&search=${search}`
      );
      if (breakTimeList.status === 200) {
        if (
          breakTimeList?.data?.data?.current_page >
          breakTimeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setBreakTimeList(breakTimeList?.data?.data?.data);
        setLastPage(breakTimeList?.data?.data?.last_page);
        setFilteredBranch(breakTimeList?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Break Time List");
    }
  };

  useEffect(() => {
    getBreakTimeList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Break Time..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(`/deleteBreakTime/${row_id}`);
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getBreakTimeList(currentPage, pageSize, searchKey);
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Break Time");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Break List</h4>

          <form
            onSubmit={() => getBreakTimeList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="add-new-button-block">
                <Link to="/master/hrms/break/create" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </form>

          {/**************** * Table ********************/}

          <BreakTimeTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            showBranchDetails={showBreakTimeDetails}
            showDetails={showBreakTimeDetails}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default BreakTimeList;
