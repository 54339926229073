import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { GET_API, POST_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import RejoinEmployeeListTable from "../../DataTable/RejoinEmployeeListTable";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import Filter from "../../../Assets/Icons/Filter.svg";
import More from "../../../Assets/Icons/More.svg";
import RejoinModal from "./RejoinModal";
import { PrivilegeContext } from "../../../PrivilegeContext";

function RejoinEmployeeList() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [rejoinDetails, setRejoinDetails] = useState({
    employee_id: "",
    remarks: "",
    rejoined_by: "",
    rejoined_on: "",
  });
  const [terminationList, setTerminationList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeName, setEmployeeName] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [govIdProof, setGovIdProof] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [exportList, setExportList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    employee_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    to_date: "",
    shift: "",
    agent: "",
    governmentIdProof: "",
  });

  const showDetails = (listDetail) => {
    const id = listDetail?.employeeId;
    navigate(`/report/termination/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "employeeName", label: "Employee Name" },
    { id: "remarks", label: "Remarks" },
    { id: "terminated_by_name", label: "Terminated By" },
    { id: "created_at", label: "Terminated Date" },
    { id: "more_icon", label: "" },
  ];

  const getexportData = (data) => {
    const attributesToRemove = [
      "agent_id",
      "blood_group_id",
      "branch_id",
      "break_time_id",
      "caste_id",
      "department_id",
      "designation_id",
      "employee_Id",
      "employee_id",
      "employee_type_id",
      "floor",
      "government_proof_id",
      "government_proof_value",
      "holiday_scheme_id",
      "id",
      "is_active",
      "pmrpy_scheme",
      "pf_complaint",
      "photo_path",
      "proof_image",
      "reporting_to",
      "religion_id",
      "shift_id",
      "updated_at",
    ];

    const updatedList = data.map((obj) => {
      const updatedObj = { ...obj };
      attributesToRemove.forEach((attr) => delete updatedObj[attr]);
      return updatedObj;
    });
    setExportList(updatedList);
  };

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getEmployeeNameList();
    getShiftList();
    getHRAgentList();
    getDepartmentList();
    getDesignationList();
    getGovIdProofList();
  }, []);

  useEffect(() => {
    getTerminationReportList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "employee_rejoin") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getTerminationReportList(1, pageSize, filterParams, searchKey);
  };

  const getTerminationReportList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const list = await GET_API(
        `/employeeTerminationReport?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (list.status === 200) {
        if (list?.data?.data?.current_page > list?.data?.data?.last_page) {
          isPageNotExit();
          return;
        }

        setTerminationList(list?.data?.data?.data);
        setTerminationList(list?.data?.data?.last_page);
        setfilteredList(list?.data?.data?.data);
        setLastPage(list?.data?.data?.last_page);
        setLoading(false);
        getexportData(list?.data?.data?.data);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeNameList = async () => {
    setLoading(true);
    try {
      const empName = await GET_API("/allEmployeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getShiftList = async () => {
    setLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const shift = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        shift.unshift({ value: "", label: "Select..." });
        setShiftList(shift);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setLoading(true);
    try {
      const HRAgent = await GET_API("/agentListDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.contact_person };
        });
        agent.unshift({ value: "", label: "Please Select" });
        sethragentList(agent);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setLoading(false);
    }
  };

  const getGovIdProofList = async () => {
    setLoading(true);
    try {
      const govList = await GET_API("/governmentIdProofDropdown");
      if (govList?.data?.status === 200) {
        const govProof = govList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        govProof.unshift({ value: "", label: "Please Select" });
        setGovIdProof(govProof);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Government ID Proof List...");
      setLoading(false);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value =
      type === "date" || type === "input" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
    setRejoinDetails({
      employee_id: "",
      remarks: "",
      rejoined_by: "",
      rejoined_on: "",
    });
  };

  const handleEditModal = (row) => {
    const userId = localStorage?.getItem("user_id");
    setRejoinDetails({
      ...rejoinDetails,
      employee_id: row?.employee_id,
      remarks: row?.remarks,
      rejoined_by: Number(userId),
      rejoined_on: row?.rejoined_on,
    });
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRejoinDetails({ ...rejoinDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsBtnDisable(true);
      const rejoin = await POST_API("/EmployeeRejoinDetails", rejoinDetails);
      if (rejoin) {
        alertify.success(rejoin?.data?.message);
        setIsOpen(!isOpen);
        getTerminationReportList(
          currentPage,
          pageSize,
          filterParams,
          searchKey
        );
        const alink = document.createElement("a");
        alink.href = rejoin.data.data.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Rejoin Employee.pdf";
        alink.click();
        setRejoinDetails({
          employee_id: "",
          remarks: "",
          rejoined_by: "",
        });
        setIsBtnDisable(false);
      }
    } catch (error) {
      setIsBtnDisable(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Employee Rejoin List</div>
          <form
            onSubmit={() =>
              getTerminationReportList(
                currentPage,
                pageSize,
                filterParams,
                searchKey
              )
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Shift</label>
                  <Select
                    options={shiftList}
                    value={shiftList?.find(
                      (option) => option?.value === filterList.shift
                    )}
                    onChange={(e) => handleFilter(e, "shift", "select")}
                    name="shift"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">HR Agent</label>
                  <Select
                    options={hragentList}
                    value={hragentList?.find(
                      (option) => option?.value === filterList?.agent
                    )}
                    onChange={(e) => handleFilter(e, "agent", "select")}
                    name="agent"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="from_date"
                      onChange={(e) => handleFilter(e, "from_date", "date")}
                      value={filterList?.from_date}
                      max={filterList?.to_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="to_date"
                      onChange={(e) => handleFilter(e, "to_date", "select")}
                      value={filterList?.to_date}
                      min={filterList?.from_date}
                    />
                  </div>
                </div>
                <div className="field-block dropdown-select-to-fourth">
                  <label className="label-name">
                    Government ID Proof Number
                  </label>
                  {/* <Select
                    options={govIdProof}
                    value={govIdProof?.find(
                      (option) => option?.value === filterList.governmentIdProof
                    )}
                    onChange={(e) =>
                      handleFilter(e, "governmentIdProof", "select")
                    }
                    name="governmentIdProof"
                    className=""
                    isSearchable
                  /> */}
                  <input
                    type="number"
                    className="input-field"
                    name="governmentIdProof"
                    placeholder="Enter ID Proof Number"
                    onChange={(e) =>
                      handleFilter(e, "governmentIdProof", "input")
                    }
                    value={filterList.governmentIdProof}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <RejoinEmployeeListTable
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            handleEditModal={handleEditModal}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            addAccess={addAccess}
          />
        </div>
        <RejoinModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          remarks={rejoinDetails?.remarks}
          isBtnDisable={isBtnDisable}
        />
      </div>
    </div>
  );
}

export default RejoinEmployeeList;
