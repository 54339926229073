import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function StickyHeadTable({
  list,
  columns,
  pageSize,
  currentPage,
}) {
  return (
    <Paper className="branch-wise-report-table">
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label=" table text-center w-2/4">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header border border-[#d9d9d9]"
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {columns.map((column) =>
                      column === "S.NO" ? (
                        <TableCell className="table-body border border-[#d9d9d9]">
                          {(currentPage - 1) * pageSize + (i + 1)}
                        </TableCell>
                      ) : column === "Month" ? (
                        <TableCell className="table-body border border-[#d9d9d9]">
                          {row?.month}
                        </TableCell>
                      ) : (
                        <TableCell className="table-body border border-[#d9d9d9]">
                          {row[column] === "P" ? (
                            <p className="text-[#11B76B]">{row[column]}</p>
                          ) : row[column] === "A" ? (
                            <p className="text-[#ed452a]">X</p>
                          ) : (
                            row[column]
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
