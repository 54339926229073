import React from "react";
import HRMS from "./../Assets/Logo/HRMS.png";

function HomePage() {
  return (
    <>
      <div className="home-page-logo"></div>
      <div className="absolute flex w-full h-[100%] justify-center items-center">
        <div className="master"></div>
        <div className="h-[230px] w-[230px] border-b-[1px] border-[#D9D9D9] p-2 bg-white-color rounded-xl">
          <img src={HRMS} alt="" />
        </div>
        <div className="po"></div>
      </div>
    </>
  );
}

export default HomePage;
