import React, { useState, useEffect, useContext } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link, useNavigate } from "react-router-dom";
import { BASE_PATH, GET_API } from "../../Services/api";
import Search from "../../Assets/Icons/Search.svg";
import BonusListTable from "../DataTable/BonusListTable";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import { PrivilegeContext } from "../../PrivilegeContext";
import Filter from "../../Assets/Icons/Filter.svg";
import Select from "react-select";
import PDFExport from "../../Assets/Icons/pdf-export.svg";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import ButtonLoader from "../ButtonLoader";

function BonusList() {
  const navigate = useNavigate();
  const [bonusList, setBonusList] = useState([]);
  const [filteredBonusList, setFilteredBonusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [filter, setFilter] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filterParams, setFilterParams] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");

  // Export Excel and PDF
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  // const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    religion_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "1997-01-01",
    joining_to_date: "",
  });

  const showDetails = (listDetail) => {
    navigate("/expense/bonus/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    {
      id: "financeCategoryName",
      label: "Finance Category Name",
    },
    { id: "amount", label: "Amount" },
    { id: "paid_status", label: "Voucher Paid Status" },
    { id: "remarks", label: "Remarks" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeList();
    getReligionList();
    getCurrentDate();
  }, []);

  const getCurrentDate = () => {
    const day = new Date();
    const date = day?.getDate();
    const month = String(day.getMonth() + 1).padStart(2, "0");
    const year = day?.getFullYear();
    setfilterList({
      ...filterList,
      joining_to_date: year + "-" + month + "-" + date,
    });
  };

  const getEmployeeList = async () => {
    try {
      setLoading(true);
      const employeeList = await GET_API(`/employeeDropdown`);
      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        const empList = employeeList?.data?.data?.map((emp) => {
          return {
            label: `${emp?.first_name} (${emp?.emp_code})`,
            value: emp?.id,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployeeName(empList);

        setLastPage(employeeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });

        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getReligionList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/religionDropdown");
      if (list.status === 200) {
        const religion = list?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        setLoading(false);
        setReligionList(religion);
        return;
      }
    } catch (error) {
      setReligionList([]);
      setLoading(false);
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getExport = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `export-bonusDetails?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Bonus.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const getExportasPDF = async () => {
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `FinanceDetailsListBonusPdf?${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Bonus.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  useEffect(() => {
    getAccess();
  }, [access]);

  useEffect(() => {
    getBonusList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBonusList(1, pageSize, searchKey);
  };

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "bonus") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const getBonusList = async (page, size, search) => {
    setLoading(true);
    try {
      const bonusList = await GET_API(
        `/BonusDetailsList?page=${page}&size=${size}${filterParams}&search=${search}`
      );
      if (bonusList.status === 200) {
        setLoading(false);
        setLastPage(bonusList?.data?.data?.last_page);
        setBonusList(bonusList?.data?.data?.data);
        setFilteredBonusList(bonusList?.data?.data?.data);
        if (
          bonusList?.data?.data?.current_page > bonusList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
      }
    } catch (error) {
      setLoading(false);
      setLastPage("");
      setBonusList([]);
      setFilteredBonusList([]);
      alertify.error("Failed to get Bonus List");
    }
  };

  const handleFilter = async (e, name) => {
    const { value } =
      name === ("joining_from_date" || "joining_to_date") ? e?.target : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow" style={{ width: "80%" }}>
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Bonus List</div>
          <form onSubmit={() => getBonusList(currentPage, pageSize, searchKey)}>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
                <div className="add-new-button-block">
                  {isExportLoading ? (
                    <ButtonLoader isBtnDisable={isExportLoading} />
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={() => getExport()}
                    >
                      <span>Export</span>
                      <img
                        src={ExcelExport}
                        alt="export"
                        style={{ height: "25px" }}
                      />
                    </button>
                  )}
                </div>

                <div className="add-new-button-block">
                  {isExportPdfLoading ? (
                    <ButtonLoader isBtnDisable={isExportPdfLoading} />
                  ) : (
                    <button
                      className="add-new-button whitespace-nowrap"
                      onClick={() => getExportasPDF()}
                    >
                      <span>Export as PDF</span>
                      <img
                        src={PDFExport}
                        alt="export"
                        style={{ height: "25px" }}
                      />
                    </button>
                  )}
                </div>
                {addAccess && (
                  <>
                    <div className="add-new-button-block">
                      <Link
                        to="/expense/bonus/bulkAdd"
                        className="add-new-button whitespace-nowrap"
                      >
                        <span>Bulk Add</span>
                        <img src={ColorAdd} alt="add" />
                      </Link>
                    </div>
                    <div className="add-new-button-block">
                      <Link
                        to="/expense/bonus/add"
                        state={{ from: "advance" }}
                        className="add-new-button whitespace-nowrap"
                      >
                        <span>Add New</span>
                        <img src={ColorAdd} alt="add" />
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>

          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={
                      filterList.gender
                        ? genderList?.find(
                            (option) => option?.value === filterList.gender
                          )
                        : ""
                    }
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Religion</label>
                  <Select
                    options={religionList}
                    value={religionList?.find(
                      (option) => option?.value === filterList?.religion_id
                    )}
                    onChange={(e) => handleFilter(e, "religion_id")}
                    name="religion_id"
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field"
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      value={filterList?.joining_from_date}
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date");
                      }}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date");
                      }}
                      value={filterList?.joining_to_date}
                    />
                  </div>
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <BonusListTable
            list={filteredBonusList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            multiple={showAccess}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}
export default BonusList;
