import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Export from "../../../Assets/Icons/Export.svg";
import Print from "../../../Assets/Icons/Print.svg";
import Logo from "../../../Assets/Logo/Logo.png";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { GET_API, POST_API } from "../../../Services/api";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";
import View from "../../../Assets/Icons/View.svg";
import { numberToWords } from "../../NumbersToWords/NumbersToWords";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import QRCode from "react-qr-code";
import dayjs from "dayjs";
import { red } from "@mui/material/colors";
import PayRollUpdateAttendanceModal from "./../PayRollUpdateAttendanceModal";


function PayslipDetails() {
  let componentRef = useRef();
  // const { state } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);

  // Access the values of the parameters
  const month1 = queryParams.get("month1");
  const year1 = queryParams.get("year1");

  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [payslipDetails, setPayslipDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(year1 ? year1 : new Date().getFullYear());
  const [month, setMonth] = useState(
    month1 ? month1 : new Date().getMonth() + 1
  );
  const [filterParams, setFilterParams] = useState("");
  const [filterList, setfilterList] = useState({
    status: "",
  });

  const attendanceList = ([
    { label: "Please Select", value: "" },
    { value: 'P', label: 'Present' },
    { value: 'H', label: 'Half Day' },
    { value: 'L', label: 'Leave' },
    // { value: 'A', label: 'Absent' }
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState({
    employee_id: "",
    status: "",
    date: "",
  });

  useEffect(() => {
    getPayslip(year, month);
  }, []);

  const handleModal = () => {
    setIsOpen(!isOpen);
    setAttendanceDetails({
      employee_id: "",
      status: "",
      date: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value =
      type === "date" || type === "input" ? e?.target?.value : e?.value;
    filterList[name] = value;
    attendanceDetails[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  
  const handleEditModal = (status) => {
    setAttendanceDetails({
      ...attendanceDetails,
      employee_id: id,
      status: status,
      date: "",
    });
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsBtnDisable(true);
      const rejoin = await POST_API(`/employee/addAttendanceStatusApproval`, attendanceDetails);
      if (rejoin) {
        alertify.success(rejoin?.data?.message);
        setIsOpen(!isOpen);
        setAttendanceDetails({
          employee_id: "",
          status: "",
          date: "",
        });
        setIsBtnDisable(false);
      }
    } catch (error) {
      setIsBtnDisable(false);
      alertify.error(error?.response?.data?.message);
    }
  };


  const getPayslip = async (year, month) => {
    setLoading(true);
    try {
      const payroll = await GET_API(
        `/calculatePayroll/${id}?year=${year}&month=${month}`
      );
      if (payroll?.status === 200) {
        setPayslipDetails(payroll?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };
  const print = useReactToPrint({
    content: () => componentRef.current,
  });
  const generatePayroll = async () => {
    setLoading(true);
    try {
      const payroll = await POST_API(
        `/payslipGenerate/${id}?year=${year}&month=${month}`,
        {
          payslipDetails,
          createdBy: localStorage.getItem("user_id"),
        }
      );
      if (payroll.status === 200) {
        setLoading(false);
        const payslip = getPayslip(year, month);
        setTimeout(() => print(), 2000);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const updateTodayAttendance = async () => {
    setLoading(true);
    try {
      const attendance = await POST_API(
        `/updateAttendanceStatus/${id}`);
      if (attendance.status === 200) {
        setLoading(false);
        setTimeout(() => window.location.reload(false), 2000);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const handlePayslipBasedonMonthAndYear = async (value) => {
    const dateObject = new Date(value.$d);
    const year = dateObject?.getFullYear();
    const month = dateObject?.getMonth() + 1;
    setYear(year);
    setMonth(month);
    getPayslip(year, month);
  };

  const fathers_name =
    payslipDetails?.employee?.fathers_name !== null
      ? payslipDetails?.employee?.fathers_name
      : "";

  if (loading) {
    <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow ">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/payroll/payslip" className="in-active">
              Generate Payslip
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Payslip</Link>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <h1 className="heading-text">Payslip</h1>
          </div>
          <div className="flex gap-2">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'"Month" and "Year"'}
                  views={["month", "year"]}
                  slotProps={{
                    field: { clearable: true, onClear: () => false },
                  }}
                  value={dayjs(payslipDetails?.datepicker_month + " " + year)}
                  onChange={(value, context) => {
                    handlePayslipBasedonMonthAndYear(value);
                  }}
                />
              </LocalizationProvider>
            </div>
            {/* <Link
              to="/payroll/payslip"
              className="view-list-button whitespace-nowrap"
            >
              <span>View List</span>
              <img src={View} alt="add" />
            </Link> */}
            <button className="view-list-button" onClick={print}>
              Print
              <img src={Print} alt="" />
            </button>
          </div>
        </div>
        <div className="card-wrapper" style={{ fontSize: "3" }}>
          <div ref={componentRef}>
            <h4 className="payslip-header">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img src={Logo} className="h-[50px] w-[50px]" alt="" />
                  <div className="text-text-dark font-karla">
                    SUMANGALI SILKS
                  </div>
                </div>

                <div className="flex gap-3">
                  {payslipDetails?.voucherNo && (
                    <QRCode
                      value={
                        payslipDetails?.voucherNo
                          ? payslipDetails?.voucherNo
                          : ""
                      }
                      style={{ height: "80px" }}
                    />
                  )}
                </div>
              </div>
            </h4>
            <div className="p-5 pt-1 pb-1">
              <div className="flex justify-between font-karla">
                <div>
                  <div>
                    Payslip for the month of
                    <b>{" " + payslipDetails?.displayMonth}</b>
                  </div>
                  <div className="flex ">
                    <label className="list-label">Employee Code: </label>
                    <div
                      style={{
                        wordBreak: "break-word",
                        fontSize: "1.2rem",
                        color: "#000",
                      }}
                      className="advance-section-list-value"
                    >
                      <b> {payslipDetails?.employee?.emp_code}</b>
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Employee Name: </label>
                    <div
                      style={{
                        wordBreak: "break-word",
                        fontSize: "1.2rem",
                        color: "#000",
                      }}
                      className="advance-section-list-value"
                    >
                      <b>{payslipDetails?.employee?.first_name}</b>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex">
                    <label className="list-label">Designation: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.designation_name}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Date: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.date}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 pt-1 pb-1">
              <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                <tbody>
                  <tr>
                    <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>Holiday Scheme</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.employee?.holiday_scheme_name}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Present Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.presentDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Absent Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.absentDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Absent Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.totalAbsentDays}{" "}
                                days
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <th colSpan="4" className="text-left">
                              <b> Earnings</b>
                            </th>
                          </tr>
                          {payslipDetails?.earnings &&
                            payslipDetails?.earnings.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td>{list?.type}</td>
                                  <td className="text-right">
                                    {list?.totalAmount}
                                  </td>
                                  <td className="text-right">
                                    <b>{list?.actualAmount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <th colSpan="2" className="text-left">
                              <b>Deductions</b>
                            </th>
                          </tr>
                          {payslipDetails?.deductions &&
                            payslipDetails?.deductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                          {payslipDetails?.otherDeductions &&
                            payslipDetails?.otherDeductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total Days</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.attendance?.totalDays} days
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td className="w-[65%]">
                              <b>Total</b>
                            </td>
                            <td className="text-right">
                              (-{payslipDetails?.totalEarnings?.deduction})
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalEarnings?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%] font-karla">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalDeductions?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-5 pt-2 pb-1 flex font-karla">
              <p className="pr-4">Salary to be paid in cash : </p>{" "}
              <div
                className="advance-voucher-bold p-5 pt-0 "
                style={{ fontSize: "40px" }}
              >
                {" "}
                {payslipDetails?.salaryToBePaid}
              </div>
            </div>
            <div className="p-5 pt-1 pb-1">
              <p
                style={{ fontSize: "1.3rem", color: "#000", marginTop: "-8px" }}
              >
                <b>
                  {payslipDetails?.salaryToBePaid > 0 &&
                    payslipDetails?.salaryToBePaid &&
                    "Rupees : " + numberToWords(payslipDetails?.salaryToBePaid)}
                </b>
              </p>
            </div>
            <br />
            <div className="p-5 pt-3 pb-1">
              <div className="flex justify-between items-center font-karla text-text-dark font-semibold">
                <div>Admit by</div>
                <div>Checked by</div>
                <div>Employee Signature</div>
              </div>
            </div>
            {/* PF Salary details */}
            {payslipDetails?.pfEarnings &&
              payslipDetails?.pfEarnings.length > 0 && (
                <>
                  <div id="scissors">
                    <div></div>
                  </div>
                  <h4 className="payslip-pf-header">
                    <div className="flex justify-between items-center">
                      PF Salary Voucher
                    </div>
                  </h4>

                  <div className="p-5 pt-1 pb-1">
                    <div className="flex justify-between">
                      <div>
                        <div className="flex">
                          <label className="list-label">Employee Code: </label>
                          <div
                            style={{
                              wordBreak: "break-word",
                              fontSize: "1.2rem",
                              color: "#000",
                            }}
                            className="advance-section-list-value"
                          >
                            <b>{payslipDetails?.employee?.emp_code}</b>
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Employee Name: </label>
                          <div
                            style={{
                              wordBreak: "break-word",
                              fontSize: "1.2rem",
                              color: "#000",
                            }}
                            className="advance-section-list-value"
                          >
                            <b> {payslipDetails?.employee?.first_name}</b>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex">
                          <label className="list-label">Designation: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.designation_name}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Date: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 pt-1 pb-1">
                    <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                      <tbody>
                        <tr>
                          <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>Holiday Scheme</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.employee
                                          ?.holiday_scheme_name
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.presentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Paid Leave Days</td>
                                  <td className="text-right">
                                    <b>
                                      {payslipDetails?.pfAttendance?.paidDays}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Net Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.netPresentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-left">
                                    <b> Earnings</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfEarnings &&
                                  payslipDetails?.pfEarnings.map((list, i) => {
                                    return (
                                      <tr>
                                        <td>{list?.name}</td>
                                        <td>{list?.type}</td>
                                        <td className="text-right">
                                          {list?.totalAmount}
                                        </td>
                                        <td className="text-right">
                                          {list?.actualAmount}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="2" className="text-left">
                                    <b>Deductions</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfDeductions &&
                                  payslipDetails?.pfDeductions.map(
                                    (list, i) => {
                                      return (
                                        <tr>
                                          <td>{list?.name}</td>
                                          <td className="text-right">
                                            <b>{list?.amount}</b>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total Days</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.attendance?.totalDays} days
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td className="w-[65%]">
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfEarnings &&
                                      payslipDetails?.pfEarnings[0] &&
                                      payslipDetails?.pfEarnings[0]
                                        .actualAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfTotalDeductions?.total}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="p-5 pt-2 pb-1 flex">
                    <p className="pr-4"> Salary to be credited in Bank : </p>{" "}
                    <div
                      className="advance-voucher-bold p-5 pt-0 "
                      style={{ fontSize: "40px" }}
                    >
                      {payslipDetails?.pfSalaryInBank}
                    </div>
                  </div>
                  <div className="p-5 pt-1 pb-1 ">
                    <p>
                      <b>
                        {payslipDetails?.pfSalaryInBank > 0 &&
                          payslipDetails?.pfSalaryInBank &&
                          "Rupees : " +
                            numberToWords(payslipDetails?.pfSalaryInBank)}
                      </b>
                    </p>
                  </div>
                  <br />

                  <div className="p-5 pt-3 pb-1">
                    <div className="flex justify-between items-center">
                      <div>
                        <b>Admit by</b>
                      </div>
                      <div>
                        <b>Checked by</b>
                      </div>
                      <div>
                        <b>Employee Signature</b>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
          {payslipDetails?.voucherNo === "" && (
            <div className="search-block ">
              <div className="export-btn" onClick={generatePayroll}>
                <span className="export-btn-text">Generate Payroll</span>
                <img src={Export} alt="" />
              </div>
            </div>
          )}

          <hr className=" border-[#d9d9d9] mt-6"></hr>
         

          <div className="grid grid-cols-2 p-3 gap-2">
          <div>
            <h3>
              <b>Attendance Details: </b>
            </h3>
          </div>
          </div>

          <div className="grid grid-cols-2 p-6 pt-2 gap-2">
            <div>
              <p>
                Month : <b>{payslipDetails?.displayMonth}</b>
              </p>
              <p>
                Total days Present :
                <b>
                  {payslipDetails?.presentDetails &&
                    payslipDetails?.presentDetails.length}
                </b>
              </p>
            </div>
            <div className="flex justify-end items-center gap-3">
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>
                <span>Present</span>
              </p>
              <p className="flex jusitify-center items-center gap-2">
                <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                <span>Absent</span>
              </p>
            </div>
          </div>
          <div className="p-1 flex flex-col items-center justify-center">
            <table className="">
              <tr>
                {payslipDetails?.attendance_details_table &&
                  payslipDetails?.attendance_details_table?.dates.map(
                    (list, i) => (
                      <th className="border attendance-header border-[#d9d9d9] p-1.5">
                        {list}
                      </th>
                    )
                  )}
              </tr>
              <tr>
                {payslipDetails?.attendance_details_table &&
                  payslipDetails?.attendance_details_table?.values.map(
                    (status) => {
                      return (
                        <td
                          className={`border-[1px] border-primary text-center p-1	${
                            status === "P"
                              ? "bg-[#11B76B] text-[#FFF]"
                              : status === "A"
                              ? "bg-[#ed452a] text-[#FFF]"
                              : status === "-"
                              ? "bg-[#DADADA] text-[#FFF]"
                              : status === "H"
                              ? "bg-[#ddc919] text-[#FFF]"
                              : status === "L"
                              ? "bg-[#248cff] text-[#FFF]"
                              : ""
                          }`}
                        >
                          {/* { (status === "L") ? <a type="button" onClick={ updateTodayAttendance } style={{ cursor: "pointer" }}> {status} </a> : status } */}

                          { (status === "L") ? <a type="button" onClick={() => handleEditModal(status)} style={{ cursor: "pointer" }}> {status} </a> : status }
                          
                        </td>
                      );
                    }
                  )}
              </tr>
            </table>
          </div>

          <PayRollUpdateAttendanceModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleFilter={handleFilter}
            filterList={filterList}
            attendanceList={attendanceList}
            status={attendanceDetails.status}
            isBtnDisable={isBtnDisable}
          />

        </div>
      </div>
    </div>
  );
}
export default PayslipDetails;
