import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../DataTable/Pagination";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  pagination,
  setState,
  setSearch,
  pageSize,
  setPageSize,
  setPageNo,
  lastPage,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                const father_name = row?.fathers_name ? row?.fathers_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }

                    {
                      <TableCell
                        className="table-body"
                        onClick={() => showDetails(row)}
                      >
                        <span
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7] cursor-pointer"
                          style={{ color: "#0284C7" }}
                        >
                          {row.EmployeeCode}
                        </span>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.voucher_status === 1 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Paid
                          </span>
                        ) : (
                          <span
                            className="bg-[#FF5C42]  p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Unpaid
                          </span>
                        )}
                      </TableCell>
                    }
                    {
                      <TableCell
                        style={{
                          color: "#7B7B7B",
                          fontSize: "16px",
                          fontWeight: "400",
                          fontFamily: "karla",
                        }}
                      >
                        {row?.finance_date}
                      </TableCell>
                    }
                    {
                      <TableCell
                        className="table-body loan-advance-expence-table"
                        style={{ textAlign: "right" }}
                      >
                        <span>&#8377;{row?.amount}</span>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.status === 0 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.status === 3 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.status === 2 ? (
                          <span
                            className="bg-[#ed452a] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected
                          </span>
                        ) : null}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pagination={pagination}
        setState={setState}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPageNo={setPageNo}
        lastPage={lastPage}
      />
    </Paper>
  );
}
