import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { GET_API } from "../../Services/api";
import config from "../../Services/config.json";
import alertify from "alertifyjs";
import Spinner from "../Spinner";

function LeaveOndutyDetails() {
  const { id } = useParams();
  const [empDetail, setEmpDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLeaveOndutyDetails();
  }, []);

  const imageFormat = (val) => {
    const extension = val?.split(".")?.pop().toLowerCase();
    const imgData = ["jpeg", "jpg", "png", "gif"];
    if (imgData.includes(extension)) {
      return true;
    } else {
      return false;
    }
  };

  const getLeaveOndutyDetails = async () => {
    setLoading(true);
    try {
      const leaveDetails = await GET_API(`/getEmployeeLeave/${id}`);
      if (leaveDetails?.status === 200) {
        setEmpDetail(leaveDetails?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.data);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow" style={{ width: "80%" }}>
      <div className=" sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/leaveonduty" className="in-active">
              Leave On Duty List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Leave On Duty Details</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Leave On Duty Details</div>

          <div className="grid grid-cols-3 font-montserrat">
            <div className="">
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Employee Code
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.empCode}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Employee Name
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.first_name + " " + empDetail?.fathers_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Branch
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.branchName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Leave Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.leave_type_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  From Date
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.from_date}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  No.Of.Days
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.no_of_days}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Status
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.is_active === 0
                    ? "Approved"
                    : empDetail?.is_active === 1
                    ? "Pending"
                    : empDetail?.is_active === 2
                    ? "Rejected"
                    : "--"}
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Department
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.deptName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Designation
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.desName}
                </div>
              </div>

              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Shift
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.shiftName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.type === "leave" ? (
                    <span
                      className=" bg-[#03789F] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Leave
                    </span>
                  ) : (
                    <span
                      className=" bg-[#11B76B] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      OD
                    </span>
                  )}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  To Date
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.to_date}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8 w-full">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Approved By
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla w-6/12"
                >
                  {empDetail?.approver_name} {empDetail?.approver_last_name}
                </div>
              </div>
            </div>
            <div className="px-6 gap-8">
              <div className="py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  Profile Image
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  <img
                    src={`${config.path}${empDetail?.profile_image}`}
                    alt="Profile Image"
                    style={{ height: "120px", width: "120px" }}
                  />
                </div>
              </div>
              <div className="py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat w-6/12">
                  File
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {imageFormat(empDetail?.file) ? (
                    <img
                      src={`${config.path}${empDetail?.file}`}
                      alt=""
                      style={{ height: "120px", width: "120px" }}
                    />
                  ) : (
                    <Link
                      className=""
                      to="https://docs.google.com/spreadsheets/d/1SHpU9uURGHchPCApxAGmE2yJf8KwGAQd/edit#gid=346827490"
                      target="_blank"
                    >
                      <button type="submit" className="submit-button">
                        <span className="submit-button-label">View File</span>
                        <FilePresentIcon className=" text-[#fff]" />
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveOndutyDetails;
