import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import { useNavigate } from "react-router-dom";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    const id = listDetail?.employeeCode;
    navigate(`/report/advance/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="11"
                  className="table-body td-data-not-found"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                const father_name = row?.father_name ? row.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="!w-[7%] table-body ">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <a
                          onClick={() => showDetailsPage(row)}
                          style={{ color: "#0284C7" }}
                          className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.employeeCode}
                        </a>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.designation_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.finance_date}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.voucher_status === 1 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Paid
                          </span>
                        ) : (
                          <span
                            className="bg-[#FF5C42]  p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Unpaid
                          </span>
                        )}
                      </TableCell>
                    }
                    {
                      <TableCell
                        className=" table-body"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <span>&#8377;{row?.amount}</span>
                      </TableCell>
                    }

                    {
                      <MoreOption
                        icon={icon}
                        row={row}
                        showDetails={showDetails}
                        multiple={multiple}
                        deleteRow={deleteRow}
                        editPage={editPage}
                        allowance={allowance}
                        termination={termination}
                        terminateEmployee={terminateEmployee}
                        activeEdit={activeEdit}
                        activeDelete={activeDelete}
                      />
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
