import { DELETE_API } from "../Services/api";
import alertify from "alertifyjs";

const handleDeleteList = async (
  rowID,
  modelName,
  callBackFun,
  url,
  currentPage,
  setCurrentPage,
  pageSize,
  searchKey,
  filterParams
) => {
  if (rowID === "") return;

  alertify

    .confirm(
      "Confirm",
      `Confirm to ${modelName}..?`,
      async function () {
        try {
          const deleteRow = await DELETE_API(`/${url}/${rowID}`);
          if (deleteRow.status === 200) {
            alertify.success(deleteRow?.data?.message);
            callBackFun(currentPage, pageSize, searchKey, filterParams);
            setCurrentPage(1);
            return;
          }
        } catch (error) {
          alertify.error(`Failed To Delete ${modelName}`);
        }
      },

      function () {}
    )

    .set("movable", false)
    .set("labels", { ok: "Delete!", cancel: "Cancel!" });
};

export { handleDeleteList };
