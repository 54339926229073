import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API, DELETE_API } from "../../Services/api";
import BloodGroupTable from "../DataTable/BloodGroupTable";
import Search from "../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Validation from "../../Validation/Validation";
import Edit from "../../Assets/Icons/Edit.svg";
import Spinner from "../Spinner";

function BloodGroup() {
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [newBloodGroup, setNewBloodGroup] = useState({
    name: "",
  });

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "blood_group", label: "Blood Group", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getBloodGroupList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBloodGroupList(1, pageSize, searchKey);
  };

  const getBloodGroupList = async (page, size, search) => {
    setLoading(true);
    try {
      const bloodGroup = await GET_API(
        `/bloodgroupList?page=${page}&size=${size}&search=${search}`
      );
      if (bloodGroup.status === 200) {
        if (
          bloodGroup?.data?.data?.current_page >
          bloodGroup?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setBloodGroupList(bloodGroup?.data?.data?.data);
        setFilteredList(bloodGroup?.data?.data?.data);
        setLastPage(bloodGroup?.data?.data?.last_page);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBloodGroup({ ...newBloodGroup, [name]: value });
  };

  const addBloodGroup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBloodGroup(newBloodGroup);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    const createBloodGroup = await POST_API("/addBloodgroup", newBloodGroup);
    if (createBloodGroup?.data?.status === 200) {
      setNewBloodGroup({ name: "" });
      getBloodGroupList(currentPage, pageSize, searchKey);
      alertify.success(createBloodGroup?.data?.message);
      setButtonDisabled(false);
      return;
    }
    if (createBloodGroup?.data?.status === 403) {
      setButtonDisabled(false);
      alertify.error(createBloodGroup?.data?.message);
      return;
    }
    alertify.error(createBloodGroup?.response?.data?.message);
  };

  const editBloodGroup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBloodGroup(newBloodGroup);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    const editBloodGroup = await POST_API("/updateBloodGroup", newBloodGroup);
    if (editBloodGroup?.data?.status === 200) {
      setNewBloodGroup({ name: "" });
      getBloodGroupList(currentPage, pageSize, searchKey);
      setEditStatus(false);
      alertify.success(editBloodGroup?.data?.message);
      setButtonDisabled(false);
      return;
    }
    if (editBloodGroup?.data?.status === 403) {
      setButtonDisabled(false);
      alertify.error(editBloodGroup?.data?.message);
      return;
    }
    alertify.error(editBloodGroup?.response?.data?.message);
  };

  const resetForm = () => {
    setEditStatus(false);
    setNewBloodGroup({ name: "" });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Blood Group..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteBloodGroup/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getBloodGroupList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Blood Group");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Add Blood Group</h4>

          <form
            onSubmit={() => getBloodGroupList(currentPage, pageSize, searchKey)}
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>
            </div>
          </form>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Blood Group <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Blood Group Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={newBloodGroup.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editBloodGroup(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addBloodGroup(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>
          <BloodGroupTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setNewBloodGroup}
            multiple={false}
            deleteRow={handleDelete}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default BloodGroup;
