import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import FloorTable from "../../DataTable/FloorTable";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import { Link } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";

function Floor() {
  const [floorList, setFloorList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [newFloor, setNewFloor] = useState({
    name: "",
    description: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "floor", label: "Floor" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getFloorList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getFloorList(1, pageSize, searchKey);
  };

  const getFloorList = async (page, size, search) => {
    setLoading(true);
    try {
      const floorList = await GET_API(
        `/floorList?page=${page}&size=${size}&search=${search}`
      );
      if (floorList.status === 200) {
        if (
          floorList?.data?.data?.current_page > floorList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLoading(false);
        setFloorList(floorList?.data?.data?.data);
        setFilteredList(floorList?.data?.data?.data);
        setLastPage(floorList?.data?.data?.last_page);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Floor List");
      setLoading(false);
      setFloorList([]);
      setFilteredList([]);
      setLastPage("");
      setCurrentPage(1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFloor({ ...newFloor, [name]: value });
  };
  const addFloor = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFloor(newFloor);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const createFloor = await POST_API("/addFloors", newFloor);
      if (createFloor?.data.status === 200) {
        setButtonDisabled(false);
        getFloorList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        alertify.success(createFloor?.data?.message);
        return;
      }
      if (createFloor?.data.status === 403) {
        setNewFloor({ name: "", description: "" });
        alertify.error(createFloor?.data?.message);
        setButtonDisabled(false);
        setCurrentPage(1);
      }
    } catch (error) {
      alertify.error(error?.message);
      setButtonDisabled(false);
      setCurrentPage(1);
    }
  };
  const editFloor = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateFloor(newFloor);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const editFloor = await POST_API("/updateFloors", newFloor);
      if (editFloor.status === 200) {
        setButtonDisabled(false);
        setNewFloor({ name: "", description: "" });
        getFloorList(currentPage, pageSize, searchKey);
        setCurrentPage(1);
        setEditStatus(false);
        alertify.success(editFloor?.data?.message);
        return;
      }
    } catch (error) {
      setButtonDisabled(false);
      setNewFloor({ name: "", description: "" });
      setCurrentPage(1);
      setEditStatus(false);
    }

    alertify.error(editFloor?.response?.data?.message);
  };
  const resetForm = () => {
    setEditStatus(false);
    setNewFloor({ ...newFloor, name: "", description: "" });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Floor..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteFloors/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getFloorList(currentPage, pageSize, searchKey);
              setCurrentPage(1);
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className=" bg-[#F7F6F4] grow">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Add Floor</h4>
            <form
              onSubmit={() => getFloorList(currentPage, pageSize, searchKey)}
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>
              </div>
            </form>
            <div className="add-list-container">
              <div className="input-block">
                <label className="add-new-input-label">
                  Floor <span className="required-symbol">*</span>
                </label>

                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Floor Name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={newFloor.name}
                />
                {errors.name ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.name}
                  </div>
                ) : null}
              </div>
              <div className="input-block">
                <label className="add-new-input-label">Description</label>
                <textarea
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={newFloor.description}
                  autoComplete="off"
                  rows={5}
                />
                {errors.description ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.description}
                  </div>
                ) : null}
              </div>
              <div className="flex gap-3">
                <div className="add-new-button-block">
                  {editStatus ? (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        editFloor(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Save</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        addFloor(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  )}
                </div>
                <Link className="edit-button" onClick={() => resetForm()}>
                  <span className="button-label">Reset</span>
                  <img src={Edit} alt="add" />
                </Link>
              </div>
            </div>
            <FloorTable
              list={filteredList}
              columns={columns}
              icon={More}
              editStatus={setEditStatus}
              editList={setNewFloor}
              multiple={false}
              deleteRow={handleDelete}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Floor;
