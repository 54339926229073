import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import TerminationTable from "../../DataTable/TerminationTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import Edit from "../../../Assets/Icons/Edit.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function TerminationList() {
  const [terminationList, setTerminationList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [newTermination, setNewTermination] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTermination({ ...newTermination, [name]: value });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Termination Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getTerminationList(currentPage, pageSize, searchKey);
  }, [currentPage, pageSize]);

  const getTerminationList = async (page, size, search) => {
    try {
      setLoading(true);
      const terminationList = await GET_API(
        `/TerminationTypeList?page=${page}&size=${size}&search=${search}`
      );
      if (terminationList?.status === 200) {
        if (
          terminationList?.data?.data?.current_page >
          terminationList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setTerminationList(terminationList?.data?.data?.data);
        setFilteredBranch(terminationList?.data?.data?.data);
        setLastPage(terminationList?.data?.data?.last_page);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setTerminationList([]);
      setFilteredBranch([]);
      setLastPage("");
      alertify.error(error?.response?.data?.message);
    }
  };

  const addTermination = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateTermination(newTermination);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addTermination", newTermination);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setNewTermination({ name: "" });
      getTerminationList(currentPage, pageSize, searchKey);
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };

  const editTermination = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateTermination(newTermination);

    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateTerminationType", newTermination);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setNewTermination({ name: "" });
      getTerminationList(currentPage, pageSize, searchKey);
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };

  if (loading) {
    return <Spinner />;
  }

  const isPageNotExit = () => {
    setCurrentPage(1);
    getTerminationList(1, pageSize, searchKey);
  };

  const resetForm = () => {
    setEditStatus(false);
    setNewTermination({ ...newTermination, name: "" });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Termination List..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteTermination/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getTerminationList(currentPage, pageSize, searchKey);
              return;
            }
            alertify.error("Failed To Delete Termination List");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <div className="card-wrapper">
            <h4 className="card-header">Termination List</h4>

            <form
              onSubmit={() =>
                getTerminationList(currentPage, pageSize, searchKey)
              }
            >
              <div className="search-block">
                <div className="search">
                  <input
                    type="text"
                    className="search-input-box"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={(e) => setSearchKey(e?.target?.value)}
                    style={{ outline: "none", border: "none" }}
                    value={searchKey}
                  />

                  <button type="submit">
                    <img src={Search} alt="" className="cursor-pointer" />
                  </button>
                </div>
              </div>
            </form>

            <div className="add-list-container">
              <div className="input-block">
                <label className="add-new-input-label">
                  Termination Name <span className="required-symbol">*</span>
                </label>

                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Termination name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={newTermination?.name}
                />
                {errors.name ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.name}
                  </div>
                ) : null}
              </div>

              <div className="flex gap-3">
                <div className="add-new-button-block">
                  {editStatus ? (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        editTermination(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Save</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        addTermination(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  )}
                </div>
                <Link className="edit-button" onClick={() => resetForm()}>
                  <span className="button-label">Reset</span>
                  <img src={Edit} alt="add" />
                </Link>
              </div>
            </div>

            {/**************** * Table ********************/}

            <TerminationTable
              list={filteredBranch}
              columns={columns}
              icon={More}
              editStatus={setEditStatus}
              editList={setNewTermination}
              multiple={false}
              deleteRow={handleDelete}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TerminationList;
