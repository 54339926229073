import React, { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import dayjs from "dayjs";
import { read, utils } from "xlsx";
import alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";
import Add from "../../Assets/Icons/Add.svg";
import More from "../../Assets/Icons/More.svg";
import { GET_API, POST_API, BASE_PATH, BASE_URL } from "../../Services/api";
import AttendanceListTable from "../DataTable/AttendanceListTable";
import Spinner from "../Spinner";
import Search from "../../Assets/Icons/Search.svg";
import Filter from "../../Assets/Icons/Filter.svg";
import ExcelExport from "../../Assets/Icons/excel-export.svg";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../PrivilegeContext";
import ButtonLoader from "../ButtonLoader";

function AttendanceList() {
  const inputFile = useRef(null);
  const navigate = useNavigate();
  const [filteredAttendanceList, setFilteredAttendanceList] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    date: dayjs(new Date()).format("YYYY-MM-DD"),
    attendance_status: "",
    valid_emp: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "department", label: "Department" },
    { id: "attendance", label: "Attendance" },
    { id: "clock_time", label: "Clock time" },
    { id: "more_icon", label: "" },
  ];

  const attendanceDetailsPage = (listDetail) => {
    const url = `/attendance/details/${listDetail?.employeeCode}`;
    const state = {
      list: listDetail,
    };
    const newTab = window.open(url, "_blank");
    newTab.history.pushState(state, "", url);
  };

  useEffect(() => {
    getEmployeeList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  useEffect(() => {
    getAttendanceList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "attendance") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const getEmployeeList = async () => {
    try {
      setIsEmpLoading(true);
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const attendanceListArr = [];
        employeeList?.data?.data.map((item) => {
          return attendanceListArr.push(item);
        });
        const empList = employeeList?.data?.data.map((item) => {
          return {
            label: `${item?.first_name} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
    }
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAttendanceList(1, pageSize, filterParams, searchKey);
  };

  const getAttendanceList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const attendanceList = await GET_API(
        `/attendanceList?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (attendanceList?.data?.status === 200) {
        if (
          attendanceList?.data?.data?.current_page >
          attendanceList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        setLastPage(attendanceList?.data?.data?.last_page);
        setAttendanceList(attendanceList?.data?.data?.data);
        setFilteredAttendanceList(attendanceList?.data?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setLastPage("");
      setAttendanceList([]);
      setFilteredAttendanceList([]);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const attendanceStatus = [
    { label: "Please Select", value: "" },
    { label: "Present", value: "present" },
    { label: "Absent", value: "absent" },
  ];

  const validEmployee = [
    { label: "Please Select", value: "" },
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];

  const onImportClick = () => {
    inputFile.current.click();
  };

  const fileUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = read(e.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            dateNF: "dd-mm-yyyy hh:mm:ss",
          });
          POST_API("/importAttendance", { data: rows })
            .then(async function (data) {
              if (data?.data?.status === true) {
                alertify.success(data?.data?.message);
                getAttendanceList(
                  currentPage,
                  pageSize,
                  filterParams,
                  searchKey
                );
              }
              if (data?.data?.status === false) {
                alertify.error(data?.data?.message);
              }
            })
            .catch((error) =>
              alertify.error("Some error Occured.Please try again")
            );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const exportAttenfdanceForm = async () => {
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-attendance-list?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Attendance.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name) => {
    const { value } = name === "date" ? e?.target : e;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">
            {"Attendance List - " + filterList?.date}
          </div>
          <form
            onSubmit={() =>
              getAttendanceList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
                <div className="add-new-button-block">
                  {isExportLoading ? (
                    <ButtonLoader isBtnDisable={isExportLoading} />
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={() => exportAttenfdanceForm()}
                    >
                      <span>Export</span>
                      <img
                        src={ExcelExport}
                        style={{ height: "25px" }}
                        alt="export"
                      />
                    </button>
                  )}
                </div>
                {addAccess && (
                  <div className="add-new-button-block">
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={fileUpload}
                    />

                    <button className="add-new-button" onClick={onImportClick}>
                      <span style={{ cursor: "pointer" }}>Import</span>
                      <img src={Add} alt="" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Date</label>
                  <input
                    type="date"
                    name="date"
                    value={filterList?.date}
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e, "date");
                    }}
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Attendance Status</label>
                  <Select
                    options={attendanceStatus}
                    value={attendanceStatus?.find(
                      (option) => option?.value === filterList.attendance_status
                    )}
                    onChange={(e) => handleFilter(e, "attendance_status")}
                    name="attendance_status"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Valid Employee</label>
                  <Select
                    options={validEmployee}
                    value={validEmployee?.find(
                      (option) => option?.value === filterList.valid_emp
                    )}
                    onChange={(e) => handleFilter(e, "valid_emp")}
                    name="valid_emp"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%]">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <AttendanceListTable
            list={filteredAttendanceList}
            columns={columns}
            icon={More}
            multiple={showAccess}
            showDetails={attendanceDetailsPage}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            enableMoreOption={showAccess}
          />
        </div>
      </div>
    </div>
  );
}

export default AttendanceList;
