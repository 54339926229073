import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import MoreOption from "../MoreOption";
import SortIcon from "../../Assets/Icons/SortIcon.svg";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  sorting,
  handleSort,
}) {
  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    const id = listDetail?.id;
    navigate(`/report/earlyExit/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                  onClick={() => handleSort(column.id, column.sortable)}
                >
                  <div className="flex">
                    {column?.label}
                    {column.sortable && sorting.orderBy === column.id ? (
                      sorting.orderByType === "desc" ? (
                        <img src={SortIcon} alt="sort icon" />
                      ) : (
                        <img src={SortIcon} alt="sort icon" />
                      )
                    ) : (
                      column.sortable && <img src={SortIcon} alt="sort icon" />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                const father_name = row?.father_name ? row.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <Link
                          onClick={() => showDetailsPage(row)}
                          style={{ color: "#0284C7" }}
                          className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.EmployeeCode}
                        </Link>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.department_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.designation_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.floor_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.attdate}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.clockInTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.clockOutTime}
                      </TableCell>
                    }
                    {/* {
                      <TableCell className="table-body">
                        {row.clock_out_time}
                      </TableCell>
                    } */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
